<template>
  <v-app>
    <portal-target name="app-modals"/> 
    <NotificationCenter v-if="show_NotificationCenter" />
    <Pallette v-if="showThemeChanger" />
    <Notification class="mt-15" />
    <ScreenLoader v-if="getScreenLoader" />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>  
import {mapGetters} from 'vuex'    
import Pallette from './components/Theme/Pallette.vue'
import Notification from './components/UI/Notification.vue'
import NotificationCenter from '@/components/Messages/NotificationCenter.vue'
import ScreenLoader from '@/components/Modals/ScreenLoader.vue'
export default {  
  components: {  
    Pallette, 
    Notification ,
    NotificationCenter,
    ScreenLoader
  },  
  created(){ 
    this.$setupAxios()
  },
  computed: {
    ...mapGetters(['showThemeChanger', 'show_NotificationCenter', 'getScreenLoader'])
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,100;1,300&display=swap');  
@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600&display=swap'); 
@import './Assets/css/styles.css';
*{
  font-size: 13px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  scroll-behavior: smooth;
} 

:root{
  --primary             : #0EBFAA ;
  --secondary           : #75D752;
  --textColor           : #7B8694;
  --borderLight         : #eee;
  --borderMedium        : #ddd;
  --borderDark          : #ccc;
  --colorGray           : #999;
  --colorGrayDark       : #555;
  --ramsColorDanger     : #C62828;
  --notificationSuccess : #3DB56E; 
  --notificationError   : #C63A3A;
  --superAdminTheme     : #35829F; 
} 

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 40px;
  border: 2px solid white;
} 

.superAdminThemeText{
  color: var(--superAdminTheme);
}


</style>