<template>
  <div class="wrap">
    <div class="loader">
      <div class="dot" :style="{backgroundColor: currentThemeObject.background}"></div>
      <div class="dot" :style="{backgroundColor: currentThemeObject.background}"></div>
      <div class="dot" :style="{backgroundColor: currentThemeObject.background}"></div>
      <div class="dot" :style="{backgroundColor: currentThemeObject.background}"></div>
      <div class="dot" :style="{backgroundColor: currentThemeObject.background}"></div>
    </div> 
    <p class="text-center mt-5">{{ title }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default { 
  props: ['title'],
  computed: {
    ...mapGetters(['currentThemeObject'])
  }
}
</script>

<style scoped>  
.wrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: auto; 
  background-color: transparent;
  box-shadow: none;
}
.dot {
  width: 24px;
  height: 24px; 
  display: inline-block;
  animation: slide 1s infinite;
}
.dot:nth-child(1) {
  animation-delay: 0.1s; 
}
.dot:nth-child(2) {
  animation-delay: 0.2s; 
}
.dot:nth-child(3) {
  animation-delay: 0.3s; 
}
.dot:nth-child(4) {
  animation-delay: 0.4s; 
}
.dot:nth-child(5) {
  animation-delay: 0.5s; 
}
@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

</style>