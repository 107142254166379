<template>
<div class="body">
  <h1 class="heading-3 superAdminThemeText pa-5">Enquiries</h1> 

  <v-data-table
      :headers="headers"
      :items="applicationList" 
      :items-per-page="5"
      item-key="uuid" 
      show-select
      v-model="allSelections"
    ></v-data-table>

  <v-expansion-panels>
    <v-expansion-panel
      v-for="(application) in allSelections"
      :key="application.uuid"
    >
      <v-expansion-panel-header class="head">
        Company - {{ application.companyName}}
      </v-expansion-panel-header>
      <v-expansion-panel-content class="mt-5">
         <p>Name - {{ application.name }}, </p>
         <p>Email -{{ application.email }}</p>
         <p class="mb-0">Message- {{ application.message }}</p> 
         <div class="text-end">
          <timeago :datetime="application.ctime" :auto-update="60"></timeago>
         </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</div>
  
</template>

<script>  
export default {
  data(){
    return{
      applicationList: [],
      selected: {}, 
      allSelections:[],
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Company',
          value: 'companyName'
        }
      ]
    }
  },
  created(){
    this.$axios.get(`${this.$api.contactUs.fetchEnquiry}`)
    .then(response=>{ 
      this.applicationList = response.data 
    })
  }
}
</script>

<style scoped>
.body{
  background-color: white;
}
.application-body{
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between; 
}
.single-application-header,
.single-application{
  display: grid;
  grid-template-columns: 33% 33% 34%;
}
.single-application div,
.single-application-header div{
  border: 1px solid #ccc;
  padding: 5px;
} 
.head{
  background-color: #f5f5f5;
} 

</style>