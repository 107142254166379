import store from '../../store/index'
import { successNotification } from '../notification'
import { getCurrentTable } from '../controllers'
import {formatUpdateCellMessage} from '@/constants/websocketMessageConstants'
const handleRowValueBroadcast = (message) =>{
  if(message.message.tableViewUuid === getCurrentTable()){ 
    successNotification(formatUpdateCellMessage)
    fetchData()
  }  
} 

const fetchData = () =>{ 
  store.dispatch('fetchCellData') 
}

export{
  handleRowValueBroadcast
}