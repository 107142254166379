<template>
  <div class="wrapper mt-5 ml-5"> 

    <Confirm 
      v-if="showDeleteConfirmation"
      :deletingComponent="tableDeletingConfirmation"
      @exit="showDeleteConfirmation=false"
      @confirm ="updateDelete" /> 

    <AddNewTable v-if="showTableManagement" 
      @exit="toggleTableManagement(false, null)"
      :tableDetails="editTable"
     />

    <div class="template-flex-row-between" 
      @click="toggleTableManagement(true, null)">
        <h2 class="heading-5">Table List</h2>
        <div class="add-panel" v-if="((getCurrentUser.role!='ROLE_READER') && (getCurrentUser.role!='ROLE_UPDATER'))">
          <v-icon 
          :color="currentThemeObject.background" 
          class="pa-0"  small>
             mdi-plus-box
          </v-icon>
          <span class="heading-6 ml-1">Add</span>
        </div>
    </div>

    <v-text-field
      label="Find a table"
      v-model="searchResult"
      v-if="tableList.length>0"
      class="body-text-small">
    </v-text-field>

    <div class="table-container" 
      v-if="filteredTables.length>0">

      <div class="table-card template-flex-row-between mt-1"
        v-for="table in filteredTables" :key="table.uuid"
        :class="{ active : currentTableName.name == table.name}">

          <div class="full-width" @click="renderTable(table)">
            <v-icon small :color="currentThemeObject.background">
              mdi-table-large
            </v-icon>
            <p class="body-text-small full-width">{{ table.name }}</p>
          </div>

          <div v-if="getCurrentUser.role!='ROLE_READER'">
            <v-icon
              small
              class="hovered mr-2 share"
              @click="showTableUsers(table)" >
                mdi-share-variant-outline
            </v-icon>
            <v-icon
              small
              class="hovered mr-1 pencil"
              @click="toggleTableManagement(true, table)">
                mdi-pencil-outline
            </v-icon>
            <v-icon
              small
              class="hovered trash"
              @click="dropTable(table)">
                mdi-delete-outline
            </v-icon>
          </div> 

      </div>
    </div>
    <div v-else>
      <p>No table found.</p> 
    </div>
    
  </div>
</template>

<script>
import {mapGetters} from 'vuex' 
import Confirm from '../Modals/Confirm.vue'  
import AddNewTable from '@/components/Modals/AddNewTable'
export default {
  components: {  
    Confirm,
    AddNewTable
  },
  data() {
    return {
      table: '',
      showEdit: false,
      showAdd: false,
      showDeleteConfirmation: false,
      tableToDelete: '',
      searchResult: '', 
      tableDeletingConfirmation: '',
      filtered: this.tableList, 
      editTable: {},
      showTableManagement: false
    }
  },
  mounted() {
    this.filtered = this.tableList
    if (!this.userHasTables) {
      this.showAdd = true
    }
  },
  computed: {
    ...mapGetters(['tableList', 'currentThemeObject', 'userHasTables', 'currentTableName', 'getCurrentUser']),
    filteredTables() {
      let filteredTables = []
      this.tableList.forEach(item => {
        if (item.name.includes(this.searchResult)) {
          filteredTables.push(item)
        }
      })
      return filteredTables
    }
  },
  watch: {
    tableList() {
      this.filtered = this.tableList
    }
  },
  methods: {
    renderTable(table) {
      this.$store.dispatch('switchTable', table)
      // .then(res=>{ 
      //   this.$store.dispatch('fetchCellData')
      // }) 
    },
    toggleTableManagement(showStatus, editTableDetails){
      this.showTableManagement = showStatus
      this.editTable = editTableDetails
    }, 
    dropTable(table) {
      this.showDeleteConfirmation = true
      this.tableDeletingConfirmation = `Table ${table.name}`
      this.tableToDelete = table.uuid 
    },
    updateDelete(value){
      this.showDeleteConfirmation = false
      if(value){
        this.$store.dispatch('removeTable', this.tableToDelete)
      }
    },
    showTableUsers(table){ 
      this.$store.dispatch('setRevokeAccessTableName', table)
      .then(res=>{
        this.$store.dispatch('toggleRemoveTableUserAccess')
      })
    }
  }
}
</script>

<style scoped> 

.wrapper { 
  width: 350px;
  min-width: 300px;
  padding: 20px;
  border: 1px solid var(--borderDark);
  margin-right: 10px;
  margin-top: 0;
  overflow: scroll;
  max-height: 450px;
}

.table-card {
  cursor: pointer;
  margin: 0;
  padding: 5px;
  border: 1px solid var(--borderLight); 
}

.table-card div {
  display: flex;
  align-items: center;
}

.table-card p {
  margin: 0;
  margin-left: 10px;
}

.table-card:hover {
  background-color: var(--borderDark);
  transition: all 0.2s ease;
}

.table-card.active {
  background-color: var(--borderLight);
}

.hovered {
  cursor: pointer;
}

.hovered:hover {
  color: black;
}

.full-width {
  width: 100%;
}

.add-panel {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}

.add-panel:hover {
  background-color: var(--borderLight);
}

.pencil:hover {
  color: var(--notificationSuccess);
}

.trash:hover {
  color: var(--ramsColorDanger);
}

.share:hover {
  color: blue;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 50px;
  border: 1px solid white;
} 
</style>