<template>
  <div class="body">
    <div class="router-links">
        <div class="router-link-container"> 
          <a href="#home" class="router-link theme-font">Home</a>
        </div>
        <div class="router-link-container"> 
          <a href="#features" class="router-link theme-font">About</a>
        </div>
        <div class="router-link-container"> 
          <a href="#services" class="router-link theme-font">Services</a>
        </div>
        <div class="router-link-container"> 
          <a href="#contact" class="router-link theme-font">Contact</a>
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.router-links{
  display: flex;
  align-items: center;
}
.router-link{
  margin-right: 30px;
  color: black;
}
.router-links a{
  text-decoration: none; 
  font-weight: 500;
  font-size: 13px;
}
</style>