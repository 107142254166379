<template>
  <div id="resetLink" class="template-flex-col-center">
    <div class="content">
      <div class="container-validation">
        <div class="section-validate">
          <div class="slide-window">
            <ShowEmail
              @emailSent="emailSent" />  
          </div> 
          
          <div class="section" id="otp">
            <h1 class="heading-1">Check Email</h1>
            <p class="mt-5">Please follow the mail sent to your email address to reset your password</p>
          </div>

          <div class="section-description">
            <h1 class="heading-1">Forget Password</h1>
            <p class="mt-5 heading-5">Please enter you workspace detail to continue</p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import ShowEmail from '@/components/Profile/ShowEmail.vue'   
export default {
  components: { ShowEmail },  
  methods:{
    showValidator(){
      document.querySelector('.slide-window').classList.add('right-slider')
      setTimeout(()=>{
        
      },this.$timeout.slider_animation)
    },
    emailSent(){

    }
  }
}
</script>

<style scoped>
#resetLink{ 
  width: 100%;
  height: 100vh; 
  background: url('../../Assets/images/forgot-password-cover.jpg')no-repeat center center/cover; 
}
.content{
  width: 800px;
  height: 350px; 
  position: relative;
  box-shadow: 2px 5px 10px rgba(0,0,0,0.8);
  background: url('https://source.unsplash.com/DJ7bWa-Gwks')no-repeat center center/cover;
}
.container-validation{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 10;
}
.section-validate{
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
}

.section-validate .section{
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 12;
  color: white;
}
.slide-window{
  position: absolute;
  z-index: 15;
}

.right-slider{
  animation: slide-to-right 1s ease-out 0s 1 forwards;
}

.reset-password-container{
  background-color: white;
  width: 100%;
  height: 100%; 
  display: grid;
  grid-template-columns: 300px 500px;
}

.section-description{
  color: white;
  margin-top: 70px;
}

@keyframes slide-to-right {
  to{
    transform: translateX(400px);
  }
}
</style>