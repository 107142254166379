import axios from "axios" 
import {API} from '@/api/api'
import {logout} from '@/composables/auth'
import {deleteTable, updateTable} from '@/composables/websocket'
import {getWorkspaceUuid, setCurrentTable} from '@/composables/controllers' 
import { successNotification, errorNotification } from '@/composables/notification'
import store from '@/store/index'
const state = {
  user_table_list: [], 
  current_col_data: [],
  currentTable: ''
} 
const actions = {

  getAllTables({commit, dispatch}) {    
    axios.get(`${API.tableView.tables.fetchAllTables}`)
      .then(allTables => { 
        commit('GET_TABLE', allTables.data)
        let activeTable = allTables.data[0]
        if(activeTable){    
          setCurrentTable(activeTable.uuid) 
          dispatch('updateCurrentTable', activeTable.uuid);
          dispatch('set_currentTableName', activeTable);  
          commit('SET_CURRENT_ACTIVE_TABLE', activeTable)
        } else {
          store.dispatch('show_sidebar') 
          store.dispatch('clearTable')
        }
    }) 
    .catch(error=>{
      logout() 
      window.location.assign('/')
    })
  }, 

  addNewTable({dispatch}, data) {  
    // addTable(data)
    axios.post(`${API.tableView.tables.addNewTable}`, data)
    .then(response => { 
      successNotification('Added a new table') 
      dispatch('getAllTables')
    })
    .catch(error => {
      errorNotification(error.response.data.message)
    })
  },
  
  updateTable({dispatch}, tableDetails) {
    updateTable(tableDetails) 
  },

  removeTable({dispatch}, removeTableID) {  
    deleteTable(removeTableID) 
  },

  switchTable({commit, dispatch}, tableDetails) {   
    setCurrentTable(tableDetails.uuid)
    dispatch('updateCurrentTable', tableDetails.uuid);
    dispatch('set_currentTableName', tableDetails);  
    commit('SET_CURRENT_ACTIVE_TABLE', tableDetails)
  },


  getColumnTogglerData({commit}) {   
    axios.get(`/column/hidden?tableViewUuid=${state.currentTable.uuid}&workspaceUuid=${getWorkspaceUuid()}`)
    .then(response => { 
      commit('UPDATE_SHOW_FIELDS_DATA', response.data)
    })
  }
}

const mutations = {
  'GET_TABLE'(state, values) {
    state.user_table_list = values
  }, 
  'UPDATE_LIST'(state, updatedList) {
    state.user_table_list = updatedList
  }, 
  'SET_CURRENT_ACTIVE_TABLE'(state, data){
    state.currentTable = data
  },
  'UPDATE_SHOW_FIELDS_DATA'(state, data) {
    state.current_col_data = data
  }
}

const getters = {
  tableList(state) {
    return state.user_table_list
  },
  togglerData(state) {
    return state.current_col_data
  },
  userHasTables(state) {
    return state.user_table_list.length > 0;
  },
  shareTableDetails(state){
    return state.currentTable
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
