<template>
  <div class="footer-wrap" id="contact"> 
    <!-- <h1 class="heading-2 text-center mb-10">Contact Us</h1> -->
    <div class="grid-template">
      <div class="image">
        <v-img src="@/Assets/images/form-contact.png"></v-img>
      </div>
      <div class="form pl-5">
        <h1 class="heading-3">Get in touch with us</h1>
        <p class="mb-5">Leave your message and we'll get back to you shortly</p>
        <v-text-field
          label="Name"
          v-model="formData.name"
          :rules="[() => !!formData.name || 'This field is required']"
          :error-messages="errorMessages"
          ref="formName"
        ></v-text-field>
        <v-text-field
          label="Email"
          v-model="formData.email"
          :rules="[() => !!formData.email || 'This field is required']"
          :error-messages="errorMessages"
          required
          ref="formEmail"
        ></v-text-field>
        <v-text-field
          label="Company Name"
          v-model="formData.companyName"
          :rules="[() => !!formData.companyName || 'This field is required']"
          :error-messages="errorMessages"
          required
          ref="formCompany"
        ></v-text-field> 
        <v-textarea 
          label="Message"  
          rows="3"
          v-model="formData.message"
          required
        ></v-textarea>
        <div class="d-flex justify-space-between align-center">
          <p v-if="successMessage" class="serverSuccessMessage">{{ successMessage }}</p>
          <p v-if="failureMessage" class="serverFailureMessage"> {{ failureMessage }}</p>
          <button class="send-btn" :class="{disabled: isFetching}" @click="handleSubmit"> SEND </button>
        </div>
      </div>
    </div>
  </div>
</template> 

<script> 
export default {
  data(){
    return{
      email: `mailto:${process.env.VUE_APP_HELP_EMAIL}`,
      formData: { },
      errorMessages: '',
      successMessage: '',
      failureMessage: '',
      isFetching: false
    }
  },
  methods:{
    handleSubmit(){
      if(this.validateData()){ 
        this.$axios.post(`${this.$api.contactUs.addEnquiry}`, this.formData)
        .then(response=>{
          this.onSuccess()
        }) 
        .catch(error=>{
          this.onError(error.response.data.message)
        })
      }
    },
    validateData(){
      this.isFetching = true
      this.$refs.formName.validate(true)
      this.$refs.formEmail.validate(true)
      this.$refs.formCompany.validate(true) 
      if(!this.formData.name || !this.formData.email || !this.formData.companyName ){
        this.isFetching = false
        return false 
      }
      return true
      
    },
    onSuccess(){ 
      this.successMessage = 'Successfully submitted' 
      this.failureMessage = ''
      this.isFetching = false
    },
    onError(error){
      this.failureMessage = error
      this.successMessage = ''
      this.isFetching = false
    }
  }, 
}
</script>


<style scoped>
.footer-wrap{ 
  width: 100%; 
  padding: 20px;
  margin-top: 150px; 
}   
p{
  margin: 0;
  padding: 0; 
  color: var(--textColor);
  font-weight: 400;
}  
@media(max-width:768px){
  .body{
    margin-top: 200px;
  }
}

.grid-template{
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
.send-btn{
  padding: 10px 30px;
  background-color: var(--secondary);
  color: white;
  border-radius: 30px;
}

.send-btn.disabled{
  background-color: #ccc;
  color: black;
}

.serverSuccessMessage{
  color: green;
}

.serverFailureMessage{
  color: red;
}
</style>