<template>
<div class="template-modal-stretch">
  <div class="wrapper p-relative pad-20 box-shadow-dark-3" v-click-outside="closeModal"> 
    <h1 class="text-center mb-3 heading-5">Existing Options in Cell</h1>
    <div
      class="pill"
      :style="{
        color: currentThemeObject.background, 
        backgroundColor: currentThemeObject.foreground,
        borderColor: currentThemeObject.background}"
        v-for="value in values"
          :key="value.value">
            <v-icon small :color="currentThemeObject.background" @click="remove(value)">
              mdi-close-thick
            </v-icon>
        {{ value }} 
    </div> 
  </div>
</div>
</template>

<script> 
import {mapGetters} from 'vuex'
export default { 
  props: ['values'], 
  methods:{
    closeModal(){
      this.$emit('closeExpandOptions')
    },
    remove(val){
      this.$emit('removeOption', val)
    }
  },
  computed:{
    ...mapGetters(['currentThemeObject'])
  }
}
</script>
<style scoped> 
.wrapper{  
  width: 300px;
  margin: auto; 
  background-color: white; 
  border-radius: 10px; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.pill{ 
  display: inline;
  border: 2px solid transparent;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 0 10px;
  font-weight: 1000; 
}
</style>