const state = {
  series: [],
  xAxis: [],
  totalAnalytics: {
    totalWorkspaces: {},
    totalUsers: {},
    totalTables: {},
    activeUsers: {}
  },
  lastUpdated: '',
  analysisRange: 10
}

const actions = {
  setTotalAnalytics({ commit }, totalAnalytics) {
    commit('SET_TOTAL_ANALYTICS', totalAnalytics)
    let currentTime = `${new Date().getHours()}:${new Date().getMinutes()}`
    let ampm = (new Date().getHours() >= 12) ? "PM" : "AM";
    const time = `${currentTime} ${ampm}`
    commit('SET_LAST_UPDATED', time)
  },
  setAxisData({ commit }, axisData) { 
    commit('SET_AXIS_DATA', axisData) 
  },
  setSeriesData({ commit }, seriesData) { 
    commit('SET_SERIES_DATA', seriesData)
  },
  setAnalysisRange({ commit }, range) {
    commit('SET_RANGE', range)
  }
}

const mutations = {
  'SET_TOTAL_ANALYTICS'(state, value) {
    state.totalAnalytics.totalWorkspaces = value.workspaceData
    state.totalAnalytics.totalUsers = value.userData
    state.totalAnalytics.totalTables = value.tableData
    state.totalAnalytics.activeUsers = value.totalActiveUsers
  },
  'SET_AXIS_DATA'(state, value) {
    state.xAxis = value
  },
  'SET_SERIES_DATA'(state, value) {
    state.series = value
  },
  'SET_LAST_UPDATED'(state, time) {
    state.lastUpdated = time
  },
  'SET_RANGE'(state, range) {
    state.analysisRange = range
  }
}

const getters = {
  getSeriesData(state) {
    return state.series
  },
  getAxisData(state) {
    return state.xAxis
  },
  getDashboardTotals(state) {
    return state.totalAnalytics
  },
  getLastUpdated(state) {
    return state.lastUpdated
  },
  getAnalysisRange(state) {
    return state.analysisRange
  }
}

export default{
  state,
  actions,
  mutations,
  getters
}