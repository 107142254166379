<template>
  <div class="boxes">
    <div class="box">
      <h1> {{ getDashboardTotals.totalWorkspaces.total }}</h1>
      <h4>Total Workspaces</h4>
      <div class="line-white my-1"></div>
      <div class="d-flex justify-space-between">
        <span>Active: {{ getDashboardTotals.totalWorkspaces.active }}</span>
        <span>Inactive: {{ getDashboardTotals.totalWorkspaces.inactive }}</span>
      </div> 
    </div>
    <div class="box">
      <h1> {{ getDashboardTotals.totalUsers.total }}</h1>
      <h4>Total Users</h4>
      <div class="line-white my-1"></div>
      <div class="d-flex justify-space-between">
        <span>Active: {{ getDashboardTotals.totalUsers.active }}</span>
        <span>Inactive: {{ getDashboardTotals.totalUsers.inactive }}</span>
      </div> 
    </div>
    <div class="box">
      <h1> {{ getDashboardTotals.totalTables.total }}</h1>
      <h4>Total Tables</h4>
      <div class="line-white my-1"></div>
      <div class="d-flex justify-space-between">
        <span>Active: {{ getDashboardTotals.totalTables.active }}</span>
        <span>Inactive: {{ getDashboardTotals.totalTables.inactive }}</span>
      </div> 
    </div>
    <div class="box">
      <h1> {{ getDashboardTotals.activeUsers }}</h1>
      <h4>Active Users</h4>
      <div class="line-white my-1"></div>
      <div class="d-flex justify-space-between">
        <span>Last Modified: {{ getLastUpdated }}</span>
      </div> 
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed:{
    ...mapGetters(['getDashboardTotals', 'getLastUpdated'])
  } 
}
</script>

<style scoped>
.boxes{
  background-color: transparent;
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  justify-content: space-between;
  padding: 5px;
}
.box{
  padding: 10px 20px 8px;
  color: white;
  border-radius: 5px;
}
.line-white{
  border-bottom: 1px solid white;
} 
h1{
  font-size: 30px;
}
h4{
  font-size: 20px;
}
.d-flex{
  align-items: center;
  margin-top: 10px;
}
.box:nth-child(1){
  background-color: #64B5F6;
}
.box:nth-child(2){
  background-color: #FF8A65;
}
.box:nth-child(3){
  background-color: #FFB74D;
}
.box:nth-child(4){
  background-color: #81C784;
}
</style>