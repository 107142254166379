<template>
<div class="body">
  <toggle-button 
    :value="value" 
    :color="currentThemeObject.background" 
    :sync="true"  
    @change="updateValue"
    class="block" />
</div>

</template>

<script> 
import {mapGetters} from 'vuex'
export default { 
  props: ['dataValue', 'row_uuid', 'col_uuid'],
  data(){
    return{
      value: false
    }
  },
  mounted(){
    this.value = this.convertValue
  },
  methods:{
    updateValue(){
      this.value = !this.value 
      let data = {
        rowUuid: this.row_uuid,
        columnUuid: this.col_uuid,
        value: this.value,
        uuid: this.dataValue.uuid
      }
      this.$store.dispatch('updateCell', data)
    }    
  }, 
  computed:{
    ...mapGetters(['currentThemeObject']),
    convertValue(){
      return this.dataValue.value == 'true'        
    }
  }
}
</script>

<style scoped>
.body{
    display: flex;
    justify-content: center;
    width: 100%; 
} 
</style>