<template>
<input
  type="text"
  v-model="cellData" 
  :id="dataValue.uuid"
  @keydown="updateCell" 
>
</template>

<script> 
export default {
  props: ['dataValue', 'row_uuid', 'col_uuid'],
  data() {
    return {
      cellData: '',
      timeout: this.$timeout.cell_update_timeout
    }
  },
  watch: {
    dataValue() {
      this.cellData = this.dataValue.value
    }
  },
  methods: {
    update() {
      let data = {
        rowUuid: this.row_uuid,
        columnUuid: this.col_uuid,
        value: this.cellData.trim(),
        uuid: this.dataValue.uuid
      }
      this.$store.dispatch('updateCell', data)
    },
    updateCell() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => { 
        this.update()
      }, this.$timeout.cell_update_timeout);
    }
  }
}
</script>

<style scoped>  
</style>