const shareTableOptions = [
  {id: 1, name: 'ADMIN'},
  {id: 2, name: 'MANAGER'},
  {id: 3, name: 'UPDATER'},
  {id: 4, name: 'READER'},
]

const DEFAULT_SHARE_TABLE_ROLE = 'READER'
const MINIMUM_TABLE_NAME_LENGTH = 2
const MINIMUM_SUGGESTION_LENGTH = 2

const WORKSPACE_HEADERS = [
  {
    text: 'Workspace name',
    value: 'name'
  },
  {
    text: 'Domain',
    value: 'domain'
  },
  {
    text: 'Contact Name',
    value: 'contactName'
  },
  {
    text: 'Contact Email',
    value: 'contactEmail'
  },
  {
    text: 'Contact Number',
    value: 'contactNumber'
  } 
] 


export {
  shareTableOptions,
  DEFAULT_SHARE_TABLE_ROLE,
  MINIMUM_TABLE_NAME_LENGTH,
  MINIMUM_SUGGESTION_LENGTH,
  WORKSPACE_HEADERS
}