const stroke = {
  show: true,
  curve: 'smooth',
  lineCap: 'butt', 
  width: 3,     
} 
const chartHeight = 300 
const analyticsRange = ['Last 10 Days', 'Last 30 Days', 'Last 90 Days']

const formatChartoptions = (id, colorsArray, xAxisData) => {
  return  {
    chart: {
      id: id,
      toolbar: { show: false }
    },
    colors: colorsArray,
    xaxis: { categories: xAxisData },
    stroke: stroke
  }
}

export {
  stroke,
  chartHeight,
  analyticsRange,
  formatChartoptions
}