<template>
<div class="template-modal-stretch">
  <div class="wrapper p-relative box-shadow-dark-3" v-click-outside="exit">
    <div class="template-abs-top-right" @click="exit">
      <v-icon color="error">
        mdi-close-thick
      </v-icon>
    </div>
    <h1 class="heading-2 text-center">Theme Manager</h1>
    <div class="color-container">
      <div class="color-item template-flex-col-center"
        v-for="color in getColorPallette" 
        :key="color.background">
        <button
          v-if="currentThemeObject.background!=color.background"
          :style="{backgroundColor: color.background, color: color.foreground}"  
          class="color-button box-shadow-dark-3-hover"
          @click="updateTheme(color)"> 
        </button> 
        <button
          v-else
          :style="{backgroundColor: color.background, color: color.foreground}"  
          class="color-button box-shadow-dark-3-hover color-button-active"
          @click="updateTheme(color)"> ✔
        </button> 
      </div>
    </div>         
  </div>
</div>
</template>

<script> 
import {mapGetters} from 'vuex'
import {setRamsTheme} from '../../composables/theme'
import {successNotification, errorNotification} from '../../composables/notification'
import {getUserManagementWorkspace, getWorkspaceUuid} from '../../composables/controllers'
export default {
  methods:{
    exit(){
      this.$store.dispatch('toggleThemeChanger', false)
    },
    updateTheme(color){    
      let updateData
      this.$axios.get(`${this.$api.theme.fetchTheme}`)
      .then(workspace=>{
        updateData = workspace.data
        updateData.foregroundColor = color.foreground 
        updateData.backgroundColor = color.background
      })
      .then(response=>{
        this.$axios.put(`${this.$api.theme.updateTheme}`, updateData)
        .then(res=>{ 
          successNotification('Theme saved')
          this.reflectTheme(color)
        }) 
      }) 
    },
    reflectTheme(color){
      let currentRole = this.getCurrentUser.role
      if(currentRole === 'ROLE_ADMIN'){
        this.updateUITheme(color)
      }
      if(getCurrentUser.superAdmin && (getUserManagementWorkspace() === getWorkspaceUuid())){
        this.updateUITheme(color)
      }
    },
    updateUITheme(color){
      this.$store.dispatch('setTheme', color) 
      setRamsTheme(color.background, color.foreground)  
    } 
  },
  computed: {
    ...mapGetters(['getColorPallette', 'getCurrentUser', 'currentThemeObject'])
  }
}
</script>

<style scoped> 
.wrapper{ 
  width: 500px;
  top: 10%; 
  margin: auto;
  padding: 40px 5px;
  background-color: white; 
  border-radius: 10px; 
}
.color-container{
  display: grid;
  grid-template-columns: repeat(5,17%);
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
}
.color-item{
  width: 100%;
  height: 100%;
  margin: 5px; 
}
.color-button{
  width: 100%;
  height: 30px; 
  box-shadow: 1px 1px 3px rgba(0,0,0,0.5);  
} 
.heading-2{
  font-size: 20px;
  margin-bottom: 20px;
}  
.color-button-active{
  font-weight: 800;
  font-size: 15px;
}
@media(max-width:900px){
  .wrapper{
    top: 2vh;
    height: 95vh;
    width: 95vw;
  }
}
</style>