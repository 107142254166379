<template>
<div class="body">
  <ImageCellExpansion 
    v-if="showExpansion"
    @exit="showExpansion=false"
    :image="dataValue.value"
   />
   <div class="header">
     <input type="file" accept="image/**" @change="handleFileChange($event)" v-if="!dataValue.value">
     <div v-else class="d-flex" >
       <v-icon class="mr-1" color="black" small @click="showExpansion=true">mdi-open-in-new</v-icon>
       <v-icon class="mr-3" color="black" small @click="deleteImage">mdi-delete</v-icon>
       <img :src="dataValue.value" alt="" class="image-attachment-cell"> 
     </div>
     
   </div>
  
</div>
</template>

<script>
import ImageCellExpansion from '@/components/Modals/ImageCellExpansion.vue'
export default {
  props: ['dataValue', 'row_uuid', 'col_uuid'],
  components: { ImageCellExpansion },
  data() {
    return {
      images: '',
      showExpansion: false,
      finalImage: ''
    }
  },
  methods: { 
    handleFileChange(event){ 
      const selectedFile = event.target.files[0] 
      let formData = new FormData();
        formData.append('attachment', selectedFile)   
        this.$axios.put(`/rowValue/attachment/${this.dataValue.uuid}`, formData)
        .then(response=>{ 
          this.dataValue.value = response.data.value
          let data = {
            rowUuid: this.row_uuid,
            columnUuid: this.col_uuid,
            value: null,
            uuid: this.dataValue.uuid, 
          }
          this.$store.dispatch('updateCell', data)
        })
        .catch(error=>{ 
        }) 
    },
    deleteImage(){
      let data = {
        rowUuid: this.row_uuid,
        columnUuid: this.col_uuid,
        value: '',
        uuid: this.dataValue.uuid
      }
      this.$store.dispatch('updateCell', data)
    }

  },
  watch: {
    dataValue() {
      this.images = this.dataValue.value
    }
  },
}
</script>

<style scoped>   
.image-header{
  border: 1px solid red;
} 
.image-attachment-cell{ 
  max-height: 40px;
  max-width: 40px;
}
</style>