import store from '../store/index' 
import axios from 'axios'
import {API} from '@/api/api'
let theme_background = process.env.VUE_APP_THEME_BACKGROUND
let theme_foreground = process.env.VUE_APP_THEME_FOREGROUND

const setDefaultTheme = () =>{
    if (!window.localStorage.getItem(theme_background) || !window.localStorage.getItem(theme_foreground)) { 
        let color = {
            background: '#000',
            foreground: '#fff'
        }
        window.localStorage.setItem(theme_background, color.background)
        window.localStorage.setItem(theme_foreground, color.foreground) 
        // store.dispatch('setTheme', color) 
    }
} 

const setRamsTheme = (background, foreground) =>{
    let color = {
        background: background,
        foreground: foreground
    } 
    window.localStorage.setItem(theme_background, color.background)
    window.localStorage.setItem(theme_foreground, color.foreground)
    store.dispatch('setTheme', color) 
} 

const setCurrentTheme = () => {
    setDefaultTheme() 
    axios.get(`${API.workspace.getWorkspaceDetails}`)
    .then(res=>{
      setRamsTheme(res.data.backgroundColor, res.data.foregroundColor)
    })
}

export { 
    setDefaultTheme, 
    setRamsTheme, 
    setCurrentTheme
}