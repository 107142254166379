<template>
<div class="body box-shadow-dark-3 pad-20 p-absolute" v-click-outside="exit"> 
  <div class="template-flex-row-center mb-5">
    <v-icon small color="black" class="mr-3">mdi-share-variant-outline</v-icon>
    <h1 class="text-center heading-6">SHARE </h1>
  </div>

  <v-text-field
      label="Table Name:"
      v-model="table_name"
      disabled
  ></v-text-field> 

  <v-text-field
      label="Recipient email:"
      v-model="recipient_email"
      class="l-space-1" 
  ></v-text-field>

  <div class="template-flex-col-between" v-if="suggestions.length<1">
    <div class="p-relative cursor-pointer">
      <p @click="showRoleOptions = !showRoleOptions" class="selected-role flex align-center">Selected Role: 
        <span class="flex align-center ml-4">
          <span>{{ role }}</span>
            <v-icon 
              color="error"  
              small>
                mdi-arrow-down-drop-circle-outline
            </v-icon>
        </span> 
      </p>
      <div class="role-options flex-col text-center" v-if="showRoleOptions">
        <v-btn 
          block
          :style="{borderColor: currentThemeObject.background, color: currentThemeObject.background}"
          v-for="option in options"
          :key="option.id"
          @click="selectOption(option)"
          class="option-button-role"
        >
          {{ option.name }}
        </v-btn>
      </div>
    </div> 
  </div>

  <div v-else class="suggestion-box">
    <div v-for="suggestion in suggestions" :key="suggestion.uuid">
      <p 
        class="suggestion-option cursor-pointer"
        :style="{borderColor: currentThemeObject.background}"
        @click="updateSuggestion(suggestion)"
      >{{ suggestion.email }}</p>
    </div>
  </div>

  <div class="grid-2 mt-5">
    <button 
      class="btn btn-cancel" 
      :style="{
        color: currentThemeObject.foreground == '#fff' ? currentThemeObject.background : 'black', 
        borderColor: currentThemeObject.foreground == '#fff' ? currentThemeObject.background : 'black'}"
      @click="exit">
      CLOSE
    </button>
    
    <button 
      class="btn btn-confirm" 
      :style="{
        backgroundColor: currentThemeObject.background,
        color: currentThemeObject.foreground }"
      @click="shareTable">
      SHARE
    </button> 
  </div>

</div> 
</template>

<script> 
import {mapGetters} from 'vuex'  
import {SHARE_TABLE_ERROR} from '@/constants/errorValidations'
import {getWorkspaceUuid, getUserUuid} from '@/composables/controllers'
import {successNotification, errorNotification} from '@/composables/notification' 
import {shareTableOptions, DEFAULT_SHARE_TABLE_ROLE, MINIMUM_SUGGESTION_LENGTH} from '@/constants/objectConstants'
import { issueNotification } from '../../composables/websocket'
export default {
  data() {
    return {
      suggestions:[],
      timeout: '', 
      table_id: '', 
      table_name: '', 
      recipient_email: '',
      showRoleOptions: false,
      options: shareTableOptions,
      role: DEFAULT_SHARE_TABLE_ROLE
    }
  },
  created() {
    this.resetShareTableDetails()
  }, 
  methods: {
    resetShareTableDetails(){
      this.table_name = this.shareTableDetails.name
      this.table_id = this.shareTableDetails.uuid 
    },
    exit() {
      this.$store.dispatch('setShareTheme', false)
    },
    selectOption(option) {
      this.role = option.name
      this.showRoleOptions = false
    }, 
    modifyRole(role){
      let modifiedRole = role.substring(0,1)
      modifiedRole += role.substring(1).toLowerCase()
      return modifiedRole
    },
    shareTable(){
      if(this.validateEmail()){
        let data = { 
          email: this.recipient_email,
          role: this.modifyRole(this.role),
          workspaceUuid: getWorkspaceUuid(),
          access: true,
          userUuid: getUserUuid(), 
        } 
        this.$axios.put(`/tableView/${this.shareTableDetails.uuid}`, data) 
        .then(response=>{  
          successNotification(`Table shared with ${data.email}`)
          issueNotification(data.email, null)
        })
        .catch(err=>{
          errorNotification(err.response.data.message)
        })
      } 
    }, 
    getSuggestions(){
      this.$axios.get(`${this.$api.table.searchUser}`,
      {
        params:{
          email: `${this.recipient_email}`,
          workspaceUuid: `${getWorkspaceUuid()}`
        }
      })
      .then(res=>{
        this.suggestions = res.data
      })
    },
    updateSuggestion(suggestion){
      this.recipient_email = suggestion.email
    },
    updateSuggestions() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => { 
        if(this.recipient_email.length>MINIMUM_SUGGESTION_LENGTH){
          this.getSuggestions()
        }
      }, this.$timeout.share_table_suggestions);
    },
    validateEmail(){
      if(this.recipient_email.includes('@') && this.recipient_email.includes('.')){
        return true
      }else{
        errorNotification(SHARE_TABLE_ERROR)
        return false
      }
    }
  },  
  watch: {
    shareTableDetails() {
      this.resetShareTableDetails()
    },
    recipient_email(){  
      this.updateSuggestions()
    }
  },
  computed: {
    ...mapGetters(['shareTableDetails','currentThemeObject'])
  }
}
</script>

<style scoped>
.body { 
  width: 350px;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%); 
  background-color: white; 
  z-index: 9000;
}   

.grid-2 {
  display: flex;
  justify-content: center; 
}  

.role-options { 
  right: 0;
  top: 20px;
  z-index: 8000;
  width: 100%;
}

.option-button-role {
  width: 260px;
  margin-top: 5px;
  border: 1px solid transparent;
}

.selected-role{ 
  color: red; 
}   

.btn{
  padding: 10px 20px;
  width: 110px;
  margin-right: 10px;
  font-weight: bold;
  border-radius: 5px;
}
.btn-confirm{  
  color: white;
  transition: all 0.3s ease;
}

.btn:hover{
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
} 

.btn-cancel{
  background-color: white;
  border: 1px solid transparent; 
} 

.suggestion-box{
  max-height: 200px;
  overflow-y: scroll;
}
.suggestion-option{
  text-align: center;
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 5px; 
  margin: 5px 0 0 0; 
}
.suggestion-option:hover{
  background-color: var(--borderLight);
}
</style>