<template>
  <div class="body"> 
    <div class="super-admin-navbar px-5 pt-2">
      <div class="d-flex justify-space-between">
        <h1 class="font-larger ml-15">RAMS DASHBOARD</h1>
        <div class="d-flex superadmin-links">
          <div class="d-flex align-center"  @click="showDashboard">
            <v-icon class="mr-1" color="#35829F">mdi-home</v-icon>
            <h1>HOME</h1>
          </div>
          <div class="d-flex align-center" @click="showAnalytics">
            <v-icon class="mr-1" color="#35829F">mdi-star</v-icon>
            <h1 >ANALYTICS</h1>
          </div>
          <div class="d-flex align-center" @click="showWorkspace">
            <v-icon class="mr-1" color="#35829F">mdi-folder</v-icon>
            <h1>WORKSPACE</h1>
          </div>
          <div class="d-flex align-center" @click="showHealth">
            <v-icon class="mr-1" color="#35829F">mdi-gauge</v-icon>
            <h1>HEALTH</h1>
          </div>
          <div class="d-flex align-center" @click="showEnquiries">
            <v-icon class="mr-1" color="#35829F">mdi-card-text</v-icon>
            <h1>ENQUIRIES</h1>
          </div>
          <div class="d-flex align-center" @click="signout">
            <v-icon class="mr-2" color="#35829F">mdi-power</v-icon>
            <h1>SIGNOUT</h1>
          </div> 
        </div>
      </div>
    </div> 
    <!-- <div class="custom-drawer elevation-10">
      <v-card>
        <v-navigation-drawer
          permanent
          expand-on-hover
        >
          <v-list>
            <v-list-item class="px-2">
              <v-list-item-avatar>
                <v-img :src="getCurrentUser.imageUrl"></v-img>
              </v-list-item-avatar>
            </v-list-item>

            <v-list-item link>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ getCurrentUser.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ getCurrentUser.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list
            nav 
          >
            <v-list-item link @click="showDashboard">
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title >Home</v-list-item-title>
            </v-list-item>
            
            <v-list-item link @click="showAnalytics">
              <v-list-item-icon>
                <v-icon>mdi-star</v-icon>
              </v-list-item-icon>
              <v-list-item-title >Analytics</v-list-item-title>
            </v-list-item>

            <v-list-item link @click="showWorkspace">
              <v-list-item-icon>
                <v-icon>mdi-folder</v-icon>
              </v-list-item-icon>
              <v-list-item-title >Workspaces</v-list-item-title>
            </v-list-item> 

            <v-list-item link @click="showHealth">
              <v-list-item-icon>
                <v-icon>mdi-gauge</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Health</v-list-item-title>
            </v-list-item>

            <v-list-item link @click="showEnquiries">
              <v-list-item-icon>
                <v-icon>mdi-card-text</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Enquiries</v-list-item-title>
            </v-list-item>

            <v-list-item link @click="signout">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-title >Sign Out</v-list-item-title>
            </v-list-item> 
            
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </div> -->
    <div id="dashboard" class="px-10 mb-5">
      <div> 
        <Analytics v-if="showComponents.analytics" />
      </div>
      <div class="total-analytics elevation-5 pa-5 mb-5" v-if="showComponents.analytics">
        <div class="vue-chart-options d-flex justify-space-between">
          <h1 class=" heading-3 superAdminThemeText">Total Analysis</h1>
          <div>
            <h1 class="text-center font-larger mb-2">
              Last 
              <span class="superAdminThemeText font-larger font-italic font-bold">
                {{ getAnalysisRange }}
              </span> days analysis
            </h1>
            <div class="d-flex"> 
              <button 
                class="mr-2 range-button" 
                @click="setAnalyticsRange(10)">
                  Last 10 Days
              </button>
              <button 
                small 
                class="mr-2 range-button"
                @click="setAnalyticsRange(30)">
                  Last 30 Days
              </button>
              <button 
                small 
                class="range-button"
                @click="setAnalyticsRange(90)">
                  Last 90 Days
              </button>
            </div> 
          </div>
        </div>
        
        <div id="consolidated-data" class="mt-5 pa-0 ">
          <div class="chart"> 
            <ChartWorkspace />
            <p class="text-center">Workspaces</p>
          </div>
          <div class="chart"> 
            <ChartUsers />
            <p class="text-center">Users</p>
          </div>
          <div class="chart"> 
            <ChartTables />
            <p class="text-center">Tables</p>
          </div> 
        </div>
      </div> 
      <WorkspaceList class="mb-5" v-if="showComponents.workspace"  />
      <Applications class="mb-5 elevation-5" v-if="showComponents.enquiry" />
      <HealthMonitor class="mb-5" v-if="showComponents.health" /> 
    </div>  
  </div>
</template>

<script>  
import {mapGetters} from 'vuex'
import {logout} from '@/composables/auth' 
import {errorNotification} from '@/composables/notification'
import UserList from '@/components/Analysis/UserList.vue'
import Analytics from '@/components/Analysis/Analytics.vue' 
import ChartUsers from '@/components/Analysis/ChartUsers.vue' 
import ChartTables from '@/components/Analysis/ChartTables.vue' 
import Applications from '@/components/Analysis/Applications.vue'
import HealthMonitor from '@/components/Analysis/HealthMonitor.vue'
import WorkspaceList from '@/components/Analysis/WorkspaceList.vue'  
import ChartWorkspace from '@/components/Analysis/ChartWorkspace.vue'    
export default {
  components: { 
    UserList,
    Analytics,
    Applications ,
    WorkspaceList,
    ChartWorkspace,
    ChartUsers,
    ChartTables, 
    HealthMonitor
  },
  data(){
    return{
      analytics: '', 
      showComponents: {
        analytics: true,
        workspace: true, 
        health: false,
        enquiry: true 
      }
    }
  },
  created(){    
    this.$axios.get(`${this.$api.analytics.totalAnalytics}`)
    .then(response=>{  
      this.$store.dispatch('setTotalAnalytics', response.data)
    })
    .catch(error=>{ errorNotification(error.response.data) })
  },  
  methods:{ 
    showDashboard(){
      this.showComponents = { analytics: true, workspace: true,  health: true, enquiry: true  }
    },
    showAnalytics(){
      this.showComponents = { analytics: true, workspace: false, health: false, enquiry: false  }
    },
    showWorkspace(){
      this.showComponents = { analytics: false, workspace: true, health: false, enquiry: false  }
    }, 
    showEnquiries(){
      this.showComponents = { analytics: false, workspace: false, health: false, enquiry: true  }
    },
    showHealth(){
      this.showComponents = { analytics: false, workspace: false, health: true, enquiry: false  }
    },
    showUsers(){
      this.showComponents = { analytics: false, workspace: false, health: false, enquiry: false  }
    },
    signout(){ 
      logout()
      this.$router.push({ name: 'SuperAdminLogin'})
    },
    setAnalyticsRange(range){
      this.$store.dispatch('setAnalysisRange', range)
    }
  },
  computed:{
    ...mapGetters(['getCurrentUser', 'getAnalysisRange'])
  }
}
</script>

<style scoped> 
#dashboard{
  padding: 20px;
  background-color: #FFFAFA;
}
.total-analytics{
  background-color: white;
  margin-top: 20px;
}
#analysedList{
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;
  align-items: center;
}
.analysed-list-component{ 
  padding: 10px;
  border-radius: 2px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.8); 
  max-height: 400px;
}
.analytics{
  display: grid;
  grid-template-columns: 50% 50%; 
  align-items: center;
} 
.charts{
  display: grid;
  grid-template-columns: 65% 35%;
}
.super-admin-navbar{ 
  color: var(--superAdminTheme);
  background-color: #FEFAFA;
  padding-bottom: 10px;
  margin-bottom: 10px;
  box-shadow: 8px 0 10px rgba(53, 130, 159, 0.8);
}
#consolidated-data{
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
}
.chart{
  border: 1px solid #eee; 
}
.chart p{
  padding: 15px;
  margin-bottom: 0; 
  font-weight: bold;
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.chart:nth-child(1) p{
  background-color: #64B5F6; 
}
.chart:nth-child(2) p{
  background-color: #FF8A65; 
}
.chart:nth-child(3) p{
  background-color: #FFB74D; 
} 
.custom-drawer{
  position: fixed;
  z-index: 100;
}
.range-button{
  background-color: white;
  color: var(--superAdminTheme);
  border: 1px solid var(--superAdminTheme);
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
}
.font-larger{
  font-size: 18px;
}
.superadmin-links h1{
  margin-right: 20px;
  font-size: 16px;
  color: var(--superAdminTheme);
}
.superadmin-links div{
  cursor: pointer; 
  padding: 5px 10px;
}
.superadmin-links div:hover{
  background-color: #eee;
}

@media(max-width: 1200px){
  .superadmin-links h1{
    margin-right: 10px;
    font-size: 12px;
  }
}
</style>