import axios from 'axios'
import { getUserUuid, getWorkspaceUuid } from '../composables/controllers'
import {fetchRamsAccessToken} from '@/composables/auth'
import store from '@/store/index'

const validateAdminsAccess = () => {
  let role = store.getters.getCurrentUser.role 
  return new Promise((resolve, reject) => {
    if (role) {
      if (role === 'ROLE_ADMIN') {
        resolve()
      } else {
        reject()
      }
    }
    else { 
      axios.get(`${process.env.VUE_APP_BASE}/user?workspaceUuid=${getWorkspaceUuid()}`)
      .then(response => { 
        if (response.data.role.includes('ADMIN')) { 
          resolve()
        } else { 
          reject('Unauthorized')
        }
      })
      .catch(err => { 
        reject('Unauthorized')
      }) 
    } 
  })
}

const validateSuperAdminAccess = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.VUE_APP_BASE}/user/details/${getUserUuid()}`,
      {
        headers: {
        'Authorization' : `Bearer ${fetchRamsAccessToken()}`
      }
    })
      .then(response => { 
      if (response.data.superAdmin) { 
        resolve()
      } else { 
        reject('Unauthorized')
      }
    })
    .catch(err => { 
      reject('Unauthorized')
    }) 
  }) 
} 

export { 
  validateAdminsAccess,
  validateSuperAdminAccess
}