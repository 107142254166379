<template>
  <div class="body-profile">
    <Navbar />
    <UserSidebar/>
    <div class="content">

      <div class="profile-non-edit elevation-5">
        <div class="image-upload-section ">
          <UploadImage :src="getUserProfilePicture" class="test" />
        </div>
        
        <div class="profile-info-basic pa-5"> 
          <v-text-field
            label="Name"
            :value="formData.name"
            disabled
            class="mt-10"></v-text-field>

          <v-text-field
            label="Email"
            :value="getCurrentUser.email"
            disabled
            ></v-text-field>  
        </div> 
      </div>

      <div class="profile-edit elevation-5">  
        <div class="edit-profile-header">
          <h1 class="heading-4">Edit Profile</h1> 
        </div> 
        <div class="profile-options mt-4">
          <div class="input-group"> 
            <div v-if="editName">
              <div class="template-flex-row-between"> 
                <label>Name</label>
                <v-icon small @click="editName=false">mdi-close-thick</v-icon>
              </div>
              <input type="text" v-model="formData.name">
            </div>  
            <div v-else class="defaultInput"> 
              <div class="template-flex-row-between">
                <p class="heading-5 mb-2">Name </p>
                <v-icon @click="editName=true" class="mr-5" v-if="getCurrentUser.authProvider!='google'">
                  mdi-pencil-outline
                </v-icon> 
              </div> 
              <p>{{ formData.name}}</p> 
            </div>  
          </div>
          <div class="input-group">
            <div v-if="editContact">
              <div class="template-flex-row-between">
                <label>Contact</label>
                <v-icon small @click="editContact=false">mdi-close-thick</v-icon>
              </div> 
              <input type="text" v-model="formData.contactNumber">
            </div>  
            <div v-else class="defaultInput"> 
              <div class="template-flex-row-between">
                <p class="heading-5 mb-2">Contact </p>
                <v-icon @click="editContact=true" class="mr-5" v-if="getCurrentUser.authProvider!='google'">
                  mdi-pencil-outline
                </v-icon> 
              </div> 
              <p>{{ formData.contactNumber}}</p> 
            </div>  
          </div> 
        </div> 

        <div v-if="isUpdatingPasword">
          <h1 class="heading-5 my-4 pl-1">Update Password</h1>
          <div class="profile-options mt-1">
            <div class="input-group">
              <label>Old Password</label>
              <input type="password" v-model="formDataPassword.oldPassword">
            </div>
            <div class="input-group">
              <label>New Password</label>
              <input type="password" v-model="formDataPassword.newPassword">
            </div>
            <div class="input-group">
              <label>Confirm Password</label>
              <input type="password" v-model="formDataPassword.rePassword">
            </div>
          </div> 
          <div class="mt-5">
            <v-btn 
              :style="{
                backgroundColor: currentThemeObject.background,
                color: currentThemeObject.foreground
              }" 
              @click="updateCompleteProfile"
              class="mr-4">
                Update Profile
            </v-btn>
            <v-btn 
              :style="{
                backgroundColor: currentThemeObject.background,
                color: currentThemeObject.foreground
              }" 
              @click="isUpdatingPasword=false">
              Close
            </v-btn>
          </div> 
        </div>


        <v-btn
          v-if="getCurrentUser.authProvider!='google' && !isUpdatingPasword"
          class="mt-5 mr-5" 
          :style="{
            backgroundColor: currentThemeObject.background,
            color: currentThemeObject.foreground
          }" 
          @click="updateBasicDetails"
        >
          Update Profile
        </v-btn>

        <v-btn
          v-if="getCurrentUser.authProvider!='google' && !isUpdatingPasword"
          class="mt-5" 
          :style="{
            backgroundColor: currentThemeObject.background,
            color: currentThemeObject.foreground
          }" 
          @click="isUpdatingPasword=true">
            Update Password
        </v-btn>
        
      </div>
    </div>  

    <div class="all-notifications">
      <AllNotifications />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/UI/Navbar.vue' 
import UploadImage from '@/components/Profile/UploadImage.vue' 
import UserSidebar from '@/components/Modals/UserSidebar.vue'
import AllNotifications from '@/components/Profile/AllNotifications.vue'

import { mapGetters} from 'vuex'
import { setCurrentTheme } from '@/composables/theme'  
import { fetchCurrentUser } from '@/composables/auth'
import { errorNotification, successNotification } from '@/composables/notification'
export default {
  components: {Navbar, UploadImage, AllNotifications, UserSidebar}, 
  data(){
    return{
      isUpdatingPasword: false,
      editName: false,
      editContact: false, 
      formData: {},
      formDataPassword: {}
    }  
  },
  created(){
    setCurrentTheme() 
    fetchCurrentUser()
    .then(result=>{
      this.formData = {
        name: this.getCurrentUser.name,
        contactNumber: parseInt(this.getCurrentUser.contactNumber),
        uuid: this.getCurrentUser.uuid
      }
    })
  },
  methods:{
    validatePassword(){ 
      if(this.formDataPassword.oldPassword.length == 0){
        errorNotification('Please enter old password')
        return false
      }
      if(this.formDataPassword.newPassword.length<7){
        errorNotification('Password cannot be less than 8 characters')
        return false
      }
      if(!this.formDataPassword.newPassword === this.formDataPassword.rePassword){
        errorNotification('Password Mismatch')
        return false
      } 
      return true
    },
    updateBasicDetails(){ 
      this.$axios.put(`${this.$api.user.profile.updateProfile}`, this.formData)
      .then(response=>{ successNotification('Profile Updated') })
    },
    updateCompleteProfile(){
      if(this.formDataPassword.newPassword === this.formDataPassword.rePassword){
        this.formData.oldPassword = this.formDataPassword.oldPassword
        this.formData.password = this.formDataPassword.newPassword
        this.$axios.put(`${this.$api.user.profile.updateProfile}`, this.formData)
        .then(response=>{ successNotification('Profile Updated') }) 
      } 
    }
  },
  computed:{
    ...mapGetters(['currentThemeObject', 'getCurrentUser', 'getUserProfilePicture'])
  }
}
</script>

<style scoped>
.body-profile{
  width: 100%;
  padding: 20px 50px;
}
.content{
  display: flex;
  align-items: center; 
} 
.image-upload-section{
  width: 100%; 
  display: flex;
  justify-content: center; 
}

.profile-non-edit{ 
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 0;   
} 

p{
  margin: 0;
}

.profile-edit{
  padding: 20px 50px; 
  width: 90%;
  height: 450px;
  margin-left: 20px;
}
.profile-options{
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
  align-items: center;
}
.profile-info-basic{
  width :100%
}
.input-group{
  padding: 5px; 
}
.input-group label{
  display: block;
}
.input-group input{
  padding: 10px 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  width: 100%;
}
.update-btn{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}


@media(max-width: 768px){
  .content{ 
    grid-template-columns: 100%;
  }
  .profile-options{ 
    grid-template-columns: 100%; 
  }
  .profile-edit{
    padding: 20px 0; 
  }
}
</style>