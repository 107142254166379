<template>
  <div class="template-modal-stretch"> 
    <div class="body">
      <div class="template-abs-top-right">
        <v-icon class="" color="error" @click="exit">
          mdi-close-thick
        </v-icon>
      </div> 
      <p class="my-5 text-center heading-5">Add New Workspace</p>

      <v-text-field  
        label="Domain Name *"
        v-model="formData.domain"
      ></v-text-field> 

      <v-text-field
        label="Workspace Name *"
        v-model="formData.name"
        :rules="nameRules"
      ></v-text-field>

      <v-text-field
        label="Contact Name"
        v-model="formData.contactName"
      ></v-text-field>

      <v-text-field
        label="Contact Email"
        v-model="formData.contactEmail"
        :rules="emailRules"
      ></v-text-field>

      <v-text-field
        label="Contact Number"
        type="number"
        v-model="formData.contactNumber"
      ></v-text-field> 

    <v-btn @click="save" dark>Submit</v-btn>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex' 
import {errorNotification, successNotification} from '../../composables/notification'
import {WORKSPACE_NAME_LENGTH, ADD_WORKSPACE_VALIDATIONS} from '@/constants/errorValidations'
export default { 
  data() {
    return {
      formData: {}, 
      nameRules: [
          v => !!v || 'Name is required',
          v => (v && v.length >= WORKSPACE_NAME_LENGTH) || `Name cannot be less than ${WORKSPACE_NAME_LENGTH} characters`,
      ],
      emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ], 
    }
  },  
  methods: {
    exit() {
      this.$emit('exit')
    },
    exitModal(){
      this.exit()
    },
    save(){   
      if(this.validateEntries()){
        let data = {
          name: this.formData.name,
          contactName: this.formData.contactName,
          contactEmail: this.formData.contactEmail,
          contactNumber: this.formData.contactNumber,
          domain: this.formData.domain
        }
        this.$axios.post(`${this.$api.workspace.addWorkspace}`, data)
        .then(response=>{
          this.exitModal() 
          successNotification('New Workspace added.')
          this.$emit('refetch')
        })  
        .catch(error=>{
          errorNotification('Unable to add new workspace')
        })
      }  
    }, 
    deleteWorkspace(){
      let data = {
        name: this.formData.name,
        contactName: this.formData.contactName,
        contactEmail: this.formData.contactEmail,
        contactNumber: this.formData.contactNumber,
        uuid: this.getSelectedWorkspaceId 
      }
      this.$store.dispatch('deleteWorkspace', data)
      this.exit()
    },
    validateEntries(){  
      if(!this.formData.name.length > WORKSPACE_NAME_LENGTH){
        errorNotification(ADD_WORKSPACE_VALIDATIONS.nameValidation)
        return false
      } 
      if(!this.formData.domain){
        errorNotification(ADD_WORKSPACE_VALIDATIONS.domainValidation)
        return false
      }
      return true
    }
  },
  computed: {
    ...mapGetters(['getSelectedWorkspaceId', 'currentThemeObject'])
  }
}
</script>

<style scoped> 
.body {
  width: 500px;
  padding: 20px;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  z-index: 100;
} 
.btn{
  padding: 10px 30px;
  border: 1px solid transparent;
  border-radius: 5px;
}
.btn:hover{
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
}
.btn.save-btn{
  color: white;
}
@media(max-width:900px){
  .body{
    width: 98%;
    margin: auto;
  }
}
</style>