import {successNotification} from '../notification'
import {getCurrentTable} from '../controllers'
import store from '@/store/index'
import axios from 'axios'

import {
  formatAddColumnMessage,
  formatUpdateColumnMessage,
  formatDeleteColumnMessage
} from '@/constants/websocketMessageConstants'

const handleColumnBroadcast = (msg) => { 
  if (msg.message.tableViewUuid === getCurrentTable()) {
    if (msg.messageType.includes('ADDED')) {
      successNotification(formatAddColumnMessage(msg.message.name))
    } 
    if (msg.messageType.includes('DELETE')) {
      axios.get(`column/${msg.uuid}?tableViewUuid=${msg.message.tableViewUuid}`)
      .then(response=>{
        if(msg.message.tableViewUuid === getCurrentTable()){ 
          successNotification(formatDeleteColumnMessage(response.data.name))
        }  
      })
    } 
    if (msg.messageType.includes('UPDATED')) {
      successNotification(formatUpdateColumnMessage(msg.message.name))
    }
    fetchData()
  }   
}

const fetchData = () =>{
  store.dispatch('fetchRowData')
  store.dispatch('fetchColData') 
  store.dispatch('fetchCellData')
}

export{
  handleColumnBroadcast
}