<template>
  <div class="background-loading">
    <div class="load-screen">
      <Loader />
    </div> 
  </div>
  
</template>

<script>
import Loader from '@/components/UI/Loader.vue'
export default {
  components: { Loader }
}
</script>

<style scoped> 
.load-screen{
  z-index: 10000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.background-loading{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 8000;
}
</style>