const borderColor = {
  light: '#eee',
  medium: '#ddd',
  dark: '#ccc'
}

const ramsHomepageTheme = {
  primary: '#0EBFAA',
  secondary: '#75D752',
  text: '#7B8694'
}

const textColors = {
  loginParagraphs: '#777'
}

const colorDefaults = {
  textBlack: '#000',
  textWhite: '#fff'
}

const superAdminTheme = {
  colorPrimary: '#35829F'
} 

export{
  borderColor,
  ramsHomepageTheme,
  textColors,
  colorDefaults,
  superAdminTheme
}