<template>
  <v-data-table
    :headers="headers"
    :items="filteredList"
    sort-by="calories"
    class=" pa-5"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        class="mb-2"
      >
        <v-toolbar-title class="heading-3 superAdminThemeText">User Management</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex">
              <div class="customSearchBox mr-3">
                <v-icon class="icon-search" small>mdi-magnify</v-icon>
                <input 
                  type="text" 
                  class="customSearchBar elevation-3"
                  placeholder="search user"
                  v-model="userInput" 
                >
              </div> 
            </div> 
          </template>
          <v-card>
            <v-card-title>
              <span class="heading-3 my-4">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name"
                    ></v-text-field>
                  </v-col>  
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.contactNumber"
                      label="Number"
                    ></v-text-field>
                  </v-col>  
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="roles"
                      label="Role"
                      v-model="editedItem.role"
                    ></v-select> 
                  </v-col>  
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#35829F"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="#35829F"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px" >
          <v-card >
            <v-card-title class="text-h5">Are you sure you want to toggle this user?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#35829F" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="#35829F" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <p class="mt-5 heading-3">Data unavailable</p>
    </template>
  </v-data-table>
</template>

<script> 
  export default { 
    props: ['workspaceUuid'],
    data: () => ({
      dialog: false,
      dialogDelete: false,    
      userInput: '',  
      filteredList: [],
      headers: [
        { 
          text: 'Name', 
          value: 'name'
        }, 
        { 
          text: 'Email', 
          value: 'email'
        }, 
        { 
          text: 'Role', 
          value: 'role'
        },
        { 
          text: 'Deleted',  
          value: 'deleted'
        },  
        { 
          text: 'Actions', 
          value: 'actions', 
          sortable: false 
        },
      ],
      users: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        name: 'John Doe',
        email: 'john.doe@johndoe.co',
        contactNumber: '',
        role: 'ADMIN' 
      },
      roles: []
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Add User' : 'Edit User'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      workspaceUuid(){
        this.fetchUsers()
      },
      userInput(){
        this.filteredList = this.users.filter(user=>{
          return user.name.toLowerCase().startsWith(this.userInput.toLowerCase())
        }) 
      }
    },

    created () {
      this.initialize()
      this.fetchUsers() 
      this.$axios.get(`${this.$api.tableView.tableRoles}`)
      .then(response=>{   
        response.data.forEach((role)=>{
          this.roles.push(`${role.role} - ${role.value}`)
        })   
      })
    },

    methods: {
      initialize () {
        this.users = [ ]
      },

      editItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true 
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {  
        this.$axios.put(`/user/${this.editedItem.uuid}`, this.editedItem)
        .then(response=>{
          this.fetchUsers()
        }) 
        this.close()
      },

      deleteItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true 
      },

      deleteItemConfirm () {
        this.$axios.delete(`/user/${this.editedItem.uuid}?workspaceUuid=${this.editedItem.workspaceUuid}`)
        .then(response=>{  
          this.fetchUsers()
        }) 
        this.closeDelete()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }, 

      fetchUsers(){
        let workspace 
        if(this.workspaceUuid){
          workspace = this.workspaceUuid
        }else{
          workspace='all'
        }
        this.$axios.get(`${process.env.VUE_APP_BASE}/user/workspace?workspaceUuid=${workspace}`)
        .then(response=>{ 
          this.users = response.data
          this.filteredList = this.users
        })
      }
    },
  }
</script>

<style scoped>
.addUserBtn{
  background-color: transparent;
  color: var(--superAdminTheme);
  border: 1px solid var(--superAdminTheme);
  box-shadow: none;
} 
.customSearchBox{
  position: relative;
}
.customSearchBar{
  width: 300px;
  padding: 7px 10px 7px 40px;
}
.customSearchBar:focus{
  outline: none;
}
.icon-search{
  position: absolute; 
  top: 10px;
  left: 10px;
}
</style>