const MINIMUM_OPTIONS = 1
const MINIMIM_COLUMN_NAME_LENGTH = 3
const ADD_COLUMN_VALIDATIONS = {
  typeValidation: 'Please Select a Column Type',
  lengthValidation: 'Please choose a valid Name',
  optionValidation: `Please choose atleast ${MINIMUM_OPTIONS} option(s)`
}

const WORKSPACE_NAME_LENGTH = 2
const ADD_WORKSPACE_VALIDATIONS = {
  nameValidation: `Workspace name cannot be less than ${WORKSPACE_NAME_LENGTH} characters`,
  domainValidation: 'Please choose valid workspace domain'
}

const SHARE_TABLE_ERROR = 'Please enter a valid email'

export {
  ADD_COLUMN_VALIDATIONS,
  MINIMUM_OPTIONS,
  MINIMIM_COLUMN_NAME_LENGTH,
  WORKSPACE_NAME_LENGTH,
  ADD_WORKSPACE_VALIDATIONS,
  SHARE_TABLE_ERROR
}