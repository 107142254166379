<template>
<v-app-bar
    app
    :style="{backgroundColor: currentThemeObject.background}"  
  >
  <v-container class="brand" @click="redirectHome"> 
    <div>  
      <UserIcon :userName="workspace.name" :size="50" :userImage="getNavbarImageUrl" /> 
    </div> 
    <span class="heading-6 ml-3" :style="{ color: currentThemeObject.foreground}">
       <span class="mobile heading-6" :style="{ color: currentThemeObject.foreground}">
         Resource Allocation Management System 
      </span> 
      <span class="heading-6 font-bold">
        | {{ workspace.name }}
      </span> 
      
    </span>
  </v-container>
  <v-spacer></v-spacer>
  <v-container class="text-end header-profile heading-6 font-bold">
    <!-- <h2 class="welcome-message heading-6" :style="{ color: currentThemeObject.foreground}"> 
      <span class="hide-on-mobile heading-7">
         Welcome,
      </span> {{ getCurrentUser.name }}
    </h2>
    <UserModal/> -->
    
  </v-container>
</v-app-bar>
</template>

<script>
 
import UserModal from '../Utilities/UserModal.vue' 
import WorkspaceIcon from '../Workspaces/WorkspaceIcon.vue'
import UserIcon from '@/components/UI/UserIcon.vue'
import {mapGetters} from 'vuex'
import {fetchCurrentUser} from '../../composables/auth'
import {getWorkspaceUuid} from '../../composables/controllers'
export default {
  data(){
    return {
      themeColor: 'black',
      workspace: '' 
    }
  },
  components: {
    UserModal, 
    WorkspaceIcon,
    UserIcon
  },
  created(){
    fetchCurrentUser()
    .then(res=>{
      this.$axios.get(`/workspace/${getWorkspaceUuid()}`)
      .then(response=>{   
        this.workspace = response.data
        document.title = `${this.workspace.name} | Resource Allocation Management System`    
        if(response.data.image){
          this.$store.dispatch('setNavbarImage', response.data.image)
        } 
      })
    }) 
  },
  methods: {
    redirectHome() {
      this.$router.push({name: 'Home'})
      
    } 
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'currentThemeObject', 'getNavbarImageUrl'])
  } 
}
</script>
</script>

<style scoped> 
.brand {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.logo-image {
  width: 50px;
  height: 50px;
} 
.caption-title {
  color: black;
  margin-left: 10px;
  font-weight: 400;
  letter-spacing: 2px;
} 

.notificationPanel{
  position: absolute;
}

@media (max-width: 1200px) {
  .mobile {
    display: none;
  }
}

.header-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-profile .welcome-message {
  display: inline-block;
  color: black;
  margin-right: 10px;  
  letter-spacing: 2px;
}

@media(max-width:769px){
  .hide-on-mobile{
    display: none;
  }
}
</style>