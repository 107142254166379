<template>
  <div class="body">
      <img :src="image" alt="" v-if="image" class="image-rounded">
        <span class="initial font-bold heading-6"> {{ initials }}</span>
  </div>
</template>

<script>
export default {
    props: ['name', 'image'],
    data(){
        return{
            initials: ''
        }
    },
    mounted(){ 
        if(!this.image){
            this.setInitials()
        }
    },
    methods:{
        setInitials(){
            let second = this.name.indexOf(' ')
            if(second){
                this.initials = `${this.name[0]}${this.name[second+1]}`
            }else{
                this.initials = this.name[0]
            }
        }
    }
}
</script>

<style scoped>  
.initial{
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; 
    overflow: hidden;
    color: white;
    background-color: #4DB6AC;   
} 
</style>