
const state = {
  showSidebar: false,
  showFields: false, 
  showThemeChanger: false,
  showCreateTablePanel: false,
  showShareTheme: false,
  showAddColumn: false,
  showAdminWorkspace: false,
  showRevokeUserTable: false, 
  showRearrangeColumns: false,
  showColumnToggle: false,
  showNotificationCenter: false,
  showScreenLoader: false,
  currentTableName: '',
  removeAccessTable: '',
  currentUserName: '',
  currentTableDescription: '', 
  isSearching: false
}


const actions = {
  toggle_showSidebar({commit}) {
    commit('TOGGLE_SIDEBAR')
  },
  hide_sidebar({commit}) {
    commit('HIDE_SIDEBAR')
  },
  show_sidebar({commit}) {
    commit('SHOW_SIDEBAR')
  },
  toggle_showFields({commit}) {
    commit('TOGGLE_FIELDS')
  },
  toggle_showAddColumn({commit}, value){
    commit('SET_SHOW_ADD_COLUMN', value)
  },
  toggle_showAdminWorkspace({commit}, value){
    commit('SET_ADMIN_WORKSPACE', value)
  },
  set_currentTableName({commit}, value) {
    commit('SET_TABLE_NAME', value.name)
    commit('SET_TABLE_DESCRIPTION', value.description)
  },  
  toggleThemeChanger({commit}, value) {
    commit('TOGGLE THEME CHANGE', value)
  },
  toggleCreateTablePanel({commit}) {
    commit('toggleCreateTablePanel')
  },
  setShareTheme({commit}, value) {
    commit('SET_SHARE_THEME', value)
  },
  setNotificationCenter({ commit }, value) {
    commit('SET_NOTIFICATION_CENTER', value)
  },
  setRevokeAccessTableName({commit}, table){ 
    commit('SET_REVOKE_ACCESS_TABLE', table)
  },
  toggleRemoveTableUserAccess({commit}){
    commit('TOGGLE_REVOKE_USER_ACCESS')
  }, 
  toggleRearrangeColumn({commit}){
    commit('TOGGLE_REARRANGE_COLUMNS')
  },
  setIsSearching({commit}, value){
    commit('SET_IS_SEARCHING', value)
  },
  set_columnToggle({commit}, value){
    commit('SET_COLUMN_TOGGLE', value)
  },
  setScreenLoader({ commit }, value) {
    commit('SET_SCREEN_LOADER', value)
  }
}

const mutations = {
  'TOGGLE_SIDEBAR'(state) {
    state.showSidebar = !state.showSidebar
  },
  'HIDE_SIDEBAR'(state) {
    state.showSidebar = false
  },
  'SHOW_SIDEBAR'(state) {
    state.showSidebar = true
  },
  'TOGGLE_FIELDS'(state) {
    state.showFields = !state.showFields
  },
  'SET_TABLE_NAME'(state, value) {
    state.currentTableName = value
  },
  'SET_TABLE_DESCRIPTION'(state, value) {
    state.currentTableDescription = value
  },
  'SET_NOTIFICATION_CENTER'(state, value){
    state.showNotificationCenter = value
  },
  'TOGGLE THEME CHANGE'(state, val) {
    state.showThemeChanger = val
  },
  'toggleCreateTablePanel'(state) {
    state.showCreateTablePanel = !state.showCreateTablePanel
  },
  'SET_SHARE_THEME'(state, value) {
    state.showShareTheme = value
  },
  'SET_SHOW_ADD_COLUMN'(state, value){
    state.showAddColumn = value
  },
  'SET_ADMIN_WORKSPACE'(state, value){
    state.showAdminWorkspace = value
  },
  'SET_REVOKE_ACCESS_TABLE'(state, value){
    state.removeAccessTable = value
  },
  'TOGGLE_REVOKE_USER_ACCESS'(state){
    state.showRevokeUserTable = !state.showRevokeUserTable
  }, 
  'TOGGLE_REARRANGE_COLUMNS'(state){
    state.showRearrangeColumns = !state.showRearrangeColumns
  },
  'SET_IS_SEARCHING'(state, value){
    state.isSearching = value
  },
  'SET_COLUMN_TOGGLE'(state,value){
    state.showColumnToggle = value
  },
  'SET_SCREEN_LOADER'(state, value) {
    state.showScreenLoader = value
  }
}

const getters = {
  show_sidebar(state) {
    return state.showSidebar
  },
  show_fields(state) {
    return state.showFields
  },
  show_share_theme(state) {
    return state.showShareTheme
  },
  show_add_column(state){
    return state.showAddColumn
  },
  show_admin_workspace(state){
    return state.showAdminWorkspace
  },
  currentTableName(state) {
    return state.currentTableName
  },
  currentTableDescription(state) {
    return state.currentTableDescription
  }, 
  currentUser(state) {
    return state.currentUserName
  }, 
  showThemeChanger(state) {
    return state.showThemeChanger
  },
  getRevokeAccessTable(state){
    return state.removeAccessTable
  },
  showRevokeAccessTable(state){
    return state.showRevokeUserTable
  }, 
  showRearrangeColumns(state){
    return state.showRearrangeColumns
  },
  showColumnToggle(state){
    return state.showColumnToggle
  },
  show_NotificationCenter(state) {
    return state.showNotificationCenter
  },
  isSearching(state){
    return state.isSearching
  },
  getScreenLoader(state) {
    return state.showScreenLoader
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}