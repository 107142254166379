import axios from 'axios'
import { fetchRamsAccessToken } from '../composables/auth';
const httpServicePlugin = {
  install(Vue, options) {
    Vue.prototype.$axios = axios

    Vue.prototype.$setupAxios = function () {
      this.$axios.defaults.baseURL = process.env.VUE_APP_BASE
      this.$axios.defaults.headers['Content-Type'] = 'application/json'
      this.$axios.interceptors.request.use(req => {
        req.headers['Authorization'] = `Bearer ${fetchRamsAccessToken()}`
        return req;
      });
    }
  }
};

export default httpServicePlugin;