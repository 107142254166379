<template>
  <div class="notification-center-container" v-click-outside="exitNotificationCenter">
    <div>
      <div class="notification-header" 
        :style="{backgroundColor: currentThemeObject.foreground, color: currentThemeObject.background }">
        <h1 class="heading-5">Notifications</h1>
        <v-icon 
          @click="exitNotificationCenter"
          :style="{color: currentThemeObject.background}">mdi-close-thick</v-icon>
      </div>
      <hr> 
      <div v-if="getUserNotifications">
        <div class="notification-container"
          v-for="(notification,index) in getUserNotifications"
          :key="index"
        >
          <Notification 
            :notification="notification" 
          />
        </div>
      </div>
      <div v-else>
        <p class="text-center pa-5">No New Notification</p>
      </div>
    </div>
    
    <v-btn 
      class="notification-btn"
      :style="{backgroundColor: currentThemeObject.background, color: currentThemeObject.foreground }"
      @click="showProfile"  
    >
        See All Notifications
    </v-btn>
  </div>
</template>

<script>
import Notification from './Notification.vue'
import {mapGetters} from 'vuex'
export default {
  components: { Notification },
  created(){
    this.$axios.get(`/notification/${this.getCurrentUser.uuid}`)
    .then(response=>{ 
      this.$store.dispatch('setUserNotifications', response.data)
    })
  },
  methods:{
    exitNotificationCenter(){
      this.$store.dispatch('setNotificationCenter', false)
    }, 
    showProfile(){
      this.$store.dispatch('setNotificationCenter', false)
      this.$router.push('/profile')
    }
  },
  computed:{
    ...mapGetters(['currentThemeObject', 'getUserNotifications', 'getCurrentUser'])
  }, 
}
</script>

<style scoped>
.notification-center-container{
  width: 300px;
  height: 90vh; 
  position: fixed;
  top: 60px;
  right: 2px;
  background-color: white;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 3px 5px 10px rgba(0,0,0,0.8);
  overflow-y: scroll;
  overflow-x: hidden; 
}
.notification-container{
  border-bottom: 1px solid var(--borderDark);
}
.notification-header{
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ccc;
}
.notification-btn{
  width: 100%;
}
</style>