<template>
<div class="template-modal-stretch">
  <div class="wrapper box-shadow-dark-3" v-click-outside="closeModal">
    <div class="template-abs-top-right">
      <v-icon  
        color="error"
        @click="closeModal">
          mdi-close-thick
      </v-icon> 
    </div> 
        
    <v-form> 
      <v-text-field
        label="Column Name *"
        v-model="col_name" 
        class="mx-0 pa-0 my-1"
        :rules="nameRules" 
      ></v-text-field>
      <v-text-field
        label="Description (Optional)"
        v-model="col_desc" 
        class="mx-0 pa-0 my-1"  
      ></v-text-field>
      
      <div v-if="selectedType!='Date'">
        <v-text-field 
          label="Default Column Value (Optional)"
          v-model="col_default_value" 
          class="mx-0 pa-0 my-1"  
        ></v-text-field>
      </div>
      
    </v-form>

    <div v-if="showColumnOptions" class="constraint-height">
      <div 
        v-for="option in colOptions" 
        :key="option.id" 
        class="column-option cursor-pointer py-1"
        @click="selectType(option)"
      >
        <v-icon class="mr-2">{{ option.icon }}</v-icon>
        <span class="column-name">{{ option.value }}</span>
      </div>
    </div>

    <v-container v-else class="select-options">
      <div class="mb-3 template-flex-row-between">  
        <div @click="addSelectOption" class="drop-option">
          <v-icon small>
              mdi-plus-circle-outline
          </v-icon>
          <span>ADD</span>
        </div>

        <div @click="removeAllSelectOptions" class="drop-option">
          <v-icon 
            small>
              mdi-cached
          </v-icon>
          <span>CLEAR</span>
        </div>

        <div @click="showColumnOptions=true;isError=false" class="drop-option">
          <v-icon  
            small>
            mdi-arrow-left
          </v-icon> 
          <span>BACK</span> 
        </div>  
      </div> 

      <div v-if="selectOptions.length<1">
        <p>No Options selected</p>
      </div>

      <div v-else class="multi-select-options">
        <div v-for="selectOption in selectOptions" :key="selectOption.id" class="select-option mb-2"> 
          <input type="color" 
            v-model="selectOption.color"  
          >
          <input type="text" 
            v-model="selectOption.value" 
            class="pl-1"
          >   
          <v-icon 
            small
            @click="removeOption(selectOption.id)"
            color="error" >
                mdi-close-thick
          </v-icon>
        </div>
      </div> 

    </v-container>

    <div v-if="selectedType==='Date'" class="mt-4"> 
      <label>Select Default Date</label>
      <input type="date" class="widthFull" v-model="col_default_value">
    </div>

    <p 
      class="text-center error-text" 
      v-if="isError">
      Please enter a valid name/option
    </p>

    <p 
      class="mb-2 mt-5" 
      v-if="selectedType">
      Creating Column Type : 
      <span class="text--success">
        {{ selectedType }}
      </span
    ></p> 

    <button
      class="mt-4 create-field-button"
      :style="{backgroundColor: currentThemeObject.background}"
      @click="createField">
        Create Field
    </button>

  </div>
</div>
</template>

<script> 
import {mapGetters} from 'vuex' 
import {createNewColumn} from '@/composables/tableController'
import {errorNotification} from '@/composables/notification'
import {colorDefaults} from '@/constants/colorConstants'
import {
  ADD_COLUMN_VALIDATIONS, 
  MINIMUM_OPTIONS, 
  MINIMIM_COLUMN_NAME_LENGTH
} from '@/constants/errorValidations'

export default {
  data(){ 
    return{
      col_name: '',
      col_desc: '',
      colors: colorDefaults,
      selectedType: '',
      col_default_value: '',
      isError: false,
      isMultiSelect: false,  
      showColumnOptions: true, 
      colOptions: [], 
      selectOptions: [],
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= MINIMIM_COLUMN_NAME_LENGTH) || `Name should be atleast ${MINIMIM_COLUMN_NAME_LENGTH} characters`,
      ]
    }
  },
  created(){
    this.$axios.get(`${this.$api.tableView.columns.getColumnTypes}`)
    .then(res=>{ 
      let colTypes = res.data
      try{
        colTypes.map(column=>{
          column.value = column.value.charAt(0).toUpperCase()+column.value.substring(1) 
          column.value = column.value.replaceAll('_', ' ') 
        }) 
        this.colOptions = colTypes 
      }catch(error){ this.colOptions = res.data  } 
    })
  },
  methods:{
    addSelectOption(){ 
      let newOption = {
        color: this.colors.textBlack, 
        value: '',
        id: Math.floor(Math.random()*1000000)
      } 
      if(this.selectOptions.length>0){
         if(this.selectOptions[this.selectOptions.length-1].value!=''){
           this.selectOptions.push(newOption)
         }
      }else{ 
        this.selectOptions.push(newOption)
      }
      
    }, 
    removeOption(id){
      this.selectOptions = this.selectOptions.filter(item=>{
        return item.id!=id
      })
    },
    removeAllSelectOptions(){
      this.selectOptions = []
    },
    selectType(option){  
      this.selectedType = option.value 
      if(option.value === 'Select' || option.value === 'Multi select'){
        this.showColumnOptions = false
      }
      if(option.value === 'Multi select'){
        this.isMultiSelect = true
      }  
      
    }, 
    validateEntries(){  
      if(!this.selectedType){ 
        errorNotification(ADD_COLUMN_VALIDATIONS.typeValidation) 
        return false
      }
      else if(this.col_name.length<MINIMIM_COLUMN_NAME_LENGTH){ 
        errorNotification(ADD_COLUMN_VALIDATIONS.lengthValidation) 
        return false
      }
      if(this.selectedType.includes('Select') || this.selectedType.includes('Multi')){ 
        if(this.selectOptions.length<MINIMUM_OPTIONS){
          errorNotification(ADD_COLUMN_VALIDATIONS.optionValidation) 
          return false
        } 
      }
      return true
    },
    createField(){   
      if(this.validateEntries()){ 

        let type = this.selectedType.toLowerCase().replaceAll(" ", "_")
        let col_config = {
          tableViewUuid: this.tableViewUuid,
          name: this.col_name,
          description: this.col_desc,
          defaultValue: this.col_default_value,  
          type: type
        }   

        if(col_config.type === 'multi_select'){
          if(this.col_default_value === ''){
            col_config.defaultValue = "[]"
          }else{
            col_config.defaultValue = this.col_default_value.split(",")
          }
        }

        // Validate that there should be atleast one option
        if(this.selectOptions.length!=0){
          col_config.option = this.selectOptions
          //Validate last option is not null
          if(this.selectOptions[this.selectOptions.length-1].value==''){
            this.selectOptions.pop()
          }
        }  
        // Now create a column with col_config configuration
        createNewColumn(col_config, this.getCurrentUser)
          // Flush the defaults
          this.selectOptions = []
          this.showColumnOptions = true
          this.col_name = ''
          this.col_desc = ''
          this.col_default_value=''
          this.closeModal()
        } 
      }, 
      closeModal(){
        this.$store.dispatch('toggle_showAddColumn', false)
      }
    },
    computed: {
      ...mapGetters(['tableViewUuid', 'currentThemeObject', 'getCurrentUser'])
    },
    watch:{
      col_name(){
        if(this.col_name.length>=MINIMIM_COLUMN_NAME_LENGTH){
          this.isError = false
        }
      }
    }
}
</script>

<style scoped> 
.wrapper{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px; 
  padding: 40px 20px 20px 20px;
  background-color: white; 
  border-radius: 10px; 
} 
.cursor-pointer:hover{
  background-color: var(--borderLight);
}
.select-option{
  display: grid;
  grid-template-columns: 10% 80% 10%;
  margin-bottom: 5px;
  align-items: flex-end;
}
.select-option input{
  width: 100%;
}
.select-option input[type=text]{
  border-bottom: 0.1px solid var(--borderLight);
}    
.drop-option{
  cursor: pointer;
  padding: 5px;
}
.drop-option:hover{
  background-color: var(--borderLight);
}
.error-text{
  color: red;
}
.text--success{
  color: green;
  text-transform: uppercase;
  font-weight: bold;
}
.create-field-button{
  padding: 10px 20px;
  display: block;
  width: 100%;
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
  transition: all 0.2s ease; 
  border-radius: 5px;
}
.create-field-button:hover{  
  box-shadow: 2px 2px 10px rgba(0,0,0,0.9);
  transition: all 0.2s ease; 
}
.multi-select-options{
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.multi-select-options::-webkit-scrollbar {
  width: 8px;
}

.multi-select-options::-webkit-scrollbar-track {
  background: white;
}

.multi-select-options::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 2px solid white;
} 
.constraint-height{
  overflow-x: hidden;
  overflow-y: scroll;
  height: 200px; 
}
.widthFull{
  width: 100%;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid var(--borderDark);
} 
</style>