<template>
<div class="body"> 
   <div class="header">
     <input type="file" accept="" @change="handleFileChange($event)" v-if="!dataValue.value">
     <div v-else class="d-flex" > 
       <v-icon class="mr-3" color="black" small @click="deleteAttachment">mdi-delete</v-icon>
       <v-btn dark x-small @click="downloadFile">Download Attachment </v-btn>
       <!-- <a :href="dataValue.value" target="blank">Download Attachment</a> -->
     </div>
     
   </div>
  
</div>
</template>

<script> 
import { errorNotification } from '../../../composables/notification';
export default {
  props: ['dataValue', 'row_uuid', 'col_uuid'], 
  data() {
    return {
      attachment: '', 
    }
  },
  created(){
    console.log(this.dataValue)
  },
  watch:{
    dataValue(){
      console.log(this.dataValue)
    }
  },
  methods: { 
    handleFileChange(event){ 
      const selectedFile = event.target.files[0] 
      let formData = new FormData();
        formData.append('attachment', selectedFile)   
        this.$axios.put(`/rowValue/attachment/${this.dataValue.uuid}`, formData)
        .then(response=>{ 
          this.dataValue.value = response.data.value 
        })
        .catch(error=>{
          errorNotification(error.response.data) 
        }) 
    },
    deleteAttachment(){
      let data = {
        rowUuid: this.row_uuid,
        columnUuid: this.col_uuid,
        value: '',
        uuid: this.dataValue.uuid
      }
      this.$store.dispatch('updateCell', data)
    },
    downloadFile(){
      this.$store.dispatch('setScreenLoader', true)
      this.$axios.get(`/rowValue/download/${this.dataValue.uuid}`)
      .then(file=>{
        this.$store.dispatch('setScreenLoader', false)
        const myblob = new Blob([file.data], { type: 'text/csv' }); 
        const url = window.URL.createObjectURL(myblob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.dataValue.value.substring(this.dataValue.value.indexOf('/')+1)}`);
        link.click();
        // console.log(file)
      })
    }

  },
  watch: {
    dataValue() {
      this.attachment = this.dataValue.value
    }
  },
}
</script>

<style scoped>   
.image-header{
  border: 1px solid red;
} 
.image-attachment-cell{ 
  max-height: 70px;
  max-width: 40px;
}
</style>