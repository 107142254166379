<template>
<div class="template-modal-stretch">
  <div class="wrapper box-shadow-dark-" v-click-outside="exitModal">
    <div class="template-abs-top-right" @click="exitModal">
      <v-icon color="error">mdi-close-thick</v-icon>
    </div> 

    <div class="header my-5">
      <h1 class="text-center heading-3 mr-5">{{ getRevokeAccessTable.name }}</h1>
      <button
        :style="{backgroundColor: currentThemeObject.background, color: currentThemeObject.foreground}"
        class="share-button" 
        @click="openShareModal">
          SHARE
      </button> 
    </div>

    <p class="heading-7 text-center">
      Your table 
      <span :style="{color:currentThemeObject.background }" class="font-thick">{{ getRevokeAccessTable.name }}</span>
      is currently being shared with following users
    </p>
    
    <div class="table-user-container" >
        <div class="table-user-header" 
          :style="{backgroundColor: currentThemeObject.background,color: currentThemeObject.foreground}">
            Image 
        </div>
        <div class="table-user-header" 
          :style="{backgroundColor: currentThemeObject.background,color: currentThemeObject.foreground}">
            Name
        </div> 
        <div class="table-user-header header-email" 
          :style="{backgroundColor: currentThemeObject.background,color: currentThemeObject.foreground}">
            Email
        </div> 
        <div class="table-user-header" 
          :style="{backgroundColor: currentThemeObject.background,color: currentThemeObject.foreground}">
            Actions
        </div>
    </div>

    <div class="table-users" v-if="userList.length>0"> 
      <div class="" v-for="user in userList" :key="user.uuid">
        <div class="table-user-container"> 
          <div class="template-flex-col-center table-user">
              <UserIcon :userImage="user.imageUrl" :size="40" :userName="user.name"/>  
          </div>
          <div class="template-flex-col-center table-user">
            {{ user.name }}
          </div>
          <div class="template-flex-col-center table-user">
            {{ user.email }}
          </div> 
          <div class="template-flex-col-center table-user">
            <button 
              class="btn btn-revoke"
              v-if="user.deleted"  
              @click="toggleUser(user ,true)">
                RE-ACTIVATE 
            </button> 
            <button 
              class="btn btn-delete"
              v-if="!user.deleted && (getCurrentUser.email !=user.email)" 
                :style="{
                  color: currentThemeObject.foreground == '#fff' ? currentThemeObject.background : 'black', 
                  borderColor: currentThemeObject.foreground == '#fff' ? currentThemeObject.background : 'black'}"
                @click="toggleUser(user ,false)">
                 REVOKE 
            </button> 
          </div>  
        </div> 
      </div>
    </div>

    <div v-else class="text-center">
      <h1 class="dispay-1 my-5">No Users Found</h1>
      <v-btn 
        :style="{backgroundColor : currentThemeObject.background, color: currentThemeObject.foreground}"  
        @click="toggleShareTable">
          Add Users 
      </v-btn>
    </div> 

  </div>
</div>
</template>

<script> 
import {mapGetters} from 'vuex' 
import UserIcon from '../UI/UserIcon.vue'
import WorkspaceIcon from '../Workspaces/WorkspaceIcon.vue'
import {successNotification,errorNotification} from '../../composables/notification'
import { getWorkspaceUuid } from '../../composables/controllers'
import { issueNotification } from '../../composables/websocket'
export default {
  components: { WorkspaceIcon, UserIcon},
  data(){
    return {
      userList: []
    }
  },
  created(){ 
    this.fetchList()
  },
  methods:{ 
    exitModal(){
      this.$store.dispatch('toggleRemoveTableUserAccess')
    },
    openShareModal(){
      this.exitModal()
      this.$store.dispatch('switchTable', this.getRevokeAccessTable)
      this.$store.dispatch('setShareTheme', true)
    },
    fetchList(){
      this.$axios.get(`/tableView/user/${this.getRevokeAccessTable.uuid}`)
      .then(res=>{  
        this.userList = res.data
      })
    },
    toggleUser(user, value){ 
      let data = {
        email: user.email,
        workspaceUuid: getWorkspaceUuid(),
        access: value
      }
      this.$axios.put(`/tableView/${this.getRevokeAccessTable.uuid}`, data)
      .then(res=>{
        successNotification('User Access Revoked')
        this.fetchList() 
        issueNotification(data.email, null)
      })
      .catch(error=>{
        errorNotification(error.response.data.message)
      })
     },
     toggleShareTable(){
       this.exitModal()
       this.$store.dispatch('setShareTheme', true)
     }    
  },
  computed:{
    ...mapGetters(['getRevokeAccessTable', 'tableViewUuid' , 'currentThemeObject', 'getCurrentUser'])
  }
}
</script>

<style scoped> 
.wrapper{ 
  width: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 20px;
  background-color: white; 
  border-radius: 10px; 
} 
.table-user-container{
  display: grid;
  grid-template-columns: 8% 30% 40% 22%;
}
.table-user{
  border: 1px solid var(--borderLight); 
  padding: 5px;
}
.user-image{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.btn{
  padding: 10px 20px;
  width: 100%; 
  font-weight: bold;
  border-radius: 5px;
  letter-spacing: 2px;
}
.btn:hover{
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
}
.btn-revoke{ 
  background-color: var(--ramsColorDanger);
  color: white;
  transition: all 0.3s ease;
} 

.btn-delete{
  background-color: white;
  border: 1px solid transparent; 
} 
.header{
  display: flex;
  justify-content: center;
  align-items: center;
} 
.share-button{
  padding: 10px 40px;
  display: block; 
  color: white;
  font-weight: bold;
  letter-spacing: 2px; 
  border-radius: 5px;
} 
.share-button:hover{  
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.8);
  transition: all 0.6s ease; 
}
.share-button-container{
  position: absolute; 
  left: 50%; 
  transform: translateX(-50%);
  bottom: 10px;
}
.table-user-header{ 
  border: 1px solid white;
  color: white;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}
@media(max-width:768px){
  .table-user.table-user-email,
  .table-user-header.header-email{
    display: none;
  }
  .wrapper{
    width: 90%;
  }
  .table-user-container{ 
    grid-template-columns: 20% 40% 40%;
  }   
}
</style> 
