<template>
  <div class="body">
    <div class="content mt-5">
      <div class="intro-text">
        <div>
          <h1 class="home-heading-5 text-theme-primary">Our services</h1>
          <h6 class="home-heading-2 mt-5">Our company provide you with the excellent services. Get the best result and all benefits working with us.</h6>
        </div> 
      </div>
      <div class="feature-main-box">
        <div class="feature-box-primary">
          <div class="card card-secondary template-fix-col-center mb-10" id="animate-feature-box"> 
            <div class="template-fix-row-center">
              <v-icon x-large color="white"> mdi-cog-outline</v-icon>
              <h1 class="home-heading-3">
                SEO ready Code
              </h1>  
            </div>
            <p class="mt-5">Be ready to receive a project with SEO ready code and raise your website to the top on Google results</p>
          </div>
        </div>
        <div class="feature-box-secondary">
          <div class="card card-primary template-fix-col-center">  
            <div class="template-fix-row-center">
              <v-icon x-large color="white"> mdi-star-circle-outline</v-icon>
              <h1 class="home-heading-3">
                Awesome Features
              </h1>  
            </div>
            <p class="mt-5">All your projects will be full nesessary features and will differ by high perfomance</p>
          </div>
          <div class="card card-support template-fix-col-center mt-10">  
            <div class="template-fix-row-center">
              <v-icon x-large color="white"> mdi-trophy-award</v-icon>
              <h1 class="home-heading-3">
                5-Star-Support
              </h1>  
            </div>
            <p class="mt-5">In case you have a problem or questions to ask, use our friendly support team</p>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped> 
.body{
  height: 500px; 
  padding-top: 40px;
}
.content{
  display: grid;
  grid-template-columns: 30% 70%;
}
.intro-text{
  padding: 20px;
  margin-bottom: 20px;
}
.heading-2{
  color: var(--textColor);
} 
.card{
  width: 90%;
  height: 200px;
  color: white;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-size: 13px;
  padding: 10px;
  cursor: pointer;
}
.card:hover{
  box-shadow: 2px 2px 15px rgba(0,0,0,0.8);
  transition: all 0.5s ease;
}
.card.card-primary{
  background-color: var(--primary);
}
.card.card-secondary{
  background-color: var(--secondary);
}
.card.card-support{
  background-color: var(--colorGray);
}
.feature-main-box{
  display: grid;
  grid-template-columns: 50% 50%;
}
.feature-box-primary{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.feature-box-secondary{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
h6{
  font-size: 15px;
  color: var(--textColor);
  font-weight: 400;
}
#animate-feature-box{
  animation: boxScroll 7s linear 0.5s infinite;
}

@keyframes boxScroll {
  0% {
    transform: translateY(0px);
  }
  25%{
    transform: translateY(-100px);
  }
  50%{
    transform: translateY(0px);
  }
  75% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media(max-width:900px){
  .content{
    grid-template-columns: 100%;
  }
  .body{
    height: 650px;
  }
  #animate-feature-box{
    animation: none
  }
}

@media(max-width:600px){
  .feature-main-box{
    grid-template-columns: 100%;
  } 
  .body{
    height: 900px;
  }
}
</style>