<template>
  <div class="user-edit-modal">
    <div class="template-flex-row-between-top">
      <h1 class="heading-4 text-center mb-2">Edit User Details</h1>
      <div class="text-end close" :style="{ color: currentThemeObject.background }">
        <v-icon
          :style="{ color: currentThemeObject.background }"
          large
          @click="close"
        >
          mdi-close-circle
        </v-icon>
      </div>
    </div>
    <h4 class="mb-5">Change the details of the user.</h4>
    <div class="fields">
      <v-text-field
        label="Name"
        v-model="formData.name"
        :color="currentThemeObject.background"
        :error-messages="errors.name.errorMessage"
        @focus="removeError('name')"
      ></v-text-field>
      <v-text-field
        label="Email"
        v-model="formData.email"
        disabled
        :color="currentThemeObject.background"
        :error-messages="errors.email.errorMessage"
        @focus="removeError('email')"
      ></v-text-field>
      <v-select
        :items="formRoles"
        label="Role"
        v-model="formData.role"
        :color="currentThemeObject.background"
        :error-messages="errors.role.errorMessage"
        @focus="removeError('role')"
      ></v-select>
    </div>
    <div class="submit mt-2">
      <button
        class="submit-btn"
        @click="submitUserDetails"
        :style="{ backgroundColor:currentThemeObject.background, color: currentThemeObject.foreground}"
        :disabled="submittingForm"
      > 
        <span :class="{'margin-left': submittingForm}">SUBMIT</span>
      </button>
    </div> 
  </div>
</template>

<script> 
import {mapGetters, mapState} from "vuex";
import {successNotification, errorNotification} from '../../composables/notification'
import {getUserManagementWorkspace } from '../../composables/controllers' 
export default {
  props: ["user", "roles", 'userDetail'], 
  data() {
    return {
      formData: {},
      formRoles: [],
      selectedRole: null,
      submittingForm: false,
      errors: {
        name: {
          hasError: false,
          errorMessage: null,
        },
        email: {
          hasError: false,
          errorMessage: null,
        },
        role: {
          hasError: false,
          errorMessage: null,
        },
      },
    }
  },
  components: {
    Notification,
  },
  computed: {
    ...mapGetters(["currentThemeObject"]),
    ...mapState({baseURL: state => state.tableData.BASE_URL})
  },
  methods: {
    close() {
      this.$emit("closeUserEditModal");
    },
    setError(fieldName, errorMessage) {
      this.errors = {
        ...this.errors
      }

      this.errors[fieldName] = {
        hasError: true,
        errorMessage,
      }
    },
    removeError(fieldName) {
      this.errors = {
        ...this.errors
      }

      this.errors[fieldName] = {
        hasError: false,
        errorMessage: null,
      }
    },
    validateUserDetails() {
      if (!this.formData.name || !this.formData.email || !this.formData.role) {
        if (!this.formData.name) this.setError("name", "This field is required");
        else this.removeError("name");

        if (!this.formData.email) this.setError("email", "This field is required");
        else this.removeError("email");

        if (!this.formData.role) this.setError("role", "Please select a role");
        else this.removeError("role");

        return false;
      } else {
        this.removeError("name");
        this.removeError("email");
        this.removeError("role");

        return true;
      }
    },
    submitUserDetails() {
      if (this.validateUserDetails()) {
        this.submittingForm = true;

        const formData = {
          name: this.formData.name,
          email: this.formData.email,
          role: this.formData.role.substring(this.formData.role.indexOf(" "), -1),
          userUuid: this.userDetail.uuid,
          workspaceUuid: getUserManagementWorkspace()
        }  

        this.$axios.put(`/user/${this.userDetail.uuid}`, formData)
        .then(response => { 
          this.submittingForm = false;
          this.$emit("closeUserEditModal", "Changes saved successfully.");
          successNotification('Changes saved successfully.')
        })
        .catch(error => { 
          this.submittingForm = false;
          errorNotification(error.response.data.message)
        }); 
      }
    },
  },
  created() {
    const roleKeys = Object.keys(this.roles);
    this.formRoles = roleKeys.map(roleKey => (this.roles[roleKey]));

    this.formData = {
      name: this.user.name,
      email: this.user.email,
      role: this.user.role,
    }
  },
}
</script>

<style scoped>
.user-edit-modal {
  width: 500px;
  min-width: 400px;
  padding: 30px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 8px 1px grey;
} 

.close {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.close p {
  padding: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;
  color: white;
  font-weight: 700;
}

input {
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid var(--colorGray);
  outline-color:  var(--colorGray);
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 800;
  transition: all 0.4s ease;
  padding: 10px 20px;
  letter-spacing: 2px;
  width: 130px;
  border-radius: 4px;
}

.submit-btn:hover {
  opacity: 0.9;
}

.margin-left {
  margin-left: 10px;
}
</style>