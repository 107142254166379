import { render, staticRenderFns } from "./WorkspaceList.vue?vue&type=template&id=66ac082a&scoped=true&"
import script from "./WorkspaceList.vue?vue&type=script&lang=js&"
export * from "./WorkspaceList.vue?vue&type=script&lang=js&"
import style0 from "./WorkspaceList.vue?vue&type=style&index=0&id=66ac082a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ac082a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDataTable,VIcon})
