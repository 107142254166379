<template>
  <div class="user-edit-modal">
    <div class="body-header">
      <h1 class="heading-4 text-center mb-2">Create User</h1>
      <div class="text-end close" :style="{ color: currentThemeObject.background }">
        <v-icon
          :style="{ color: currentThemeObject.background }"
          large
          @click="close"
        >
          mdi-close-circle
        </v-icon>
      </div>
    </div>
    <h4 class="mb-5">Provide the details of the new user.</h4>
    <div class="fields"> 
      <v-text-field
        label="Name"
        v-model="formData.name"
        :color="currentThemeObject.background"
        :error-messages="errors.name.errorMessage"
        @focus="removeError('name')"
      ></v-text-field>
      <v-text-field
        label="Email"
        v-model="formData.email"
        :color="currentThemeObject.background"
        :error-messages="errors.email.errorMessage"
        @focus="removeError('email')"
      ></v-text-field>
      <v-select
        :items="formRoles"
        label="Role"
        v-model="formData.role"
        :color="currentThemeObject.background"
        :error-messages="errors.role.errorMessage"
        @focus="removeError('role')"
      ></v-select>
    </div>
    <div class="text-center">
      <button 
        @click="submitUserDetails" 
        :style="{ backgroundColor:currentThemeObject.background, color: currentThemeObject.foreground}"
        :disabled="submittingForm"
        class="submit-btn mt-4">
        SUBMIT
    </button>
    </div> 
  </div>
</template>

<script> 
import Loader from '../UI/Loader.vue' 
import {mapGetters} from "vuex";
import {errorNotification } from '@/composables/notification'
import {getUserManagementWorkspace } from '@/composables/controllers'
import { issueNotification } from '../../composables/websocket';
export default {
  props: ["roles"],
  components: { Loader },
  data() {
    return {
      formData: {},
      formRoles: [],
      submittingForm: false,
      errors: {
        name: {
          hasError: false,
          errorMessage: null,
        },
        email: {
          hasError: false,
          errorMessage: null,
        },
        role: {
          hasError: false,
          errorMessage: null,
        },
      },
    }
  }, 
  computed: {
    ...mapGetters(["currentThemeObject"]), 
  },
  methods: {
    close() {
      this.$emit("closeAddUserModal");
    },
    setError(fieldName, errorMessage) {
      this.errors = {
        ...this.errors
      } 
      this.errors[fieldName] = {
        hasError: true,
        errorMessage,
      }
    },
    removeError(fieldName) {
      this.errors = {
        ...this.errors
      } 
      this.errors[fieldName] = {
        hasError: false,
        errorMessage: null,
      }
    },
    validateUserDetails() {
      if (!this.formData.name || !this.formData.email || !this.formData.role) {
        if (!this.formData.name) this.setError("name", "This field is required");
        else this.removeError("name");

        if (!this.formData.email) this.setError("email", "This field is required");
        else this.removeError("email");

        if (!this.formData.role) this.setError("role", "Please select a role");
        else this.removeError("role");

        return false;
      } else {
        this.removeError("name");
        this.removeError("role");
        this.removeError("email");

        return true;
      } 
    },
    submitUserDetails() {
      if (this.validateUserDetails()) {
        this.submittingForm = true; 
        
        const formData = {
          name: this.formData.name,
          email: this.formData.email,
          role: this.formData.role.substring(this.formData.role.indexOf(" "), -1),
          workspaceUuid: getUserManagementWorkspace()
        } 
        this.$axios.post(`${this.$api.user.addUser}`, formData)
        .then(response => { 
          this.submittingForm = false;
          this.$emit("closeAddUserModal", "New user created successfully.");
          issueNotification(formData.email, null)
        })
        .catch((error) => {  
          this.submittingForm = false;
          errorNotification(error.response.data.message)
        });  
      }
    },
  },
  created() {
    const roleKeys = Object.keys(this.roles);
    this.formRoles = roleKeys.map(roleKey => (this.roles[roleKey]));

    this.formData = {
      name: "",
      email: "",
      role: "",
    }
  },
}
</script>

<style scoped>
.user-edit-modal {
  width: 500px;
  min-width: 400px;
  padding: 30px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 8px 1px grey;
}

.body-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.close {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.close p {
  padding: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;
  color: white;
  font-weight: 700;
}

input {
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid var(--borderDark);
  outline-color: var(--borderDark);
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 800;
  transition: all 0.4s ease;
  padding: 10px 20px;
  letter-spacing: 2px;
  width: 130px;
  border-radius: 4px;
  border: 1px solid blue;
}

.submit-btn:hover {
  opacity: 0.9;
}

.margin-left {
  margin-left: 10px;
}
</style>