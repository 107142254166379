<template>
  <div class="custom-drawer elevation-10">
    <v-card>
      <v-navigation-drawer
        permanent
        expand-on-hover
        :color="sideBarTheme"
        dark
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img :src="getUserProfilePicture"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ getCurrentUser.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ getCurrentUser.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
          nav 
        >
          <v-list-item link @click="redirectToHome">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Home</v-list-item-title>
          </v-list-item>
          
          <v-list-item link @click="redirectToProfile">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Profile</v-list-item-title>
          </v-list-item>

          <v-list-item link @click="showNotifications">
            <v-list-item-icon>
              <v-icon>mdi-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Notifications</v-list-item-title>
          </v-list-item>
          

          <v-list-item link v-if="getCurrentUser.role === 'ROLE_ADMIN'" @click="redirectToManagement">
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Manage</v-list-item-title>
          </v-list-item>  

          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Sign Out</v-list-item-title>
          </v-list-item> 
          
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { logout } from '../../composables/auth'
export default {
  data(){
    return{
      sideBarTheme: 'black'
    }
  },
  methods:{
    redirectToHome(){
      this.$router.push({ name: 'Home'})
    },
    redirectToProfile(){
      this.$router.push({ name: 'UpdateProfile'})
    },
    redirectToManagement(){
      this.$router.push({ name: 'AdminManagement'})
    },
    logout(){
      logout() 
      this.$router.push('/login')
    },
    showNotifications(){
      this.$store.dispatch('setNotificationCenter', true)
    }
  },
  watch:{
    currentThemeObject(){ 
      if(this.currentThemeObject.foreground === '#fff'){
        this.sideBarTheme = this.currentThemeObject.background
      }else{
        this.sideBarTheme = 'black'
      }
    }
  },
  computed:{
    ...mapGetters(['getCurrentUser', 'currentThemeObject', 'getUserProfilePicture'])
  }
}
</script>

<style scoped>
.custom-drawer{
  position: fixed;
  z-index: 100;
  top: 55%;
  transform: translate(0%, -55%);
  right: 0;
  background-color: black;
}
</style>