<template>
<div class="wrapper">
    <a :href="cellData" target="_blank" v-if="cellData.length>7">
      <v-icon :color="currentThemeObject.background" small>
        mdi-open-in-new
      </v-icon>
    </a> 
    <input
      type="text"
      class="body-wrap"
      v-model="cellData"
      :id="dataValue.uuid"
      @keydown="updateCell" 
    >
</div>

</template>

<script>
import { mapGetters } from 'vuex' 
export default {
  props: ['dataValue', 'row_uuid', 'col_uuid'],
  data() {
    return {
      cellData: '',
      timeout: this.$timeout.cell_update_timer
    }
  },
  watch: {
    dataValue() {
      this.cellData = this.dataValue.value
    }
  },
  methods: {
    update() {
      let data = {
        rowUuid: this.row_uuid,
        columnUuid: this.col_uuid,
        value: this.cellData.trim(),
        uuid: this.dataValue.uuid
      }
      this.$store.dispatch('updateCell', data)
    },
    updateCell() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.update()
      }, this.$timeout.cell_update_timer);
    }
  },
  computed:{
    ...mapGetters(['currentThemeObject'])
  }
}
</script>

<style scoped>
.wrapper{
    display: flex; 
    align-items: center;
} 
.body-wrap{
  width: 100%; 
  height: 100%;
  padding-left: 5px;
  margin-left: 2px;
  color: #1E88E5;
  font-weight: bold;
}
a{
  text-decoration: none;
}
</style>