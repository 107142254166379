<template>
  <div class="login-wrapper">
    <div class="content">
      <div class="section section-workspace template-flex-col-center" id="workspaceLogin">
        <div v-if="isCheckingWorkspace">
          <div class="fix-top-left" @click="redirectToHome">
            <v-icon large :color="colors.secondary">
              mdi-home
            </v-icon>
          </div>
          <div class="show-on-mobile">  
            <v-img src="@/Assets/images/features.svg"></v-img> 
          </div>
          <h1 class="font-3">Enter Workspace</h1>
          <p class="my-5">Continue to RAMS by providing the workspace name</p>
          <div class="workspace-input-container">
            <v-text-field
              label="Workspace" 
              class="workspace-input"
              v-model="workspaceName" 
              @keydown.enter="checkWorkspace"
            >
            </v-text-field> 
            <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
            <a :href="mail" class="need-help">Need Help?</a> 
            <v-btn 
              outlined 
              block 
              rounded 
              :color="colors.primary" 
              class="my-8" 
              @click="checkWorkspace">
                Check
            </v-btn>
          </div>  
          <Loader v-if="isFetchingWorkspace" />
        </div>
        <div v-else >
          <img src="https://ld-wp73.template-help.com/wordpress/prod_27482/v1/wp-content/uploads/2020/01/img-2.svg" alt="" class="img-fluid">
        </div>
      </div>
      <div class="section section-login text-center pb-8">
        <div v-if="!isCheckingWorkspace">
          <div class="fix-top-right" @click="isCheckingWorkspace=!isCheckingWorkspace">
            <v-icon :color="colors.primary">
              mdi-keyboard-backspace
            </v-icon>
          </div>
          <div class="fix-top-left" @click="redirectToHome">
            <v-icon large :color="colors.secondary">
              mdi-home
            </v-icon>
          </div>
          <h1 class="font-3">Sign In to <span class="workspace-name font-3">{{ workspaceName }}</span></h1>
          <div class="login-options">
            <div class="login-option mt-3">
              <a :href="redirectLink">
                <v-icon color="#777" class="icon-outline">
                  mdi-google-plus
                </v-icon>
              </a> 
            </div>
          </div>
          <div class="template-flex-col-center mt-lg-8">
            <input type="email" class="user-id user-input" placeholder="User email" v-model="formData.email">
            <input type="password" class="user-password user-input" placeholder="Password" v-model="formData.password">
          </div> 
          <p class="text-center serverError">{{ serverError }}</p>
          <div class="mt-5">
            <a href="/profile/forgot-password" class="forgot-password-link">Forgot Password?</a>
          </div> 
          <v-btn class="mt-5" :color="colors.primary" dark rounded @click="handleLogin">Login</v-btn>
          
        </div>
        <div v-else class="hide-on-mobile">  
          <img src="https://ld-wp73.template-help.com/wordpress/prod_27482/v1/wp-content/uploads/2020/01/img.svg" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/UI/Loader.vue'  
import { COOKIE_WORKSPACE } from '@/constants/cookieConstants' 
import { ramsHomepageTheme } from '@/constants/colorConstants'
import { setLSToken } from '@/composables/auth' 
import { getWorkspaceUuid, setWorkspaceUuid} from '@/composables/controllers'  
import { setUserManagementWorkspace } from '../../composables/controllers'
export default {
  components: { Loader },
  data(){
    return{ 
      formData: {},
      workspaceName: '',
      errorMessage: '',
      serverError: '',
      isCheckingWorkspace: true,
      isFetchingWorkspace: false,
      colors: ramsHomepageTheme,
      redirectLink: `${process.env.VUE_APP_BASE}/oauth2/authorize/google?redirect_uri=${process.env.VUE_APP_BACKEND_BASE}`,
      mail: `mailto:${process.env.VUE_APP_HELP_EMAIL}?subject=Help%20@%20RAMS`,
    }
  },  
  methods:{
    redirectToHome(){
      this.$router.push({name: 'LandingPage'})
    },
    checkWorkspace(){
      this.setIsFetchingWorkspace(true)
      this.errorMessage = ''
      this.$axios.get(`${this.$api.workspace.exists}`, {
        params:{
          name: `${this.workspaceName}`
        }
      })
      .then(response=>{
        this.updateStorage(response.data.uuid)
        setTimeout(()=>{ 
          this.setIsFetchingWorkspace(false)
          this.isCheckingWorkspace = false
        },this.$timeout.login_animation)
      })
      .catch(error=>{  
        this.errorMessage = error.response.data.message
        this.setIsFetchingWorkspace(false)
      }) 
    },
    updateStorage(workspaceUuid){
      setWorkspaceUuid(workspaceUuid) 
      this.$cookie.set(COOKIE_WORKSPACE, workspaceUuid, 1);
      setUserManagementWorkspace(workspaceUuid)   
    },
    setIsFetchingWorkspace(value){
      this.isFetchingWorkspace = value
    },
    handleLogin(){
      this.formData.workspaceUuid = getWorkspaceUuid()  
      this.$axios.post(`${this.$api.formLogin.checkLogin}`, this.formData)
      .then(response=>{ 
        setLSToken(process.env.VUE_APP_ACCESS_TOKEN, response.data.accessToken);   
        this.$axios.put(`${this.$api.user.lastLogin}`)
        .then(res=>{
          this.$store.dispatch('setCurrentUser', res.data);    
          this.$router.push({ name: 'Home'}) 
        }) 
        })
      .catch(error=>{ 
        this.serverError = error.response.data.message
      })
    }
  }
}
</script>

<style scoped>
*{ 
  font-family: 'Abhaya Libre', serif;
}
.login-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  
}
.content{
  position: relative;
  width: 800px;
  height: 500px; 
  padding: 0;
  display: flex; 
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  box-shadow: 2px 2px 10px rgba(0,0,0,0.8);
  background-color: white;
  border-radius: 10px; 
} 
.section-workspace{
  width: 300px;
  padding: 80px 20px 0 20px;  
  text-align: center;
  border-right: 1px solid var(--borderLight); 
}
.workspace-input-container{
  width: 100%;
}
.workspace-input{
  width: 100%;
  border: 0;
  border-bottom: 1px ;
}   
.section-login{
  padding: 80px 20px 0 20px;
  width: 500px;
} 
.user-input{
  margin-top: 20px;
  width: 250px;
  padding: 8px;
  background-color: var(--borderLight);
}
.forgot-password-link{
  text-decoration: none;
  color: var(--primary); 
}
.icon-outline{
  border: 1px solid #777;
  border-radius: 50%;
  padding: 5px;
  width: 35px;
  height: 35px;
  cursor: pointer;
} 
.img-fluid{
  width: 100%;
  height: 100%;
}
.fix-top-right{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.fix-top-left{
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
}
.error-message{
  color: var(--ramsColorDanger);
}
a{
  text-decoration: none;
  color: #777;
}
.need-help{
  color: var(--secondary);
  font-weight: bold;
} 
.font-3{
  font-size: 30px;
}
.show-on-mobile{
  display: none;
}
.workspace-name{
  color: var(--secondary);
}
.serverError{
  color: var(--ramsColorDanger);
}
 
@media (max-width:800px){  
  .login-wrapper{
    width: 100%;
  }
  .content{
    width: 100%;  
    max-width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .section-workspace{
    width: 100%; 
  }
  .hide-on-mobile{
    display: none;
  } 
  .img-fluid{ 
    max-height: 200px;
    width: auto; 
  }
  .font-3{
    font-size: 20px; 
    margin-top: -80px;
  }
  .show-on-mobile{
    display: block;
    margin-bottom: 90px;
  }
}
</style>