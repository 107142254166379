import Vue from 'vue'
import VueRouter from 'vue-router'

// Auth
import LoginPage from '../views/Auth/LoginPage.vue'
import LoginHandler from "../views/Auth/LoginHandler";
import SuperAdminLogin from '../views/Auth/SuperAdminLogin.vue'

// Profile
import ResetPassword from '../views/Profile/ResetPassword'
import UpdateProfile from '../views/Profile/UpdateProfile.vue'
import ChangePassword from '../views/Profile/ChangePassword.vue'

// Management
import AdminManagement from '../views/Manage/AdminManagement.vue' 
import SuperAdminDashboard from '../views/Manage/SuperAdminDashboard.vue' 

// Functional Pages
import Home from '../views/Home.vue'
import LandingPage from '../views/LandingPage.vue'

// Error Page
import PageNotFound from '../views/PageNotFound.vue'  
 
import {
  validateAdminsAccess,
  validateSuperAdminAccess 
} from '@/composables/routeGuards'

Vue.use(VueRouter);
 
const requireAuth = (to, from, next) => { 
  if (!window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN)) { 
    next({name: 'LoginPage'})
  } else {  
    next();
  }
} 

const superAdminAccess = (to, from, next) => { 
  validateSuperAdminAccess()
    .then(() => { next() })
    .catch(error => { next({ name: 'SuperAdminLogin' }) })
} 

const adminsRoute = (to, from, next) =>{ 
  validateAdminsAccess()
    .then(() => { next() })
    .catch(error => { next({ name: 'Home'}) })
}

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  }, 
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage
  },
  {
    path: "/login/admin",
    name: "SuperAdminLogin",
    component: SuperAdminLogin
  },
  {
    path: "/oauth2/redirect",
    name: "LoginHandler",
    component: LoginHandler,
    props: true,
  }, 
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: requireAuth,
  }, 
  {
    path: '/profile',
    name: 'UpdateProfile',
    component: UpdateProfile,
    beforeEnter :requireAuth
  }, 
  {
    path: '/admin/manage',
    name: 'AdminManagement',
    component: AdminManagement,
    beforeEnter: adminsRoute
  }, 
  {
    path: "/dashboard",
    name: "SuperAdminDashboard",
    component: SuperAdminDashboard,
    beforeEnter: superAdminAccess
  },
  {
    path: "/profile/forgot-password",
    name: "ResetPassword",
    component: ResetPassword
  },
  {
    path: "/profile/reset/:token",
    name: "ChangePassword",
    component: ChangePassword
  },
  {
    path: '/*',
    name: '/404',
    component: PageNotFound
  }
  
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
