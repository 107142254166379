<template>
<v-container fluid v-if="userHasTables" class="body">
  <div class="template-flex-col-center mx-2">
    <h1 class="heading-4 mb-1">{{ currentTableName }}</h1>
    <h6 class="heading-6">{{ currentTableDescription }}</h6>
  </div> 
</v-container>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {}
  }, 
  computed: {
    ...mapGetters(['currentTableName', 'currentTableDescription', 'userHasTables'])
  }
}
</script>

<style scoped>
.body { 
  margin-top: -10px;
}  

.table-name {
  font-weight: 500;
} 

.share-button{  
  color: white; 
  letter-spacing: 2px; 
  border-radius: 5px; 
}  

@media(max-width:768px){
  .flex{
    align-items: flex-start;
  }
}
</style>