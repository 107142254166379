<template>
  <div class="template-modal-stretch">
    <div class="content elevation 5" v-click-outside="close">
      <div class="header">
        <h1 class="heading-3" v-if="!tableDetails">Create new Table</h1>
        <h1 class="heading-3" v-else>Edit {{ tableDetails.name }}</h1>
        <v-icon color="error" @click="close">mdi-close-thick</v-icon> 
      </div>
      <p>Give a unique name to your table</p> 
      <div class="form">
        <v-text-field
          label="Name"
          v-model="formData.name"
        ></v-text-field>
        <v-text-field
          label="Description"
          v-model="formData.description"
          placeholder="A nice description for your table"
        ></v-text-field>
        <div class="d-flex justify-end"> 
          <v-btn @click="createNewTable" v-if="!tableDetails" color="success" outlined>Create</v-btn>
          <v-btn @click="editTable" v-else color="success" outlined >Save Changes</v-btn>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'  
import {getWorkspaceUuid} from '@/composables/controllers'
import {errorNotification,successNotification} from '@/composables/notification'
import {MINIMUM_TABLE_NAME_LENGTH} from '@/constants/objectConstants'
export default {
  props: ['tableDetails'],
  data(){
    return{
      formData: {}
    }
  },
  methods:{
    close(){
      this.$emit('exit')
    },
    initialiseData(){
      if(this.tableDetails){
        this.formData = this.tableDetails
      }
    },
    validateTableName(){
      if(this.formData.name.length>MINIMUM_TABLE_NAME_LENGTH){
        return true
      }
      errorNotification('Please Add Table Name')
      return false
    },
    createNewTable() { 
      if(this.validateTableName()){
        let data = {
          name: this.formData.name,
          description: this.formData.description, 
          workspaceUuid: getWorkspaceUuid()
        }
        this.$store.dispatch('addNewTable', data)
        this.$store.dispatch('getAllTables', this.userUuid)
        this.close()
        successNotification('New Table Added')
      }
    },
    editTable(){
      let tableDetails = {
        uuid: this.formData.uuid,
        name: this.formData.name,
        description: this.formData.description
      }
      this.$store.dispatch('updateTable', tableDetails)
      this.close()
    } 
  }, 
  computed: {
    ...mapGetters(['currentThemeObject'])
  },
  watch:{
    tableDetails(){
      this.initialiseData()
    }   
  },
  created(){ 
    this.initialiseData()
  }
}
</script>

<style scoped>
.content{
  position: fixed;
  width: 500px;
  z-index: 8000;
  background-color: white;
  padding: 20px 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>