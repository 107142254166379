<template>
  <v-app-bar
    app 
    color="white"
  >
    <div class="d-flex align-center">
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="@/Assets/images/homepage-logo.png"
        transition="scale-transition"
        width="150"
      /> 
    </div>

    <v-spacer></v-spacer> 
    <RouterLinks />
    <v-spacer></v-spacer>
    <router-link to="/login">
      <v-btn outlined class="sign-in-btn">Sign In</v-btn>
    </router-link>
  </v-app-bar>
</template>

<script>
import RouterLinks from './RouterLinks.vue'
export default{
  name: 'App',
  components: {RouterLinks},
  data(){
    return{

    }
  }
};
</script>

<style scoped>
.sign-in-btn{
  background-color: var(--secondary);
  transition: all 0.3s ease;
  color: white;
}
a{
  text-decoration: none;
}
.sign-in-btn:hover{
  background-color: var(--primary); 
  transition: all 0.3s ease;
}
</style>