
import {TIMEOUT} from '@/constants/timeoutConstants'
const state = {
    message : '',
    status : true,
    display : false
}
const actions = {
    notify({commit}, data){
        commit('SET_MESSAGE', data.msg)
        commit('SET_STATUS', data.status)
        commit('SET_DISPLAY', true)
        setTimeout(()=>{
            commit('SET_DISPLAY', false)
        },TIMEOUT.notification_timer)
    },
    hideNotification({commit}){
        commit('HIDE_NOTIFICATION')
    }
}
const mutations = {
    'SET_STATUS'(state, value){
        state.status = value
    },
    'SET_MESSAGE'(state, value){
        state.message = value
    },
    'SET_DISPLAY'(state, value){
        state.display = value
    },
    'HIDE_NOTIFICATION'(state){
        state.display = false
    }
}
const getters = {
    getMessage(state){
        return state.message
    },
    getStatus(state){
        return state.status
    },
    getDisplay(state){
        return state.display
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}