<template>
<div class="filters">
  <ImportCSV 
    v-if="showImportCSV"
    @exit="showImportCSV=false"
   />
  <div class="filter ml-5" @click="toggle_show_sidebar" v-if="userHasTables">
    <div class="grid" v-if="show_sidebar">
      <v-icon small color="green"> mdi-toggle-switch</v-icon>
      <span class="text">Table List</span>
    </div>
    <div class="grid" v-else>
      <v-icon small color="red"> mdi-toggle-switch-off</v-icon>
      <span class="text">Table List</span>
    </div>
  </div>

  <div class="filter" @click="toggle_show_fields" v-if="userHasTables && userHasColumns">
    <div class="grid" @click="setColumnToggle(true)">
      <v-icon small :style="{color: currentThemeObject.background}"> mdi-xml</v-icon>
      <span class="text">Hide Fields</span>
    </div>
    <div class="toggler" v-if="showColumnToggle">
      <ToggleColumn class="toggler-absolute"/>
    </div>
  </div>

  <div class="filter" @click="toggle_show_rearrangeColumn" v-if="userHasTables && userHasColumns">
    <div class="grid">
      <v-icon small :style="{color: currentThemeObject.background}"> mdi-sort-variant</v-icon>
      <span class="text">Organize</span>
    </div>
  </div> 

  <div class="filter" v-if="getCurrentUser.role!='ROLE_READER' && userHasTables" @click="openShareTable">
    <div class="grid">
      <v-icon small :style="{color: currentThemeObject.background}"> mdi-share-variant</v-icon>
      <span class="text">Share</span>
    </div>
  </div> 

  <div class="filter" @click="showImportCSV=true">
    <div class="grid">
      <v-icon small :style="{color: currentThemeObject.background}"> mdi-file-import-outline</v-icon>
      <span class="text">Import</span>
    </div>
  </div> 

  <div class="filter" v-if="userHasRows" @click="downloadCSV">
    <div class="grid">
      <v-icon small :style="{color: currentThemeObject.background}"> mdi-download</v-icon>
      <span class="text">Download</span>
    </div>
  </div> 

  <div class="filter searchbox" v-if="userHasRows || isSearching">
      <div class="grid">
          <v-icon small class="mr-1" :style="{color: currentThemeObject.background}">mdi-magnify</v-icon>
          <input type="text" class="searchFilter" v-model="search_result">
      </div>
  </div> 

</div>
</template>

<script> 
import {mapGetters} from 'vuex' 
import ToggleColumn from '../Utilities/ToggleColumn.vue'
import ImportCSV from '@/components/Modals/ImportCSV.vue'
import { getCurrentTable } from '../../composables/controllers'
export default {
  components: {
    ToggleColumn,
    ImportCSV
  },
  data() {
    return {
      search_result: '',
      timeout: this.$timeout.search_timer_delay,
      showImportCSV: false
    }
  },
  watch: { 
    search_result() { 
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$store.dispatch('findSearchResult', this.search_result)
        if(this.search_result === ''){
          this.$store.dispatch('setIsSearching', false)
        }else{
          this.$store.dispatch('setIsSearching', true)
        } 
      },this.$timeout.search_timer_delay); 
    }, 
  },
  methods: { 
    toggle_show_sidebar() {
      this.$store.dispatch('toggle_showSidebar')
    },
    toggle_show_fields() {
      this.$store.dispatch('toggle_showFields')
      this.$store.dispatch('getColumnTogglerData')
    },
    toggle_show_sortPanel() {
      this.$store.dispatch('openSortPanel')
    },
    toggle_show_rearrangeColumn(){
      this.$store.dispatch('toggleRearrangeColumn')
    },
    setColumnToggle(value){
      this.$store.dispatch('set_columnToggle', value)
    },
    openShareTable(){
      this.$store.dispatch('setShareTheme', true)
    },
    downloadCSV(){
      this.$store.dispatch('setScreenLoader', true)
      this.$axios.get(`/tableView/downloadCSV?uuid=${getCurrentTable()}`)
      .then(file=>{ 
        this.$store.dispatch('setScreenLoader', false)
        const myblob = new Blob([file.data], { type: 'text/csv' }); 
        const url = window.URL.createObjectURL(myblob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.currentTableName}.csv`);
        link.click();
      })
    }
  },
  computed: {
    ...mapGetters([
      'currentThemeObject', 
      'show_fields', 
      'show_sidebar', 
      'showSortPanel', 
      'userHasTables', 
      'userHasColumns',
      'userHasRows',
      'isSearching',
      'showColumnToggle',
      'getCurrentUser',
      'currentTableName'
    ])
  }
}
</script>

<style scoped>
.filters {
  padding: 5px 0;
  display: flex;
  justify-content: flex-start; 
}

.text {
  color: black;
  margin-left: 5px;
}

.grid {
  display: flex;
  align-items: center;
}

.filter {
  cursor: pointer;
  padding: 10px;
  position: relative;
}

.filter:hover {
  background-color: #f4f4f4;
  transition: all 0.5s ease;
}

.toggler-absolute {
  top: 40px;
  left: -10px;
  position: absolute;
}

.searchFilter {
  width: 200px;
  border: none;
  border: 1px solid var(--colorGray);
  padding-left: 5px;
} 

@media (max-width: 768px) {
  span.text {
    display: none;
  } 
  .filters {
    display: grid;
    grid-template-columns: 20% 20% 20% 40%;
    align-items: center; 
  }

  .filter {
    padding: 5px;
    display: flex;
    justify-content: center;
  }

  .searchbox {
    display: none;
  }

  .searchbox input {
    width: 250px;
  }
}
</style>