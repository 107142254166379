import axios from "axios";
import store from "../store/index";
import { getWorkspaceUuid, setUserUuid } from "./controllers";

const fetchRamsAccessToken = () => {
  return window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN);
};

const fetchCurrentUser = () => {
  if (!fetchRamsAccessToken()) {
    return Promise.reject("No access token set.");
  }
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.VUE_APP_BASE}/user?workspaceUuid=${getWorkspaceUuid()}`
      )
      .then((response) => {
        store.dispatch("setCurrentUser", response.data);
        setUserUuid(response.data.uuid);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const refetchSuperAdmin = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_BASE}/user/superAdmin`)
      .then((response) => {
        store.dispatch("setCurrentUser", response.data);
        resolve();
      })
      .catch((error) => {
        reject("Unauthorised");
      });
  });
};

const getUrlParameters = (name) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(window.location.href);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const setLSToken = (name, value) => {
  window.localStorage.setItem(name, value);
};

const logout = () => {
  window.localStorage.clear();
};

export {
  fetchRamsAccessToken,
  fetchCurrentUser,
  getUrlParameters,
  setLSToken,
  logout,
  refetchSuperAdmin,
};
