<template>
  <div class="workspace-body elevation-5 mt-5" > 
    <div class="template-flex-row-between">
      <h2 class="ma-5 heading-3 superAdminThemeText">Workspace Analysis</h2> 
      <div class="d-flex">
        <div class="searchbox mr-3">
          <v-icon class="icon-search" small>mdi-magnify</v-icon>
          <input 
            type="text" 
            class="customSearchBox elevation-3"
            placeholder="search workspace"
            v-model="userInput" 
          > 
        </div>
        <button  
          class="mr-5 themeButton"  
          @click="addWorkspace=true">
            Add Workspace
        </button> 
      </div>
    </div>

    <ChartWorkspace />

    <Manage 
      v-if="addWorkspace"
      @exit="addWorkspace=false"
      @refetch="updateWorkspaceList"
     />

    <v-data-table
      :headers="headers"
      :items="filteredList"
      :single-select="setSingleSelect"
      :items-per-page="5"
      item-key="name" 
      show-select
      v-model="selected"
    ></v-data-table>

    <div class="mt-5" v-if="selected.length!=0" >
      <WorkspaceDetails
        :workspace="selected[0]"
        @updateWorkspaces="updateWorkspaces"
       />
    </div>
  </div>
</template>

<script> 
import Manage from '@/components/Workspaces/Manage.vue'
import {WORKSPACE_HEADERS} from '@/constants/objectConstants'
import ChartWorkspace from '@/components/Analysis/ChartWorkspace.vue'
import WorkspaceDetails from '@/components/Workspaces/WorkspaceDetails.vue'
export default {
  props: ['series'],
  components: { WorkspaceDetails, Manage, ChartWorkspace },
  data(){
    return{
      headers: WORKSPACE_HEADERS,
      items: [],
      filteredList: [],
      selected: [],
      userInput: '',
      setSingleSelect: true,
      addWorkspace: false
    }
  },  
  created(){  
    this.fetchWorkspaces()
  },
  methods:{
    fetchWorkspaces(){
      this.$axios.get(`${this.$api.workspace.getWorkspaceList}`)
      .then(response=>{
        this.items = response.data    
        this.filteredList = this.items
      })
    },
    updateWorkspaceList(){
      this.fetchWorkspaces()
    },
    updateWorkspaces(){
      this.fetchWorkspaces()
    }, 
  },
  watch:{
    userInput(){
      this.filteredList = this.items.filter(workspace=>{
        return workspace.name.toLowerCase().startsWith(this.userInput.toLowerCase())
      }) 
    }
  }
}
</script>

<style scoped>
.themeButton{
  color: var(--superAdminTheme);
  border: 1px solid var(--superAdminTheme);
  background-color: transparent;
  padding: 8px 20px;
  border-radius: 5px;
}
.workspace-body{
  background-color: white;
}
.customSearchBox{
  padding: 7px 10px 7px 30px;
  width: 300px;
  border: 1px solid #eee; 
}
.searchbox{ 
  position: relative;
}
.customSearchBox:focus{
  outline: none;
}
.icon-search{
  position: absolute; 
  top: 10px;
  left: 10px;
}
</style>