<template>
  <div
    class="wrapper"
    :style="{borderColor : currentColor}">

    <span 
      :style="{color : currentColor}">
      {{ data }}
    </span> 

    <span>
      <v-icon
        :style="{color : currentColor}"
        x-small
        @click="close">
          mdi-close-thick
      </v-icon>
    </span>
    
  </div>
</template>

<script>
export default {
  props: ['data', 'color', 'colorOptions'],
  data() {
    return {
      currentColor: ''
    }
  },
  created() { 
    this.updateCell()
  },
  methods: {
    updateCell() {
      this.colorOptions.forEach(item => {
        if (item.value == this.data) {
          this.currentColor = item.color
        }
      })
    },
    close() {
      this.$emit('close', this.data)
    }
  },
  watch: {
    data() {
      this.updateCell()
    }
  }
}
</script>

<style scoped>
.wrapper {
  padding: 5px;
  border-radius: 30px;
  border: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
  margin: 0;
} 
</style>