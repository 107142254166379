import {successNotification} from '../notification'
import {getCurrentTable} from '../controllers'
import store from '../../store/index'
import axios from 'axios'

// Custom Messages
import {
  formatTableUpdateMessage,
  formatTableDeletedMessage
} from '@/constants/websocketMessageConstants'

const handleTableBroadcast = (msg) =>{   
  let message = msg.messageType 
  store.dispatch('getAllTables') 
  if(message.includes('DELETED')){  
    axios.get(`tableView/details/${msg.uuid}`)
    .then(response=>{ 
      if (msg.uuid === getCurrentTable()) { 
        successNotification(formatTableDeletedMessage(response.data.name))
      } 
    }) 
  }
  if(message.includes('UPDATED')){  
    if(msg.uuid === getCurrentTable()){   
      successNotification(formatTableUpdateMessage(response.data.name))
    } 
  }   
}

export {
  handleTableBroadcast
}