<template>
<div class="body truncate"> 
  <MultiSelectOptions 
    v-if="expandOptions" 
    :values="values" 
    @closeExpandOptions="expandOptions=false"
    @removeOption="remove" />
  <v-icon 
    small 
    @click="showAdd = !showAdd" 
    :style="{ color: currentThemeObject.background}">
      mdi-plus-box
  </v-icon>
  <v-icon 
    v-if="values.length>2"
    small  
    @click="expandOptions=true"
    class="mr-2"
    :style="{ color: currentThemeObject.background}">
      mdi-open-in-new
  </v-icon>
  
  <PillCell
    v-for="value in values"
    :key="value.value"
    :data="value"
    :color="currentColor"
    :colorOptions="listOptions"
    @close="remove"/> 

  <SelectionOptionModal 
    v-if="showAdd"
    :options="listOptions"
    @add="add"
    @exit="showAdd = false" />
</div>
</template>

<script> 
import {mapGetters} from 'vuex'
import PillCell from './PillCell'
import SelectionOptionModal from '../../Modals/SelectOptionModal.vue'
import MultiSelectOptions from '../../Modals/MultiSelectOptions.vue'
export default {
  props: ['dataValue', 'options', 'row_uuid', 'col_uuid'],
  components: {
    PillCell, 
    SelectionOptionModal, 
    MultiSelectOptions 
  },
  data() {
    return {
      values: [],
      showAdd: false,
      expandOptions: false,
      listOptions: [],
      currentColor: 'blue'
    }
  },
  created() {
    this.listOptions = JSON.parse(this.options) 
  },
  watch: {
    dataValue() {
      try{
        this.values = JSON.parse(this.dataValue.value)
      }catch(error){ } 
    },
    options() {
      this.listOptions = JSON.parse(this.options)
    }
  },
  updated() {
    if (this.values.length <= 0) {
      try{
        this.objects = this.dataValue.value.split(',')
      }catch(error){
        if(this.dataValue.value != null)
          this.objects.push(this.dataValue.value)
      }
    }
  },
  methods: {
    add(value) {
      if (!this.values.includes(value.value)) {
        this.values.push(value.value)
      }
      this.sendReq()
    },
    remove(value) {
      this.values = this.values.filter(item => {
        return item != value
      })
      this.sendReq()
    },
    closeAddPanel() {
      this.showAdd = false
    },
    sendReq() {
      let data_user_value = []
      this.values.forEach(item => {
        data_user_value.push(item)
      })
      let data = {
        rowUuid: this.row_uuid,
        columnUuid: this.col_uuid,
        value: JSON.stringify(data_user_value),
        uuid: this.dataValue.uuid
      }
      this.$store.dispatch('updateCell', data)
    }
  },
  computed:{
    ...mapGetters(['currentThemeObject'])
  }
}
</script>

<style scoped>

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.body {
  cursor: pointer;
  display: flex;
  overflow-x: scroll;
}

.panel {
  min-width: 150px;
  background-color: white;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  position: absolute;
  left: 2px;
}

.addPanel {
  position: absolute;
  margin-top: 20px;
}

span.pill {
  padding: 5px;
  border-radius: 50%;
  background-color: var(--borderDark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.option-button {
  text-align: center;
  padding: 5px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.option-button:hover {
  background-color: var(--borderDark);
}

::-webkit-scrollbar {
  width: 1px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background-color: #f3f3f3;
  border-radius: 40px;
  border: 1px solid white;
}
</style>