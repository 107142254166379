const formatTableUpdateMessage = (tableName) => {
  return `Table ${tableName} is updated`
}

const formatTableDeletedMessage = (tableName) => {
  return `Table ${tableName} is deleted`
}

const formatAddColumnMessage = (columnName) => {
  return `Column ${columnName} is added`
}

const formatUpdateColumnMessage = (columnName) => {
  return `Column ${columnName} is updated`
}

const formatDeleteColumnMessage = (columnName) => {
  return `Column ${columnName} was deleted`
}


const formatUpdateCellMessage = 'Updated Cell Value',
      formatAddRowMessage = 'A new row is added',
      formatDeleteRowMessage  = 'A row was deleted'   

export {
  formatTableUpdateMessage,
  formatTableDeletedMessage,
  formatUpdateCellMessage,
  formatAddRowMessage,
  formatDeleteRowMessage,
  formatAddColumnMessage,
  formatUpdateColumnMessage,
  formatDeleteColumnMessage
}