const TIMEOUT = {
  // Timer before share email keyword displays suggesstions
  share_table_suggestions : 500, 

  // Intentional Delay to wait for animations
  animation_delay         : 500,

  // Timer to wait before user stops updating row value
  cell_update_timer       : 1000,

  // Timer to prevent user from sorting column again
  sortable_timer          : 1000,

  // Timer before search api is called 
  search_timer_delay      : 500,

  // Timer before refetch users inside table  
  load_user_delay         : 500,

  // Timer to display duration of user notification
  notification_timer      : 3000,

  // Timer before re connecting websockets
  websocket_reset_timer   : 1000,
  
  // Timer before invalid user is redirected to login page
  login_failure_timer     : 3000,

  // Timer for login window animation
  login_animation: 1000,
  
  // Slider animation
  slider_animation: 1000,

  // Redirects waiting time,
  redirect_timeout: 2000,

  // Timeout before resent otp is enabled,
  enable_resend_otp_timeout: 5000,

  // Timeout before updating Super Admin Dashboard
  update_dashboard: 600000,

  // Timeout before saving textx in database
  cell_update_timeout: 1000
}

export {
  TIMEOUT
}