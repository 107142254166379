<template>
<div class="body text-center" v-if="getDisplay">

  <v-alert
    class="toast-success toast-msg"
    dark
    v-if="getStatus">
    {{ getMessage }}
    <v-icon color="white" small @click="close">
      mdi-close-thick
    </v-icon>
  </v-alert>

  <v-alert
    color="toast-failure toast-msg"
    dark
    v-else>
    {{ getMessage }}
    <v-icon color="white" small @click="close">
      mdi-close-thick
    </v-icon>
  </v-alert>
  
</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  methods: {
    close() {
      this.$store.dispatch('hideNotification', false)
    }
  },
  computed: {
    ...mapGetters(['getMessage', 'getStatus', 'getDisplay'])
  }
}
</script>

<style scoped>
.body {
  z-index: 11000;
  padding: 20px 30px;
  position: fixed;
  top: -5px;
  left: 10px; 
  letter-spacing: 2px;
  display: flex;
}

.toast-msg { 
  font-weight: bold;
  border-radius: 20px;
}

.toast-msg.toast-success {
  background-color: var(--notificationSuccess);  
}

.toast-msg.toast-failure {
  background-color: var(--notificationError); 
}

@media (max-width: 768px) {
  .body {
    width: 80%;
  }
}
</style>