<template>
  <div class="body-webpage">
    <v-container class="body-main-webpage">
    <Navbar />
    <div class="webpage-component"  id="home">
      <IndexWeb/>
    </div>
    <div class="webpage-component" id="features">
      <Features/>
    </div>
    <div class="webpage-component" id="working">
      <HowRamsWorks/>
    </div>
    <div class="webpage-component" id="services">
      <Services/>
    </div>
     <div class="webpage-component" id="testimonies">
      <Testimonies/>
    </div>
    <div class="webpage-component" id="footer">
      <Footer />
    </div>
  </v-container>
  </div> 
</template>

<script>
import Footer from '@/components/Homepage/components/Footer.vue'
import IndexWeb from '@/components/Homepage/components/IndexWeb.vue'
import Features from '@/components/Homepage/components/Features.vue'
import Services from '@/components/Homepage/components/Services.vue'
import Navbar from '@/components/Homepage/components/Navbar/Navbar.vue'
import Testimonies from '@/components/Homepage/components/Testimonies.vue'
import HowRamsWorks from '@/components/Homepage/components/HowRamsWorks.vue'
export default { 
  components: {
    Navbar,
    IndexWeb,
    HowRamsWorks,
    Features,
    Services,
    Testimonies,
    Footer
  }
}
</script>

<style scoped>  
.body-main-webpage{
  max-width: 1000px;
}   

::-webkit-scrollbar-thumb {
  background-color: #0EBFAA!important;
  border-radius: 40px!important;
  border: 3px solid #75D752!important;
  height: 20px;
}  

.theme-font{
  color: black;
  transition: all 0.5s ease;
}

.theme-font:hover{
  color: var(--primary);
  transition: all 0.3s ease;
} 

.image-fluid{
  width: 100%;
  height: 100%;

  max-width: 450px;
  max-height: 450px;
} 
 
@media(max-width:900px){
  .home-heading-5{
    font-size: 30px; 
    line-height: 1.8rem;
  }
}  

</style>