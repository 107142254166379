<template>
<div class="template-modal-stretch">
  <div class="wrapper p-relative pad-30 box-shadow-dark-3" v-click-outside="exitModal">
      
    <div v-if="isFetching" class="text-center">
      <h1 class="heading-6 mb-5">Loading Columns</h1> 
         <Loader />
    </div>
    <div v-else>
      <h1 class="heading-6 text-center mb-5">Re Arrange Columns</h1> 
        <draggable v-model="columns" ghost-class="ghost" @end="onEnd">
          <transition-group type="transition" name="flip-list">
            <div class="sortable" v-for="element in columns" :key="element.uuid" :id="element.uuid">
              <v-icon class="mr-1" small>
                  mdi-drag-variant
              </v-icon>
              <p class="heading-6">
                {{ element.name }}
              </p> 
            </div>
          </transition-group>
        </draggable>
    </div>  

  </div>
</div>
</template>

<script> 
import {mapGetters} from 'vuex'
import draggable from 'vuedraggable'
import Loader from '../UI/Loader.vue' 
import {errorNotification} from '@/composables/notification'
import { getWorkspaceUuid } from '@/composables/controllers'
export default { 
  components: {
    draggable,
    Loader
  },
  data(){
    return{ 
      isFetching: true,
      oldIndex: '',
      newIndex: '',
      columns: []
    }
  },
  created(){
    this.fetchColumns()
  },
  methods:{
    exitModal(){
      this.$store.dispatch('toggleRearrangeColumn')
    },
    onEnd(evt){ 
      let selectedItem
      this.columns.forEach(item=>{
        if(item.uuid == evt.item.id) {
          selectedItem = item
        }
      }) 
      this.oldIndex = evt.oldIndex
      this.newIndex = evt.newIndex
      let data = {  
        toPosition: this.newIndex + 1,
        tableViewUuid: this.tableViewUuid
      } 
      this.isFetching = true
      this.$axios.put(`column/arrange/${selectedItem.uuid}`, data)
      .then(response=>{ 
        this.fetchColumns()
        this.$store.dispatch('refetchTable')
        .then(response=>{
          this.isFetching = false
        })       
      })
      .catch(error=>{ 
        errorNotification(error.response.data.message)
      })
    } ,
    fetchColumns(){
      this.$axios.get(`${this.$api.tableView.columns.fetchAllColumns}`,{
        params:{
          tableViewUuid: `${this.tableViewUuid}`,
          workspaceUuid: `${getWorkspaceUuid()}`
        }
      })
      .then(response=>{
        this.columns = response.data
        setTimeout(()=>{
          this.isFetching = false
        },this.$timeout.animation_delay)
      })
      .catch(error=>{
        errorNotification(error.response.data.message)
      })
    }
  },
  computed:{
    ...mapGetters(['tableViewUuid'])
  }   
}
</script>

<style scoped> 
.wrapper{ 
  top: 50px;  
  width: 500px;
  margin: auto; 
  background-color: white;   
  border-radius: 10px;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.sortable{
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: move;  
  border: 1px solid var(--borderDark);
} 
p{
  margin: 0;
}
</style>