<template>
<div class="body template-flex-col-center">
  <h1 class="display-4">Page Not Found</h1>
  <p class="mt-3">Something Went Wrong</p>
  <a href="/home">Go to home</a>
</div>
</template>

<script>
export default {}
</script>

<style scoped>
.body {
  width: 100vw;
  height: 100vh; 
}
</style>