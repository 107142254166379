import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// 3rd party libraries
import PortalVue from "portal-vue"; 
Vue.use(PortalVue);
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)
import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, { name: 'timeago', locale: undefined, })
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)  
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
import VueCookie from 'vue-cookie' 
Vue.use(VueCookie);  

import { API } from './api/api' 
Vue.prototype.$api = API
import constantPlugin from './plugins/constantPlugin'
import httpService from './plugins/httpService'
Vue.use(constantPlugin)
Vue.use(httpService)
new Vue({
  router,
  store,
  vuetify, 
  render: (h) => h(App),
}).$mount("#app");
