<template>
  <div class="management-body">
    <Navbar />
    <EditAdminWorkspace 
      v-if="showEditOption"
      @exit="showEditOption=false"
      @fetchWorkspace="fetchDetails"
      :workspace="workspace"
     />
    <div class="pa-5">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex">
          <UserIcon :userName="workspace.name" :size="75" :userImage="getNavbarImageUrl" />
          <h1 class="heading-3 text-center ma-3">{{workspace.name}} Dashboard</h1> 
        </div> 
        <v-icon large @click="showEditOption=true" :color="colorBG">mdi-pencil</v-icon>
      </div>  
    </div>  

    <div class="user-management-panel">
      <UserManagement />
    </div>
  </div>
</template>

<script> 
import { mapGetters } from 'vuex'
import { setCurrentTheme } from '@/composables/theme'  

import Navbar from '@/components/UI/Navbar.vue' 
import UserManagement from '@/views/Manage/UserManagement.vue'
import UserIcon from '@/components/UI/UserIcon.vue'
import EditAdminWorkspace from '@/components/Modals/EditAdminWorkspace.vue'
export default {
  components: {Navbar, UserManagement, UserIcon, EditAdminWorkspace},
  created(){
    setCurrentTheme()
    this.fetchDetails()
  },
  data(){
    return{
      workspace: '',  
      colorBG: '', 
      image: '',
      workspaceImage: '',
      showEditOption: false
    }
  },
  methods:{
    fetchDetails(){
      this.$axios.get(`${this.$api.workspace.getWorkspaceDetails}`)
      .then(response=>{  
        this.workspace = response.data 
        this.workspaceImage = response.data.image 
      }) 
    },
    
  },
  computed:{
    ...mapGetters(['currentThemeObject', 'getNavbarImageUrl'])
  },
  watch:{
    currentThemeObject(){
      this.colorBG = this.currentThemeObject.foreground === '#000' ? '#000' : this.currentThemeObject.background
    }
  }
}
</script>

<style scoped>
@import url('../../Assets/css/management-fonts.css');
.workspace-information{
  display: grid;
  grid-template-columns: 25% 70%;
  justify-content: space-between; 
} 
p{
  margin: 0;
} 
.pointer{
  cursor: pointer;
}
.workspace-contact{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  width: 300px;
} 
.workspace-basics{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} 
.userInput{
  position: relative;
}
.absolute-top-right-fix{
  position: absolute;
  top: 5px;
  right: 5px;
}
.workspace-information-panel{
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
  align-items: center;
} 
.box{
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}
.box.theme:hover{
  opacity: 0.9;
} 
.uploaded-image img{
  width: 100%;
  height: auto;
}
.image-section{
  position: relative;
}
.upload-file-tag{ 
  border: 1px solid red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 125px;
  width: 125px;
  border-radius: 50%;
  opacity: 1;
}
.box.theme:hover{
  opacity: 0.9;
} 
</style>