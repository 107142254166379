<template>
    <div id="user-icon" :style="iconSizeStyle" class="heading-6 font-bold">
        
        <img v-if="userImage" :src="userImage" :style="iconSizeStyle" alt="user-image"/>
        <span v-else>{{initials}}</span>
    </div>
</template>

<script>
export default {
    props: ["userName", "userImage", "size"],
    data() {
        return {
            initials: "",
            iconSizeStyle: null,
        }
    },
    methods: {
        setInitials() {
            if(this.userName){
                const lastNameIndex = this.userName.indexOf(" ") + 1;
                this.initials = `${this.userName[0]}${this.userName[lastNameIndex]}`.toUpperCase();
            }else{
                this.initials = 'AA'
            }
            
        }
    },
    created() {
        if(!this.userImage) this.setInitials();

        this.iconSizeStyle = {
            width: `${this.size}px`,
            height: `${this.size}px`,
        }
    }
}
</script>

<style scoped>
#user-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    color: white;
    background-color: yellowgreen; 
}  
</style>