<template>
  <div class="profile-image-container">
    
    <v-img 
      :src="image" 
      class="user-profile-image" 
    ></v-img>
    <div class="absolute-upload-button">
      <input 
        type="file" 
        @change="handleFileUpload($event)">
      <v-icon class="error pa-2" color="white">
        mdi-camera
      </v-icon>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { fetchCurrentUser } from '../../composables/auth';
import { errorNotification } from '../../composables/notification';
export default {
  props:['src'],
  data(){
    return{
      image: ''
    }
  },
  methods:{
    handleFileUpload(event){
      let formData = new FormData();  
      formData.append('image', event.target.files[0])   
      this.$axios.put(`/user/image/${this.getCurrentUser.uuid}`, formData )
      .then(response=>{ 
        this.$store.dispatch('setUserProfileImage', response.data.imageUrl)
        this.image = response.data.imageUrl
      })
      .catch(error=>{
        errorNotification(error.response)
      })
    }
  },
  created(){
    this.image = this.src
    fetchCurrentUser()
    .then(res=>{
      this.image = this.getCurrentUser.imageUrl
    })
  },
  computed: {
    ...mapGetters(['getCurrentUser'])
  }
}
</script>

<style scoped>
.profile-image-container{
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.user-profile-image{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.absolute-upload-button{
  position: absolute;
  right: 5px;
  bottom: 15px;
  border-radius: 50%; 
}

.absolute-upload-button i{
  border-radius: 50%; 
  cursor: pointer;
}
input{
  position: absolute;
  opacity: 0;
  width: 45px;
  height: 45px;
  z-index: 50;
  cursor: pointer; 
}
</style>