import {
  getCurrentTable,
  getUserManagementWorkspace,
  getUserUuid, 
  getWorkspaceUuid,
} from '@/composables/controllers'

const API = {

  contactUs: {
    fetchEnquiry: `/contactUs/enquiry`,                                 // GET   
    addEnquiry: `/contactUs`                                            // POST  
  },

  analytics: {
    chartDataTable: `/analytics/table?startRange=`,                     // GET   
    chartDataUser: `/analytics/user?startRange=`,                       // GET   
    chartDataWorkspace: `/analytics/workspace?startRange=`,             // GET  
    totalAnalytics: `/analytics/totalAnalytics`                         // GET  
  },

  health: `/actuator/health`,                                           // GET  
  
  workspace: {
    getWorkspaceList: `/workspace`,                                     // GET  
    getWorkspaceDetails: `/workspace/${getWorkspaceUuid()}`,            // GET,
    addWorkspace: `/workspace`,                                         // POST
    exists: `/workspace/exist`                                          // GET
  },

  tableView: {
    columns: {
      getColumnTypes: `/columnTypes`,                                   // GET   
      arrangeColumn: `/column/arrange`,                                 // PUT
      fetchAllColumns: `/column`,                                       // GET
      columnToggler: `/column/hidden?tableViewUuid=${getCurrentTable()}&workspaceUuid=${getWorkspaceUuid()}`, // GET
    },
    tables: {
      fetchAllTables: `/tableView?workspaceUuid=${getWorkspaceUuid()}`, // GET
      addNewTable: `/tableView`,                                        // POST
    },
    tableRoles: `/roles`
  },

  table: {
    sharedList: `/tableView/user/`,                                     // GET
    revokeUser: `/tableView/`,                                          // PUT
    searchUser: `/user/search`                                          // GET  
  },

  user: {
    profile: {
      resetPassword: `/user/resetPassword`,                             // PUT 
      forgotPassword: `/user/forgetPassword`,                           // POST
      updateProfile: `/user/profile/update`
    },
    addUser: `/user`,                                                   // POST
    formLogin: `/auth/login`,                                           // POST
    getCurrentUser: `${process.env.VUE_APP_BASE}/user?workspaceUuid=${getWorkspaceUuid()}`,  // GET,
    getSuperAdminDetails: `${process.env.VUE_APP_BASE}/user/superAdmin`,                     // GET
    validateSuperAdminDetails: `${process.env.VUE_APP_BASE}/user/details/${getUserUuid()}`,  // GET
    fetchUsers: `/user/workspace?workspaceUuid=${getUserManagementWorkspace()}`,             // GET,
    lastLogin: `/user/lastlogin?workspaceUuid=${getWorkspaceUuid()}`
  },

  theme: {
    fetchTheme: `/workspace/${getUserManagementWorkspace()}`, // GET
    updateTheme: `/workspace/${getUserManagementWorkspace()}` // PUT
  }, 

  formLogin: {
    checkLogin: `/auth/login`
  }
} 

export {
  API
}