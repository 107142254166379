<template>
<v-container fluid class="scroller pa-0 pt-5" id="table-container">

  <div v-if="showConfirmation">
    <Confirmation 
      :deletingComponent ="elementToDelete" 
      @confirm="updateDeletion"
      @exit="showConfirmation=false"  />
  </div>

  <div class="d-flex " >
    <div class="border-number h-custom" v-if="userHasRows"> </div>
      <TableHeaders/>
    </div> 

    <div v-for="row in row_data"
      :key="row.uuid"
      :id="row.uuid"
      class="grid"
    >  
    <div class="border-number cross-on-hover" 
      @click="toggleConfirm(row)"
      >
      <span>{{ row.priority }}</span> 
    </div>

    <div v-for="col in col_data" :key="col.uuid" :id="col.uuid" >
      <Cell 
        :row_uuid="row.uuid"
        :col_uuid="col.uuid"
        :col="col"
        :width="col.size"
        class="full-height" />
    </div> 

  </div> 

  <button
    @click="addNewRow"
    class="add-row-button mt-5" 
    v-if="userHasTables && userHasColumns && getCurrentUser.role!='ROLE_READER'" > 
    +
  </button> 
  
</v-container>

</template>

<script>
import {mapGetters} from 'vuex'
import TableHeaders from './TableHeaders.vue'

import Cell from './Cell.vue'
import Confirmation from '../Modals/Confirm.vue'    
export default {
  components: {
    TableHeaders, 
    Cell, 
    Confirmation
  },
  data() {
    return {
      showConfirmation: false,
      table_to_del: '', 
      elementToDelete: '',
      isFetchingData: false
    }
  }, 
  mounted(){
    setTimeout(()=>{
      if(this.userHasTables){
        this.$store.dispatch('refetchTable')
      } 
    },2000)  
    this.scroll()
  },
  methods: {
    addNewRow() {
      this.$store.dispatch('addNewRow')
    },
    deleteRow(rowID) { 
      this.$store.dispatch('deleteRow', rowID)
    },
    toggleConfirm(row) {
      this.showConfirmation = true
      this.table_to_del = row.uuid
      this.elementToDelete = `Row number ${row.priority}`
    },
    updateDeletion(val) {
      if (val) {
        this.deleteRow(this.table_to_del)
      }
      this.showConfirmation = false
    }, 
    setHoverText($event,value){
      $event.target.innerText=value
    },
    scroll () {
      window.onscroll = () => { 
        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight  
        if (bottomOfWindow && !this.isFetchingData && window.location.href.includes('/home')) {  
          this.$store.dispatch('increasePaginationNumber')  
          this.$store.dispatch('fetchNewData')
          setTimeout(()=>{
            this.isFetchingData = false
          }, 2000) 
        }
     	}
  	}
  },    
  computed: {
    ...mapGetters([
      'row_data',
      'col_data', 
      'userHasTables', 
      'userHasColumns', 
      'currentThemeObject',  
      'userHasRows',
      'getCurrentUser',
      'getPaginationNumber'
    ])
  }
}
</script>

<style scoped>


.grid {
  display: flex;
  height: 31px;
}

.bordered {
  border: 1px solid var(--borderDark);
}

.scroller {
  min-height: 70vh; 
  overflow-x: scroll;  
} 

.full-height {
  height: 100%;
} 

.add-row-button { 
  border: 1px solid var(--colorGray);
  background-color: white; 
  width: 50px;
  border-radius: none;
  height: 35px; 
} 

.border-number{
  width: 50px;
  min-width: 50px;
  text-align: center;
  border: 1px solid var(--borderDark);
  background-color: #f5f5f5;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.border-number:hover{
  background-color: var(--ramsColorDanger);
  color: white;
  transition: all 0.5s ease;
  font-weight: bold; 
}

.border-number.h-custom:hover{
  background-color: #f5f5f5;
  transition: none; 
}

.margin-left-custom{
  margin-left: 50px;
} 

.h-custom{
  height: 31px;
  font-weight: bold;
}  

.cross-on-hover:hover{
  content: 'X';
}
#table-container{
  min-height: 500px;
}
 
@media (max-width: 768px) {
  .scroller {
    min-height: 60vh;
  }
}
</style>