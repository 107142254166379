<template>
<div class="notification-body pa-5  mt-10"> 
  <div class="template-flex-row-between">
    <h1 class="heading-4 mb-4">Notifications</h1> 
  </div> 
  <div class=""
    v-for="notification in getUserNotifications" :key="notification.id">  
    <div class="single-notification elevation-1 mb-2 pa-2">
      <img :src="notification.triggerImageUrl" alt="" class="image-url">
      <div>
        <p>{{ notification.notification }}</p>
        <timeago :datetime="notification.triggerTime" :auto-update="60"></timeago>
      </div> 
    </div> 
  </div>
</div>
</template>

<script> 
import {mapGetters} from 'vuex'
export default { 
  computed:{
    ...mapGetters(['getUserNotifications'])
  }
}
</script>

<style scoped>
.notification-body{
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #eee;
}
.single-notification{
  display: flex;
  align-items: center; 
  width: 100%;
  padding: 10px 0; 
}
p{
  margin-bottom: 10px;
} 
.image-url{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 30px;
}
.custom-divider{
  width: 100%;
  height: 5px;
  border-top: 1px solid var(--borderDark);
}
</style>