<template>
<div class="body">
  <Confirmation 
    v-if="showConfirmation" 
    :deletingComponent="columnToDelete" 
    @exit="showConfirmation=false"
    @confirm="updateStatus" />

  <div class="wrap">
    <div class="head" 
      @click.right="toggleModal($event)"
      @click="sortColumn"  >
        <button :style="{width: col.size + 'px'}" id="columnHeader">
          {{ col.name }}
        </button>
    </div>

    <div v-if="showConfig" class="options" :style="{width: 300 + 'px'}" v-click-outside="hideModal">

      <div class="closeIcon" @click="showConfig=false">
        <v-icon color="error">
            mdi-close-thick
        </v-icon>
      </div> 

      <v-text-field
        label="Column Name" 
        v-model="colName" 
        class="pt-5"
        dense
      ></v-text-field>

      <v-text-field
        label="Column Description" 
        v-model="colDesc" 
        dense
      ></v-text-field> 

      <v-text-field
        label="Column Default Value" 
        v-model="colDefaultValue" 
        dense
      ></v-text-field>

      <div v-if="col.option != 'null'"> 
        <div class="contain-options">
          <OptionList class="options-list"
            v-for="option in options"
            :key="option.value"
            :data="option"
            @remove="removeOption"
            @updateColor="updateColor"
            @updateValue="updateOptionData" 
          />
        </div> 
        <button class="button-add-option" @click="addNewOption" 
        :style="cssStyles">
          ADD OPTION
        </button> 
    </div>
  
    <div class="actions">
      <v-btn
        small
        class="btn"
        :style="cssStyles"
        @click="updateHeader">
          SAVE
      </v-btn>
      <v-btn
        small
        class="btn"
        :style="cssStyles"
        @click="handleDeleteColumn">
          DELETE
      </v-btn>
      <v-btn
        small
        class="btn" 
        :style="cssStyles"
        @click="toggleHidden">
          HIDE
      </v-btn> 
    </div>

  </div>
</div> 
</div>
</template>

<script> 
import OptionList from './OptionList.vue'
import Confirmation from '@/components/Modals/Confirm.vue'

import {mapGetters} from 'vuex' 
import {colorDefaults} from '@/constants/colorConstants'
import {getWorkspaceUuid} from '@/composables/controllers'
import {successNotification} from '@/composables/notification'
export default {
  props: ['column'],
  components: {
    OptionList, 
    Confirmation
  },
  data() {
    return {
      col: '',
      colName: '',
      colDesc: '',
      colDefaultValue: '',
      counter: 0,
      colors: colorDefaults,
      showConfig: false,
      showConfirmation: false,
      options: [],
      removedOptions: [],
      cssStyles: {},
      columnToDelete: ''
    }
  },
  created() {
    this.col = this.column
    this.colName = this.column.name
    this.colDesc = this.column.description
    this.options = JSON.parse(this.column.option) 
    this.cssStyles = {
      backgroundColor : this.currentThemeObject.background,
      color: this.currentThemeObject.foreground
    }
  }, 
  methods: {
    toggleModal($event) {
      $event.preventDefault()
      this.showConfig = !this.showConfig 
    },
    hideModal(){ 
      this.showConfig = false
    },
    removeOption(option) {
      this.removedOptions.push(option.value) 
      this.options = this.options.filter(item => {
        return item.value != option.value
      })
    },
    updateColor(newColor) {
      this.options.forEach(item => {
        if (item.value == newColor.value) {
          item.color = newColor.color
        }
      })
    },
    updateOptionData(newVal) {
      this.options.forEach(item => {
        if (item.value == newVal.value) {
          item.displayValue = newVal.displayValue
          item.value = item.displayValue
        }
      })
    },
    addNewOption() {
      if(this.options.length==0){
        this.pushNewMessage() 
      }else{
        if(this.options[this.options.length-1].displayValue!=''){
          this.pushNewMessage()
        }
      } 
    },
    pushNewMessage(){
      this.options.push({
        color: this.colors.textBlack,
        value: '',
        displayValue: ''
      })
    },
    updateHeader() {
      let data = {
        uuid: this.col.uuid,
        name: this.colName,
        description: this.colDesc,
        userUuid: this.getCurrentUser.uuid,
        updateOptions: this.removedOptions,
        type: this.column.type,
        defaultValue: this.colDefaultValue
      } 

      // Filter unnecessary / empty elements
      if(this.options){
        this.options = this.options.filter(option=>{
          return option.value!=''
        })
      }
      
      if (this.options) {
        data.option = this.options
      } 
      this.$store.dispatch('updateColumnHead', data)
      this.removedOptions = []
    },
    handleDeleteColumn(){
      this.columnToDelete = `Column ${this.column.name}`
      this.showConfirmation = true
    },
    deleteColumn() {
      this.$store.dispatch('deleteColumn', this.col.uuid)
    },
    confirmation(value){
      if(value){
        this.deleteColumn()
      }
      this.showConfirmation = false
    },
    updateStatus(val) {
      if (val) {
        this.deleteColumn()
      }
      this.showConfirmation = false
    }, 
    closeModal() {
      this.showConfig = false
    },
    toggleHidden(){
      let data = {
        name: this.column.name,
        uuid: this.column.uuid,
        hidden: !this.column.hidden,
        userUuid: this.getCurrentUser.uuid,
        workspaceUuid: getWorkspaceUuid()
      }
      this.$axios.put(`/column/${data.uuid}`, data)
        .then(response=>{  
          this.$store.dispatch('refetchTable') 
      })   
    }, 
    sortColumn(){ 
      document.getElementById('columnHeader').disabled = true 
      setTimeout(()=>{
        document.getElementById('columnHeader').disabled = false 
      },this.$timeout.sortable_timer)
      let data = {
        uuid :this.column.uuid, 
        userUuid: this.getCurrentUser.uuid,
        workspaceUuid: getWorkspaceUuid()
      } 
      this.counter++  
      if(this.counter%2==0){ 
        data.sort = true
      }else{ 
        data.sort = false
      }   
      this.$store.dispatch('sort', data)
      successNotification(`Sorting Column by ${this.col.name}`)
    }, 
  },
  watch: {
    column() {
      this.col = this.column
      this.options = JSON.parse(this.column.option) 
    },
    currentThemeObject(){
      this.cssStyles = {
        backgroundColor : this.currentThemeObject.background,
        color: this.currentThemeObject.foreground
      }
    }
  },
  computed:{
    ...mapGetters(['currentThemeObject', 'getCurrentUser'])
  }
}
</script>

<style scoped>
button {
  padding: 5px 10px;
  text-transform: capitalize;
  letter-spacing: 0.8px;
  font-weight: 700;
}

.head {
  background-color: #f4f4f4;
  border: 1px solid var(--colorGray);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.options {
  position: relative;
  top: 10px;
  background-color: white;
  padding: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.7);
  z-index: 3002;
}

label {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

p, textarea {
  padding: 5px;
  margin: 0;
}

input {
  padding: 5px;
  border-bottom: 1px solid var(--colorGray);
  width: 100%;
}

textarea {
  padding: 5px;
}

.actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.actions button{
  width: 90px;
}

.btn {
  width: 28%;
  color: white;
  font-weight: bold;
}

.btn.danger {
  background-color: var(--ramsColorDanger);
}

.close-popup {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
  margin: 1px;
  background-color: black;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.closeIcon{
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.wrap {
  z-index: 2002;
}
.contain-options{
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 8px;
}
.button-add-option{
  width: 100%;
  display: block;
  border-radius: 5px;
  margin-top: 5px;
}
.options-list{
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>