<template>
  <div class="wrapper">
    <div class="content">
      <div class="template-fix-col-center"> 
        <Chips/>
        <h1 class="home-heading-5 text-theme-secondary">How RAMS Works</h1>
      </div>
      <div class="columns mt-5">
        <div class="column template-fix-col-center">
          <v-img src="@/Assets/images/feature-1.png"></v-img>
          <h1 class="home-heading-3">Planning</h1>
          <p class="bodyText">Firstly, we will plan all the necessary elements in your project.</p>
        </div>
        <div class="column template-fix-col-center">
          <v-img src="@/Assets/images/feature-2.png"></v-img>
          <h1 class="home-heading-3">Design</h1>
          <p class="bodyText">After that, our professional designers will make a special and fascinating design for your project.</p>
        </div>
        <div class="column template-fix-col-center">
          <v-img src="@/Assets/images/feature-3.png"></v-img>
          <h1 class="home-heading-3">Development</h1>
          <p class="bodyText">Then we will develop all your ideas and make the project live.</p>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import Chips from './UI/Chips.vue'
export default {
  components: {Chips}
}
</script>

<style scoped>  
.wrapper{
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
} 
.columns{
  display: grid;
  grid-template-columns: 32% 32% 32%;
}
.column{
  padding: 10px;
}
.bodyText{
  color: var(--textColor);
  font-size: 13px; 
  line-height: 1rem;
  text-align: center;
  margin-top: 10px;
}
.template-fix-col-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media(max-width:900px){
  .columns{
    display: grid;
    grid-template-columns: 100%;
  } 
  .wrapper{
    height: 1000px;
  }
}  
</style> 