<template>
  <div class="reset-password-body">
    <h1 class="heading-3">Reset Password</h1>
    <v-form class="w100">
      <v-text-field 
        v-model="password"
        label="Enter password"
        type="password">
      </v-text-field>
      <v-text-field 
        v-model="rePassword"
        label="Re-Enter password"
        type="password">
      </v-text-field>
      <v-btn dark block @click="handleReset">RESET</v-btn>
    </v-form>
  </div>
</template>

<script>  
import {errorNotification, successNotification} from '@/composables/notification'
export default { 
  data(){
    return{
      password: '',
      rePassword: ''
    }
  },
  methods:{
    handleReset(){
      if(this.password === this.rePassword && this.password.trim().length>7){
        let passwordObject = { 
          password: this.password
        }
        this.$axios.put(`${this.$api.user.profile.resetPassword}`, passwordObject)
        .then(res=>{
          successNotification('Password Reset Sucessful')
          setTimeout(()=>{
            this.$router.push({name: 'LoginPage'})
          },this.$timeout.redirect_timeout)
        })
        .catch(error=>{
          errorNotification(error.response.data)
        })
      }else{
        errorNotification('Invalid')
      }
    }
  }
}
</script>

<style scoped>
.reset-password-body{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.w100{
  padding: 50px;
  width: 100%;
}
</style>