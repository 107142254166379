<template>
<div class="grid">
  <input type="color"
    @change="updateColor"
    class="color"
    v-model="color"
  >

  <input type="text"
    :placeholder="col.value"
    v-model="text"
    @keydown="handleData"
  >
  <v-icon
    @click="remove"
    small
    color="error"
    class="cursor"
  >
    mdi-close-thick
  </v-icon>
  
</div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      col: '',
      color: '',
      text: ''
    }
  },
  created() {
    this.col = this.data
    this.color = this.data.color
  },
  watch: {
    data() {
      this.col = this.data
    }
  },
  methods: {
    updateColor() {
      this.col.color = this.color
      this.$emit('updateColor', this.col)
    },
    remove() {
      this.$emit('remove', this.col)
    },
    updateData() {
      this.col.displayValue = this.text
      this.$emit('updateValue', this.col)
    },
    handleData() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.updateData()
      }, 1100);
    }
  }
}
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}

.grid {
  display: grid;
  padding: 5px 0;
  grid-template-columns: 15% 75% 10%;
  align-items: center;
}

.grid:hover {
  background-color: #f4f4f4;
}

.color {
  width: 20px;
  height: 20px;
}

.cursor, input {
  cursor: pointer;
}

input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid black;
}
</style>