<template>
<div class="grid"> 
  <ColumnHead
    v-for="col in col_data"
    :key="col.uuid"
    :column="col"
    :style="{width: col.size + 'px'}"
  />  
  <v-btn
    small 
    class="ml-2"
    width="80px"  
    @click="dispatchOpenToggle"
    v-if="userHasTables && getCurrentUser.role!='ROLE_READER'" 
  > + 
  </v-btn> 
</div>
</template>

<script>
import {mapGetters} from 'vuex'  
import ColumnHead from './TableHead/ColumnHead.vue'

export default {
  components: {  
    ColumnHead 
  },
  data() {
    return {  
      showConfirmation: false,
      col_to_delete: '',
      elementToDelete: ''
    }
  },
  methods: {  
    dispatchOpenToggle(){
      this.$store.dispatch('toggle_showAddColumn', true)
    }
  },
  computed: {
    ...mapGetters([
      'col_data', 
      'currentThemeObject', 
      'getCurrentUser',
      'userHasTables'
    ])
  }
}
</script>

<style scoped>
.grid {
  display: flex;  
}

.border {
  border: 1px solid var(--borderDark); 
}

input {
  background-color: var(--borderLight);
  width: 100%;
  outline: none;
}

.header {
  display: grid;
  grid-template-columns: 90% 10%;
  background-color: var(--borderLight);
  outline: none;
}

.cursor {
  cursor: pointer;
} 
 
</style>