<template>
  <div class="notification-body">
    <div class="notification">
      <div class="user-image"> 
        <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.pixabay.com%2Fphoto%2F2015%2F03%2F04%2F22%2F35%2Fhead-659652_960_720.png&f=1&nofb=1" v-if="!notification.triggerImageUrl" alt="" class="image-user">
        <img :src="notification.triggerImageUrl" v-else alt="" class="image-user">
      </div>
      <div class="notification-content">
        <p>{{ notification.notification }}</p>
      </div> 
    </div>
  </div>
</template>

<script>
export default {
  props: ['notification']
}
</script>

<style scoped>
  .notification-body{
    width: 300px;
    padding: 5px 0;
  }
  .notification{
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 5px; 
  } 
  .notification-content{
    width: 230px; 
  }
  p{
    margin: 0;
    font-size: 12px;
  } 
  .user-image{
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img.image-user{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
</style>