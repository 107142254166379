<template>
  <div class="body-login">
    <div class="section text-center">
      <div>  
        <img src="https://ld-wp73.template-help.com/wordpress/prod_27482/v1/wp-content/uploads/2020/01/img.svg" alt="" class="img-fluid">
      </div>
      <div class="section-login"> 
        <h1 class="display-3 mb-5">Sign In</h1> 
        <div class="form mt-lg-8">
          <v-text-field
            label="Email" 
            v-model="formData.email"
            :rules="[() => !!formData.email || 'This field is required']"
          ></v-text-field> 
          <v-text-field
            label="Password"
            type="password"
            v-model="formData.password"
            :rules="[() => !!formData.password || 'This field is required']"
          ></v-text-field> 
        </div>   
        <p class="errorMessages">{{ errorMessages }}</p>
        <v-btn class="mt-5"  dark rounded @click="handleLogin" @keydown.enter="handleLogin">Login</v-btn>
      </div>
    </div>
  </div>
</template>

<script> 
import { setLSToken } from '@/composables/auth' 
import { setUserUuid } from '@/composables/controllers'
export default {
  data(){
    return{
      formData: {},
      errorMessages: '', 
    }
  },
  methods:{
    handleLogin(){  
      if(this.validateFormData()){
        this.$axios.post(`${this.$api.formLogin.checkLogin}`, this.formData)
        .then(response=>{    
          setLSToken(process.env.VUE_APP_ACCESS_TOKEN, response.data.accessToken);  
          setUserUuid(response.data.userDto.uuid)
          this.$router.push('/dashboard')   
        })
        .catch(error=>{ 
          this.errorMessages = error.response.data.message
        })
      }
    },
    validateFormData(){ 
      if(!this.formData.email){
        this.showError('Error! please enter email')  
        return false
      }
      if(!this.formData.password){
        this.showError('Error! please enter password')  
        return false
      }  
      return true
    },
    showError(message){
      this.errorMessages = message
      setTimeout(()=>{
        this.errorMessages = ''
      },2000)
    }
  } 
}
</script>

<style scoped>
.body-login{
  width: 90%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.section{
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%; 
}
.section-login{ 
  width: 100%;
}
.form{
  width: 70%;
  margin: auto;
}
.errorMessages{
  color: var(--ramsColorDanger);
}
</style>