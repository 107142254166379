import store from '../../store/index' 
import axios from 'axios'
const handleNotificationBroadcast = (message) => {
  if (store.getters.getCurrentUser.uuid, message.userUuid) {
    axios.get(`/notification/${message.userUuid}`)
      .then(response => {
        store.dispatch('setUserNotifications', response.data)
      })
  }   
} 

export{
  handleNotificationBroadcast
}