import axios from "axios" 
import { getWorkspaceUuid } from "../../composables/controllers"
import {errorNotification} from '../../composables/notification' 
import { 
  addRow, 
  deleteRow,  
  deleteColumn, 
  updateColumn,  
  updateRowValue 
} from '../../composables/websocket'

const state = {
  row_data : [],
  col_data : [],
  cell_data : [],   
  currentTableViewUuid: '',
  paginationNumber: 0,
}
const mutations = {
  'FETCH_ROW_DATA'(state, response){
    state.row_data = response 
  },
  'FETCH_COL_DATA'(state, response){
    state.col_data = response 
  },
  'FETCH_CELL_DATA'(state, response){
    state.cell_data = response 
  },
  'UPDATE_ROW'(state, new_row_data){
    state.row_data = new_row_data
  },
  'UPDATE_COL'(state, new_col_data){
    state.col_data = new_col_data
  },
  'UPDATE_CURRENT_TABLE'(state, uuid){ 
    state.currentTableViewUuid = uuid
  },
  'UPDATE_CELL_VALUE'(state, data){
    state.cell_data = data 
  },
  'PAGINATE_FORWARD'(state) {
    state.paginationNumber += 1
  },
  'APPEND_ROW'(state, value) { 
    state.row_data = state.row_data.concat(value) 
  },
  'APPEND_ROW_VALUES'(state, value) {
    state.cell_data = state.cell_data.concat(value)   
  },
  'SET_SORTED_CELL_DATA'(state, value) {
    state.cell_data = value
  }
}
const actions = {
  updateCurrentTable({ commit, dispatch }, uuid){ 
    commit('UPDATE_CURRENT_TABLE', uuid)
    dispatch('fetchRowData')
    dispatch('fetchColData')
    dispatch('fetchCellData')
  }, 

  refetchTable({commit, dispatch}){
    dispatch('fetchRowData')
    dispatch('fetchColData')
    dispatch('fetchCellData')
  },
  
  clearTable({ commit }) {
    commit('FETCH_ROW_DATA', [])
    commit('FETCH_COL_DATA', [])
    commit('FETCH_CELL_DATA', [])  
  },
  

  async fetchRowData({ commit }) { 
    axios.get(`/row?tableViewUuid=${state.currentTableViewUuid}&page=${state.paginationNumber}`)
    .then(response=>{  
      commit('FETCH_ROW_DATA', response.data) 
    })
    .catch(error=>{ errorNotification(error.response.data.message) })
  }, 

  async fetchColData({ commit, dispatch }){ 
    axios.get(`/column?tableViewUuid=${state.currentTableViewUuid}&workspaceUuid=${getWorkspaceUuid()}`)
    .then(response=>{
      commit('FETCH_COL_DATA', response.data)
      dispatch('getColumnTogglerData') 
    })
    .catch(error=>{ errorNotification(error.response.data.message) })
  },

  async fetchCellData({ commit }) { 
    axios.get(`/rowValue?tableViewUuid=${state.currentTableViewUuid}&page=${state.paginationNumber}`)
    .then(response=>{ 
      commit('FETCH_CELL_DATA', response.data) 
    })
    .catch(error=>{ errorNotification(error.response.data.message) }) 
  },
    
  async fetchNewData({ commit }) {
    axios.get(`/row/pagination?tableViewUuid=${state.currentTableViewUuid}&page=${state.paginationNumber}`)
      .then(response => { 
      commit('APPEND_ROW', response.data )
      axios.get(`/rowValue?tableViewUuid=${state.currentTableViewUuid}&page=${state.paginationNumber}`)
        .then(res => { 
        commit('APPEND_ROW_VALUES', res.data) 
      })
    })
  },

  async fetchSortedCellData({ commit }) {
    axios.get(`rowValue/sortvalue?tableViewUuid=${state.currentTableViewUuid}&page=${state.paginationNumber}`)
      .then(response => {
        commit('SET_SORTED_CELL_DATA', response.data)
      })
  },

  addNewColumn({ dispatch }, data) { },

  addNewRow({ dispatch }){  
    addRow()  
  },

  deleteRow({ commit, dispatch }, delete_row_id){ 
    deleteRow(delete_row_id)   
  },

  deleteColumn({ commit, dispatch }, delete_col_id){ 
    deleteColumn(delete_col_id)  
  },  

  updateCell({ dispatch }, data){ 
    updateRowValue(data) 
  },

  updateColumnHead({dispatch}, data){   
    updateColumn(data) 
  },

  sort({dispatch}, data){
    axios.put(`/rowValue/${data.uuid}`, data)
      .then(res => {
        dispatch('fetchRowData')
        dispatch('fetchSortedCellData')
      })
  }, 
    
  findSearchResult({ dispatch, commit }, data){
    axios.get(`/row/search?tableViewUuid=${state.currentTableViewUuid}&value=${data}&page=${state.paginationNumber}`)
    .then(response=>{    
      commit('UPDATE_ROW', response.data) 
      dispatch('fetchCellData') 
    }) 
  },

  increasePaginationNumber({ commit }) {
    commit ('PAGINATE_FORWARD')
  }

}
const getters = {
  row_data(state){
    return state.row_data
  },
  col_data(state){
    return state.col_data
  },
  cell_data(state){
    return state.cell_data
  },
  tableViewUuid(state){
    return state.currentTableViewUuid
  },
  userHasColumns(state){
    if(state.col_data.length>0){
      return true
    }
      return false
  },
  userHasRows(state){
    if(state.row_data.length==0){
      return false
    }
    return true 
  },
  getPaginationNumber(state) {
    return state.paginationNumber
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
