<template>
<div id="workspace-chart"> 
  <apexchart width="100%" :height="chartHeight" type="line" :options="options" :series="series"></apexchart>
</div>
</template>

<script> 
import{ mapGetters } from 'vuex' 
import {chartHeight, formatChartoptions} from '@/constants/chartConstants'  
export default { 
  data(){
     return { 
      options: formatChartoptions('vuechart-users',['#FF8A65', '#81C784'], []),
      series: [],  
      chartHeight: chartHeight
    }
  }, 
  mounted(){
    this.refetchData()
  },
  methods:{
    refetchData(){
      this.fetchUsersData()
      setTimeout(()=>{
        this.refetchData()
      },this.$timeout.update_dashboard)
    },
    fetchUsersData(){
      this.$axios.get(`${this.$api.analytics.chartDataUser}${this.getAnalysisRange}`)
      .then(response=>{   
        this.series = [
          {
            name: 'Users',
            data: response.data.users
          },
          {
            name: 'Active Users',
            data: response.data.activeUsers
          }
        ]
        this.options = formatChartoptions('vuechart-users',['#FF8A65', '#81C784'], response.data.dateList) 
      })
    }
  },  
  computed:{
    ...mapGetters(['getAnalysisRange'])
  }
}
</script>

<style scoped> 
</style>