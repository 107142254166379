<template>
<div class="body">
  <TextareaExpansion 
    v-if="showExpandedValue" 
    @closeExpandOptions="showExpandedValue=false"
    :value="cellData"
    :row="row_uuid"
    :col="col_uuid"
    :uuid="dataValue.uuid" 
   />
  <v-icon 
    :color="currentThemeObject.background" 
    small v-if="cellData.length>25"
    @click="showExpandedValue=true">
    mdi-script-text
  </v-icon>
  <input
    type="text"
    v-model="cellData" 
    :id="dataValue.uuid"
    @keydown="updateCell" 
  > 
</div>
  
</template>

<script>
import TextareaExpansion from '../../Modals/TextareaExpansion.vue'
import { mapGetters } from 'vuex' 
export default {
  props: ['dataValue', 'row_uuid', 'col_uuid'],
  components: {TextareaExpansion},
  data() {
    return {
      cellData: '',
      timeout: 1000,
      showExpandedValue: false
    }
  },
  watch: {
    dataValue() {
      this.cellData = this.dataValue.value
    }
  },
  methods: {
    update() {
      let data = {
        rowUuid: this.row_uuid,
        columnUuid: this.col_uuid,
        value: this.cellData.trim(),
        uuid: this.dataValue.uuid
      }
      this.$store.dispatch('updateCell', data)
    },
    updateCell() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.update()
      }, this.$timeout.cell_update_timer);
    }
  },
  computed:{
    ...mapGetters(['currentThemeObject'])
  }
}
</script>

<style scoped> 
input{
  width: 90%;
  margin-left: 10px;
}
input:focus{
  outline: none;
}
</style>