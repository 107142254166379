<template>
<div class="body" v-click-outside="closeModal">
  <div v-for="col in togglerData" :key="col.uuid">
    <div class="grid">
      <p>{{ col.name }}</p>
      <v-icon v-if="!col.hidden" color="green" class="icon" @click="update(col)">
        mdi-toggle-switch
      </v-icon>
      <v-icon v-else color="red" class="icon" @click="update(col)">
        mdi-toggle-switch-off
      </v-icon>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex' 
import {getWorkspaceUuid} from '@/composables/controllers' 
export default {
  data() {
    return {
      options: []
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'togglerData'])
  }, 
  methods: {
    fetchColumn(){
      this.$axios.get(`${this.$api.tableView.columns.columnToggler}`)
      .then(response => { 
        this.options = response.data
      })
    },
    update(val) { 
      let data = {
        name: val.name,
        uuid: val.uuid,
        hidden: !val.hidden,
        userUuid: this.getCurrentUser.uuid,
        workspaceUuid: getWorkspaceUuid()
      }
      
      this.$axios.put(`/column/${data.uuid}`, data)
        .then(response=>{   
          this.fetchColumn() 
          this.$store.dispatch('refetchTable')
      })  
      this.$store.dispatch('toggle_showFields') 

    },
    closeModal() {
      this.$store.dispatch('set_columnToggle', false)
    }
  },
  
}
</script>

<style scoped>
.body {
  width: 250px; 
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.8);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
  z-index: 9000;
}

p {
  margin: 0;
}

.grid {
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  padding: 5px;
}

.grid:hover {
  background-color: #f1f1f1;
}

.icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  box-shadow: none;
}

.icon:focus {
  outline: none;
  box-shadow: none;
}
</style>