const state = {
  pallette: [
    { background: '#FCE4EC', foreground: '#000'},
    { background: '#EDE7F6', foreground: '#000'},
    { background: '#FFF3E0', foreground: '#000'},
    { background: '#D7CCC8', foreground: '#000'},
    { background: '#fff', foreground: '#000'},
    
    { background: '#F48FB1', foreground: '#000'},
    { background: '#B39DDB', foreground: '#000'},
    { background: '#FFCC80', foreground: '#000'},
    { background: '#A1887F', foreground: '#000'},
    { background: '#CFD8DC', foreground: '#000'},
    
    { background: '#EC407A', foreground: '#fff'},
    { background: '#7E57C2', foreground: '#fff'},
    { background: '#FFA726', foreground: '#fff'},
    { background: '#8D6E63', foreground: '#fff'},
    { background: '#455A64', foreground: '#fff'},
    
    { background: '#C2185B', foreground: '#fff'},
    { background: '#4527A0', foreground: '#fff'},
    { background: '#E65100', foreground: '#fff'},
    { background: '#3E2723', foreground: '#fff'},
    { background: '#000', foreground: '#fff'},
    
    { background: '#EF9A9A', foreground: '#000' },
    { background: '#E1BEE7', foreground: '#000' },
    { background: '#BBDEFB', foreground: '#000' },
    { background: '#B2EBF2', foreground: '#000'},
    { background: '#C8E6C9', foreground: '#000'}, 

    { background: '#EF5350', foreground: '#000' },
    { background: '#EA80FC', foreground: '#000'},
    { background: '#42A5F5', foreground: '#000'},
    { background: '#4DD0E1', foreground: '#000'},
    { background: '#A5D6A7', foreground: '#000'},

    { background: '#D32F2F', foreground: '#fff' },
    { background: '#E040FB', foreground: '#fff'},
    { background: '#1F76D2', foreground: '#fff'},
    { background: '#24ACC1', foreground: '#fff'},
    { background: '#388E3C', foreground: '#fff'}, 

    { background: '#C62828', foreground: '#fff' }, 
    { background: '#D500F9', foreground: '#fff'}, 
    { background: '#1447A1', foreground: '#fff'}, 
    { background: '#19838F', foreground: '#fff'}, 
    { background: '#1B5E20', foreground: '#fff'},
  ],
  currentThemeObject: {background: '#0EBFAA', foreground: '#fff'}
}

const actions = {
  setTheme({commit}, color){  
    commit('SET_THEME', color)
  }
}

const mutations = {
  'SET_THEME'(state,color){
    state.currentThemeObject = color
  }
}

const getters = {
  getColorPallette(state){
    return state.pallette
  },
  currentThemeObject(state){
    return state.currentThemeObject
  }
}

export default{
  state,
  actions,
  mutations,
  getters
}