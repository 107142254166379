<template>
  <div class="template-modal-stretch">
    <div class="file-import pa-10 elevation-5" v-click-outside="exit">
      <div class="text-center">
        <h1 class="mb-5 heading-3">Import Data</h1>
        <div v-if="showLoader">
          <h1 class="mb-5">Yout data is now being uploaded</h1>
          <Loader />
        </div>
        <div v-else>
          <v-text-field
            v-if="addingNewTable"
            label="Table Name"
            v-model="tableName"
          ></v-text-field>
          <v-file-input
            accept=".csv"
            show-size
            v-model="files"
            label="Import CSV"
            @change="updateFileStatus"
          ></v-file-input>  
          <div v-if="fileDetails.name" class="text-left">
            <p><span class="bold">Uploaded:</span>  {{ fileDetails.name }}</p>
            <p><span class="bold">Size:</span> {{ fileDetails.size }}</p>
          </div>
          <div class="text-end">
            <v-btn color="success" outlined @click="saveCSV">SAVE</v-btn>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script> 
import { errorNotification, successNotification } from '@/composables/notification'
import { getCurrentTable, getUserUuid, getWorkspaceUuid } from '../../composables/controllers'
import Loader from '@/components/UI/Loader.vue'
export default {
  props: ['addingNewTable'],
  components: { Loader },
  data(){
    return{
      files: [],
      showLoader: false,
      fileDetails: {},
      BYTECONVERT: 1000000,
      tableName: ''
    }
  },
  methods:{
    exit(){
      this.$emit('exit')
    }, 
    saveCSV(){ 
      if(this.files.size/this.BYTECONVERT <= 5){
        let formData = new FormData();
        formData.append('file', this.files)   
        this.showLoader = true
        this.$axios.post(`tableView/upload-csv?workspaceUuid=${getWorkspaceUuid()}`, formData)
        .then(response=>{
          this.showLoader = false
          this.exit()
          successNotification('UploadedCSV')
          this.$store.dispatch('getAllTables')
          this.$store.dispatch('refetchTable') 
        })
        .catch(error=>{
          this.showLoader = false
          this.exit()
          errorNotification('Unable To Import Data')
        })
      }else{
        errorNotification('File Size must be less than 5 Mb')
      }
      
    },
    updateFileStatus(){
      if(this.files){
        this.fileDetails.name = this.files.name
        this.fileDetails.size = `${this.files.size/this.BYTECONVERT} Mb`
      }else{
        this.fileDetails = {}
      }
      
    }
  }
}
</script>

<style scoped> 
.file-import{
  position: fixed;
  width: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
}
.bold{
  font-weight: bold;
}
</style>