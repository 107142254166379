<template>
  <div class="body">
    <v-container class="content">
      <div class="content-section body-container">
        <Chips class="mb-8" />
        <h1 class="home-heading-5 ma-0 text-theme-primary">Start up your </h1>
        <h1 class="home-heading-5 ma-0 text-theme-primary">dream</h1>
        <p class="home-heading-2 my-5">Create your own business with our company. We will help you to make your dream true in several easy steps.</p>
        <div class="d-flex mt-10">
          <div class="theme-button btn-primary">Start Now</div>
          <div class="theme-button btn-secondary">Learn More</div>
        </div>
      </div>  
      <div class="content-section image-container">
        <img src="../../../Assets/images/index-web.svg" alt="img" class="image-fluid">
      </div>
    </v-container>
  </div>
</template>

<script>
import Chips from './UI/Chips.vue'
export default {
  components: {Chips}
}
</script>

<style scoped>
.body{
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}
.content{
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
.content-section{
  padding: 10px;
}
p{
  font-size: 15px;
  color: var(--textColor);
  font-weight: 400;
}

@media(max-width:900px){
  .content{
    grid-template-columns: 100%;
  }
  .content-section.image-container{
    order: 1;
  }
  .content-section.body-container{
    order: 2;
  }
  .content-section.image-container{ 
    display: flex;
    justify-content: center;
  }
  .body{
    height: 700px; 
  }
}
</style>