<template>
<div id="workspace-chart"> 
  <apexchart width="100%" :height="chartHeight" type="line" :options="options" :series="series"></apexchart>
</div>
</template>

<script> 
import {mapGetters} from 'vuex' 
import {chartHeight, formatChartoptions} from '@/constants/chartConstants' 
export default { 
  data(){
     return { 
      options: formatChartoptions('vuechart-tables',['#FFB74D'], []), 
      series: [], 
      chartHeight: chartHeight
    }
  }, 
  mounted(){
    this.refetchData()
  },
  methods:{
    refetchData(){
      this.fetchTableData()
      setTimeout(()=>{
        this.refetchData()
      },this.$timeout.update_dashboard)
    },
    fetchTableData(){
      this.$axios.get(`${this.$api.analytics.chartDataTable}${this.getAnalysisRange}`)
      .then(response=>{   
        this.series = [{
          name: 'Table',
          data:response.data.tables
        }] 
        this.options = formatChartoptions('vuechart-tables',['#FFB74D'], response.data.dateList) 
      })
    }
  }, 
  computed:{
    ...mapGetters(['getAnalysisRange'])
  }
}
</script>

<style scoped> 
</style>