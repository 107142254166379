<template>
<div class="template-modal-stretch">
  <div class="textarea-wrapper pad-20 box-shadow-dark-3" v-click-outside="closeModal"> 
      <h1 class="heading-3 ml-2">Edit </h1> 
      <textarea v-model="dataValue" cols="20">

      </textarea>
      <div class="text-end mt-3">
        <v-btn
          color="orange"
          outlined
          class="mr-5"
          @click="resetField"
        >
          CLEAR
        </v-btn>
        <v-btn 
          color="success"
          outlined
          @click="handleSave">
            SAVE
        </v-btn>
      </div>
  </div>
</div>
</template>

<script> 
import {mapGetters} from 'vuex'
export default { 
  props: ['value', 'row', 'col', 'uuid'], 
  data(){
    return{
      dataValue: ''
    }
  },
  created(){
    this.dataValue = this.value
  },
  methods:{
    closeModal(){
      this.$emit('closeExpandOptions')
    }, 
    resetField(){
      this.dataValue = ''
    },
    handleSave(){
      let data = {
        rowUuid: this.row,
        columnUuid: this.col,
        value: this.dataValue.trim(),
        uuid: this.uuid
      }
      this.$store.dispatch('updateCell', data)
    }
  },
  computed:{
    ...mapGetters(['currentThemeObject'])
  }
}
</script>
<style scoped> 
.textarea-wrapper{  
  position: fixed; 
  width: 500px;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  background-color: white; 
  border-radius: 10px; 
  min-height: 400px;
} 
textarea{
  width: 100%;
  height: 300px;
  white-space: pre-wrap;
  padding: 10px;
  resize: none;
  border:1px solid var(--borderMedium);
}
textarea:focus{
  outline-color: var(--borderDark);
}
</style>