const state = {
  currentUser: {},
  authenticated: false,
  hasRedirected: false,
  notifications: [],
  hasNewNotification: false,
};

const actions = {
  setCurrentUser({ commit, dispatch }, currentUser) {
    commit("SET_CURRENT_USER", currentUser);
    dispatch("setUserProfileImage", currentUser.imageUrl);
  },
  setAuthenticated({ commit }, authenticated) {
    commit("SET_AUTHENTICATION", authenticated);
  },
  setRedirected({ commit }, value) {
    commit("SET_REDIRECTED", value);
  },
  setUserNotifications({ commit }, notificationList) {
    commit("SET_USER_NOTIFICATIONS", notificationList);
  },
  setNewNotificationStatus({ commit }, notification) {},
};

const mutations = {
  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
  },
  SET_AUTHENTICATION(state, authenticated) {
    state.authenticated = authenticated;
  },
  SET_REDIRECTED(state, value) {
    state.hasRedirected = value;
  },
  SET_USER_NOTIFICATIONS(state, notificationList) {
    state.notifications = notificationList;
  },
};

const getters = {
  getCurrentUser(state) {
    return state.currentUser;
  },
  getAuthenticated(state) {
    return state.authenticated;
  },
  isSuperAdminRedirected(state) {
    return state.hasRedirected;
  },
  getUserNotifications(state) {
    return state.notifications;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
