<template>
  <div class="body">
    <v-container class="content">
      <div class="content-section image-container">
        <img src="@/Assets/images/features.svg" alt="img" class="image-fluid">
      </div>  
      <div class="content-section">
        <Chips class="mb-8" />
        <h1 class="home-heading-5 ma-0 text-theme-secondary">Few Reasons </h1>
        <h1 class="home-heading-5 ma-0 text-theme-secondary">why choose us</h1>
        <p class="features-body mt-5">We offer you stunning posibillities.</p>
        <div class="features">
          <div class="feature">
            <p class="feature-title">
              Workspace
            </p>
            <p class="feature-body">A great number of satisfied clients who want to come back</p>
          </div>
          <div class="feature">
            <p class="feature-title">
              Table management
            </p>
            <p class="feature-body">Our projects have full set of the latest technologies, with eye-catching design.</p>
          </div>
          <div class="feature">
            <p class="feature-title">
              Real Time Update
            </p>
            <p class="feature-body">Receive a report and deep analysis at the end of each week.</p>
          </div>
          <div class="feature">
            <p class="feature-title">
              Security
            </p>
            <p class="feature-body">Receive a report and deep analysis at the end of each week.</p>
          </div>
          
        </div> 
      </div>
    </v-container>
  </div>
</template>

<script>
import Chips from './UI/Chips.vue'
export default {
  components: {Chips}
}
</script>

<style scoped>
.body{
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}
.content{
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
.content-section{
  padding: 20px;
}
.features{
  display: grid;
  grid-template-columns: repeat(2,50%);
}
.features-body{
  font-size: 15px;
  color: var(--textColor);
  font-weight: 400;
}
.feature-body{
  font-size: 13px;
  color: var(--textColor);
  line-height: 1.2rem;
}
.feature{
  padding: 5px;
}
.feature-title{
  color: var(--primary);
  font-size: 20px;
}

@media(max-width:900px){
  .body{ 
    height: 950px;
  }
  .content{ 
    grid-template-columns: 100%; 
  }
  .features{
    display: flex;
    flex-direction: column;
  }
  .content-section{ 
    padding: 5px;
  }
  .feature{
    padding: 0;
  }
  .feature-title{
    color: var(--primary);
    font-size: 15px;
  }
  .feature-body{  
    line-height: 1rem;
    margin-top: 0;
  }
  .content-section.image-container{ 
    display: flex;
    justify-content: center;
  }
} 

</style>