<template>
  <div class="holder">
    <Navbar /> 
    <v-container fluid> 
      <div class="upper-actions">
        <v-btn text class="mb-2" @click="goBack"><span>&#8672;</span> Back</v-btn>
        <h2 class="heading-4">USER MANAGEMENT</h2>
        <v-btn text class="mb-2" @click="toggleUserCreationModal(true)">Add a New User</v-btn>
      </div>

      <ul class="users-list">
        <li class="users-list-headings border-bottom " 
        :style="{backgroundColor: currentThemeObject.background, color: currentThemeObject.foreground}">
          <div class="">Image</div>
          <div class="border-left ">NAME</div>
          <div class="border-left">EMAIL</div>
          <div class="border-left">ROLE</div>
          <div class="border-left">LAST LOGIN</div>
          <div class="border-left">ACTIONS</div>
        </li>
        <li v-if="fetchingUsers" class="fetching-users"> 
          <Loader class="loader-space" />
          <span>Loading Users...</span>
        </li>
        <div v-else>
          <h1 class="heading-5 text-center py-10" v-if="users.length<1">No Users Found</h1>
            <li v-else v-for="(user, index) in users" :key="user.uuid" class="border-bottom">
            <div class=""><user-icon :userImage="user.imageUrl" :size="40" :userName="user.name" /></div>
            <div class="border-left"><p>{{user.name}}</p></div>
            <div class="border-left"><p>{{user.email}}</p></div>
            <div class="border-left"><p>{{user.role}}</p></div> 
            <div class="border-left">
              <timeago :datetime="user.lastLogin" :auto-update="60" v-if="user.lastLogin"></timeago>
              <span v-else class="text-center">-</span>
            </div>
            <div class="user-actions border-left" v-if="!user.deleted">
              <button
                class="btn btn-edit"
                :disabled="getCurrentUser.uuid === user.uuid || user.role === 'ROLE_SUPER_ADMIN'" 
                :class="{disabled : getCurrentUser.uuid === user.uuid || user.role === 'ROLE_SUPER_ADMIN'}"
                @click="openUserEdit(index, user)"
                :style="{backgroundColor: currentThemeObject.background, color: currentThemeObject.foreground}"> 
                  EDIT 
              </button>

              <button
                class="btn btn-delete"
                :class="{disabled : getCurrentUser.uuid === user.uuid || user.role === 'ROLE_SUPER_ADMIN'}"
                :disabled="getCurrentUser.uuid === user.uuid || user.role === 'ROLE_SUPER_ADMIN'" 
                @click="toggleUserDeleteModal(true, index, user)"
                :style="{
                  color: currentThemeObject.foreground == '#fff' ? currentThemeObject.background : 'black', 
                  borderColor: currentThemeObject.foreground == '#fff' ? currentThemeObject.background : 'black'}"> 
                    ENABLED 
              </button>   
            </div>
            <div class="user-actions border-left" v-else>

              <button
                class="btn btn-edit disabled"
                disabled
                @click="openUserEdit(index, user)"
                :style="{backgroundColor: currentThemeObject.background}"> 
                  EDIT 
              </button>

              <button
                class="btn btn-delete inactive-user"
                :disabled="getCurrentUser.uuid === user.uuid" 
                @click="toggleUserDeleteModal(true, index, user)"
                :style="{
                  color: currentThemeObject.foreground == '#fff' ? currentThemeObject.background : 'black', 
                  borderColor: currentThemeObject.foreground == '#fff' ? currentThemeObject.background : 'black'}">
                   DISABLED 
              </button> 

            </div>
          </li>
        </div>
      </ul>

      <portal to="app-modals" v-if="showEditModal">
        <div class="overlay"></div>
        <div class="user-edit-modal-container">
          <user-edit :user="users[currentEditIndex]" :userDetail="editingUser" :roles="roles" @closeUserEditModal="closeUserEdit"/>
        </div>
      </portal>

      <portal to="app-modals" v-if="showDeleteModal">
        <div class="overlay"></div>
        <div class="user-edit-modal-container">
          <div class="user-delete-modal">
            <div class="confirmation-dialog">
              <h2>Are you sure you want to delete/reactivate the user?</h2>
            </div>
            <div class="delete-actions">
              <v-btn class="mr-3" 
                :style="{backgroundColor: currentThemeObject.background, color: currentThemeObject.foreground}"
                @click="deleteUser">  
                Confirm
              </v-btn>
              <v-btn color="error" @click="toggleUserDeleteModal(false, null, null)">Cancel</v-btn>
            </div>
          </div>
        </div>
      </portal>

      <portal to="app-modals" v-if="showCreationModal">
        <div class="overlay"></div>
        <div class="user-edit-modal-container">
          <add-user :roles="roles" @closeAddUserModal="($event) => toggleUserCreationModal(false, $event)"/>
        </div>
      </portal>
    </v-container> 
  </div>
</template>

<script> 
// Functions
import {mapGetters, mapState } from "vuex"; 
import {setCurrentTheme} from '@/composables/theme'
import {fetchCurrentUser } from "@/composables/auth"; 
import {successNotification, errorNotification} from '@/composables/notification'
// Components  
import AddUser from "@/components/UI/AddUser.vue";
import UserEdit from "@/components/UI/UserEdit.vue"; 
import UserIcon from "@/components/UI/UserIcon.vue"; 
import Navbar from '@/components/UI/Navbar.vue'
import Loader from '@/components/UI/Loader.vue'
import { issueNotification } from '@/composables/websocket';
export default { 
  data() {
    return {
      users: [], 
      roles: [],
      togglingUser: '',
      editingUser: '',
      currentEditIndex: null,
      currentDeleteIndex: null,
      fetchingUsers: false,
      deletingUser: false,
      showEditModal: false,
      showDeleteModal: false,
      showCreationModal: false,
    }
  },
  components: { 
    UserEdit, 
    AddUser,
    UserIcon,
    Navbar,
    Loader
  },
  methods: {
    goBack() { this.$router.go(-1); },
    openUserEdit(index, user) {
      this.currentEditIndex = index;
      this.showEditModal = true;
      this.editingUser = user
    },
    closeUserEdit(message = null) {
      this.currentEditIndex = null;
      this.showEditModal = false;

      if (message) {
        this.fetchUsers();
        successNotification(message) 
      }
    },
    toggleUserDeleteModal(shouldOpen, deleteIndex, user) {
      this.showDeleteModal = shouldOpen;
      this.currentDeleteIndex = deleteIndex;
      this.togglingUser = user
    },
    deleteUser() {   
      this.deletingUser = true;  
      // Now data is not needed here
      let data = {
        currentUserUuid: this.getCurrentUser.uuid 
      } 
      let msg
      if(this.togglingUser.deleted){
        msg = "Reactivated the user"
      }else{
        msg = "Deleted the user"
      } 
      this.$axios.delete(`/user/${this.togglingUser.uuid}?workspaceUuid=${this.togglingUser.workspaceUuid}`, {
        data: data
      })
      .then(res=>{
        this.deletingUser = false;
        this.toggleUserDeleteModal(false, null, null); 
        successNotification(msg)
        this.fetchUsers();
        issueNotification(null, this.togglingUser.uuid)
      })
      .catch(error=>{
        this.deletingUser = false;
        errorNotification(error.response)
      }) 
    }, 
    toggleUserCreationModal(shouldOpen, message = null) {
      this.showCreationModal = shouldOpen;

      if (message) {
        this.fetchUsers();
        successNotification(message) 
      }
    },
    fetchUsers() {
      this.fetchingUsers = true;   
      this.$axios.get(`${this.$api.user.fetchUsers}`)
      .then(response=>{  
        this.users = response.data;
        this.fetchingUsers = false;
        this.$emit("usersFetched", this.users)
      })
      .catch(error=>{
        errorNotification(error.response.data.message)
        this.fetchingUsers = false;
      })
    },
    
  },
  computed: {
    ...mapGetters(['showThemeChanger', "getCurrentUser", 'currentThemeObject']),
    ...mapState({baseURL: state => state.tableData.BASE_URL})
  },
  created() {
    fetchCurrentUser(); 
    this.fetchUsers(); 
    setCurrentTheme()
    this.$axios.get(`${this.$api.tableView.tableRoles}`)
    .then(response=>{  
      response.data.forEach((role)=>{
        this.roles.push(`${role.role} - ${role.value}`)
      })  
    })
  },
}
</script>

<style scoped>
.logo-image {
  width: 50px;
  height: 50px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.brand {
  display: flex;
  align-items: center;
}
 

@media (max-width: 1200px) {
  .mobile {
    display: none;
  }
}

.users-list {
  list-style: none;
  padding: 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0px 1px 8px 1px rgb(0, 0, 0, 0.15);
  text-align: center;
}

.users-list-headings {
  justify-content: center;
  text-transform: uppercase;
}

.users-list li:last-child {
  border-bottom: none;
}

.users-list li div:first-child {
  flex-basis: auto;
  width: 100px;
  justify-content: center;
}

.users-list li {
  display: flex;
}

.users-list li:hover {
  background-color: whitesmoke;
}

.users-list li div {
  flex-basis: 25%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  overflow-x: hidden;
  justify-content: center;
}

.users-list li div p {
  margin: 0px;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.user-actions{
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
}

.btn{
  padding: 8px 20px;
  width: 100%; 
  font-weight: bold;
  border-radius: 5px;
}

.btn.disabled{
  background-color: #ccc !important;
  border: none!important;
  color: black !important;
}
.btn.disabled:hover{
  box-shadow: none!important;
}

.btn:hover{
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.9);
}

.btn.btn-edit{
  color: white;
  margin-right: 10px;
}

.btn.btn-delete{
  border: 1px solid transparent;
}

.btn.inactive-user{
  color: crimson!important;
  border-color: crimson!important;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
}

.user-edit-modal-container {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fetching-users { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}  
.loader-space{ 
  width: 300px!important;
  padding-top: 50px!important;
}

.user-delete-modal {
  width: 500px;
  min-width: 400px;
  padding: 30px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 8px 1px grey;
  display: flex;
  flex-direction: column;
}

.confirmation-dialog {
  margin-bottom: 30px;
}

.delete-actions {
  display: flex;
  justify-content: flex-end;
}

.upper-actions {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.upper-actions h2 {
  font-weight: 500;
  letter-spacing: 2px;
} 

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
</style>