const setWorkspaceUuid = (id) =>{
    window.localStorage.setItem(process.env.VUE_APP_WORKSPACE, id) 
}

const getWorkspaceUuid = () =>{
    return window.localStorage.getItem(process.env.VUE_APP_WORKSPACE)
} 

const setCurrentTable = (id) =>{
    window.localStorage.setItem(process.env.VUE_APP_TABLEVIEW, id)
}

const getCurrentTable = () =>{
    return window.localStorage.getItem(process.env.VUE_APP_TABLEVIEW)
} 

const setUserManagementWorkspace = (id) =>{
    window.localStorage.setItem(process.env.VUE_APP_USER_MANAGEMENT_WORKSPACE, id)
}

const getUserManagementWorkspace = () =>{
    return window.localStorage.getItem(process.env.VUE_APP_USER_MANAGEMENT_WORKSPACE)
}

const setUserUuid = (uuid) =>{
    window.localStorage.setItem(process.env.VUE_APP_USER_ID, uuid)
}

const getUserUuid = () =>{
    return window.localStorage.getItem(process.env.VUE_APP_USER_ID)
}
 

export {
    setWorkspaceUuid,
    getWorkspaceUuid, 
    setCurrentTable,
    getCurrentTable,
    setUserManagementWorkspace,
    getUserManagementWorkspace,
    setUserUuid,
    getUserUuid
}