<template>
  <component
    class="pa-1 component"
    :is="type"
    :style="[dynamicWidth]"
    :dataValue="element"
    :options="col.option"
    :id="element.uuid"
    :row_uuid="row_uuid"
    :col_uuid="col_uuid"
  ></component>
</template>

<script>
import {mapGetters} from 'vuex'
// All Cell Types
import UrlCell from './Cells/UrlCell.vue'
import DateCell from './Cells/DateCell.vue'
import FlagCell from './Cells/FlagCell.vue'
import TextCell from './Cells/TextCell.vue'
import ImageCell from './Cells/ImageCell.vue'
import NumberCell from './Cells/NumberCell.vue'
import TextareaCell from './Cells/TextareaCell.vue'
import SingleSelect from './Cells/SingleSelectCell.vue'
import MultiSelectCell from './Cells/MultiSelectCell.vue'
import AttachmentCell from './Cells/AttachmentCell.vue'
export default {
  props: ['row_uuid', 'col_uuid', 'col', 'width'],
  components: {
    UrlCell, 
    DateCell, 
    TextCell, 
    FlagCell, 
    ImageCell,
    NumberCell, 
    TextareaCell,
    SingleSelect, 
    AttachmentCell,
    MultiSelectCell, 
  },
  data() {
    let colType 
    switch(this.col.type){
      case 'text': colType = 'TextCell'
        break;
      case 'flag':  colType = 'FlagCell'
        break;
      case 'date': colType = 'DateCell'
        break;
      case 'select': colType = 'SingleSelect'
        break;
      case 'url': colType = 'UrlCell'
        break;
      case 'number': colType = 'NumberCell'
        break;
      case 'image': colType = 'ImageCell'
        break;
      case 'textarea': colType = 'TextareaCell'
        break;
      case 'attachment': colType = 'AttachmentCell'
        break;
      default : colType = 'MultiSelectCell'
    }

    return {
      element: '',
      dynamicWidth: {},
      isTyping: false,
      type: colType
    }
  },
  methods: {
    updateData() {
      this.cell_data.forEach(item => {
        if ((item.columnId === this.col_uuid) && (item.rowId === this.row_uuid)) {
          let el = item
          this.element = el
        }
      }) 
    }
  },
  created() {
    this.updateData()
    this.dynamicWidth.width = `${this.width}px`;
  },
  watch: {
    cell_data() {
      this.updateData()
    }
  },
  computed: {
    ...mapGetters(['cell_data'])
  } 
}
</script>

<style scoped>
.component {
  border: 1px solid #bbb;
  border-right-color: var(--borderDark);
  border-left-color: var(--borderDark);
  border-top: none;
  margin: 0;
  cursor: pointer;
}
</style>