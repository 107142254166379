<template>
  <div class="super-admin-workspace-management">
    <div class="workspace-header">
      <h1 
        class="heading-4 text-center superAdminThemeText mb-5">{{ workspace.name }} 
      </h1>
      <div class="state-status inactive" v-if="workspace.deleted">INACTIVE</div>
      <div class="state-status active" v-else>ACTIVE</div>
      <div class="workspace-details pa-5">
        <div class="grid-5">
          <div class="d-flex justify-center">
            <v-avatar>
              <v-img :src="getCurrentUser.imageUrl"></v-img>
            </v-avatar> 
          </div>
          <v-text-field
            label="Domain"
            v-model="workspaceDetail.domain"
            class="constraint-width"
            :disabled="!isUpdating"
          ></v-text-field>
          <v-text-field
            label="Email"
            v-model="workspaceDetail.contactEmail"
            class="constraint-width"
            :disabled="!isUpdating"
          ></v-text-field>
          <v-text-field
            label="Name"
            v-model="workspaceDetail.contactName"
            class="constraint-width"
            :disabled="!isUpdating"
          ></v-text-field>
          <v-text-field
            label="Contact"
            v-model="workspaceDetail.contactNumber"
            class="constraint-width"
            :disabled="!isUpdating"
          ></v-text-field>
          <div v-if="isUpdating" class="template-flex-row-center">
            <v-icon 
              class="mt-4 mr-5 icon"
              @click="updateWorkspace"
            >
              mdi-content-save
            </v-icon>
            <v-icon 
              class="mt-4 icon" 
              @click="updateEditing(false)"
            >
              mdi-close-thick
            </v-icon>
          </div> 
          <div class="template-flex-row-center" v-else>
            <v-icon 
              class="mt-4 mr-5 icon"
              @click="updateEditing(true)"
            >
              mdi-pencil
            </v-icon>
            <v-icon 
              class="mt-4 icon"
              @click="toggleWorkspace"
            >
              mdi-delete
            </v-icon>
          </div>
          
        </div>  
      </div>
      <div class="user-list px-15">
        <UserList :workspaceUuid="workspaceDetail.uuid" />
      </div>
    </div>
  </div>
</template>

<script> 
import {mapGetters} from 'vuex'
import UserList from '@/components/Analysis/UserList.vue'
export default {
  props: ['workspace'],
  components: { UserList },
  data(){
    return{
      workspaceDetail: '',
      isUpdating: false
    }
  },
  created(){
    this.workspaceDetail = this.workspace
  },
  methods:{
    updateEditing(value){
      this.isUpdating = value
    },
    toggleWorkspace(){
      this.$axios.delete(`/workspace/${this.workspaceDetail.uuid}`)
      .then(response=>{
        this.workspaceDetail.deleted = !this.workspaceDetail.deleted
      })
    },
    updateWorkspace(){
      this.$axios.put(`/workspace/${this.workspaceDetail.uuid}`, this.workspaceDetail)
      .then(response=>{
        this.updateEditing(false)
      })
    }
  },
  watch:{
    workspace(){ 
      this.workspaceDetail = this.workspace
    }
  },
  computed:{
    ...mapGetters(['getCurrentUser'])
  }
}
</script>

<style scoped> 
.workspace-header{
  position: relative;
}
.grid-5{
  display: grid;
  grid-template-columns: 10% 10% 30% 20% 20% 10%;
  justify-content: space-between;
  align-items: flex-start;
} 
.constraint-width{
  width: 90%;
}
.update-button{
  padding: 10px 20px;
  border: 1px solid transparent;
  letter-spacing: 2px;
  border-radius: 5px;
}
.icon{
  cursor: pointer;
}
.state-status{
  position: absolute;
  top: -5px;
  right: 20px;
  padding: 10px 20px;
  letter-spacing: 1px;
  border-radius: 5px;
}
.state-status.active{
  border: 1px solid green;
  color: green;
}
.state-status.inactive{
  border: 1px solid red;
  color: red;
}
</style>