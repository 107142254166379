<template>
<div class="holder">
  <Navbar/> 
  <UserSidebar />
  <v-container fluid @click.right="prevent($event)">
    <AddColumn v-if="show_add_column"/>
    <AdminWorkspace v-if="show_admin_workspace" /> 
    <TableUsers v-if="showRevokeAccessTable" /> 
    <RearrangeColumns v-if="showRearrangeColumns" />
    <Header/>
    <ShareTable v-if="show_share_theme"/>
    <div class="outline-gray mx-5"></div>
      <Filters/>
    <div class="outline-gray mx-5"></div>
    <div class="main-body">
      <Sidebar v-if="show_sidebar"/>
      <TableContainer/>
    </div> 
  </v-container>
</div> 
</template>

<script>
//Navbar Components   
import Navbar from '@/components/UI/Navbar.vue'

// Table Components
import TableContainer from '@/components/TableView/TableContainer'

// UI Components
import Header from '@/components/UI/Header.vue'
import Filters from '@/components/UI/Filters.vue'
import Sidebar from '@/components/UI/Sidebar.vue'
import UserSidebar from '@/components/Modals/UserSidebar.vue'

//Modals / Popups / Utilities 
import AddColumn from '@/components/Modals/AddColumn.vue'
import TableUsers from '@/components/Modals/TableUsers.vue'  
import ShareTable from '@/components/Export/ShareTable.vue' 
import RearrangeColumns from '@/components/Modals/RearrangeColumn.vue' 

// Functions  
import { API } from '@/api/api'
import { mapGetters } from 'vuex'  
import { setCurrentTheme } from '@/composables/theme' 
import { COOKIE_USER_ID } from '@/constants/cookieConstants' 
import { getWebsocket, parseMessage, notificationWebSocket, parseNotification } from '@/composables/websocket'   
export default {
  components: {
    TableContainer,
    Header,
    Filters,
    Sidebar, 
    Navbar,
    ShareTable,
    AddColumn, 
    TableUsers,
    UserSidebar,
    RearrangeColumns
  }, 
  created() {  
    this.$axios.get(`${API.user.getCurrentUser}`)
      .then(response => {
        this.$store.dispatch('setCurrentUser', response.data); 
        this.$cookie.set(COOKIE_USER_ID, response.data.uuid, 1);
      }) 
    // Load All User Tables
    this.$store.dispatch('getAllTables')    
    setCurrentTheme()
  },
  data(){
    return{ 
      ws: '',
      notificationWebsocket: ''
    }
  },
  mounted(){  
    this.setupWebSocket()
    this.setupNotificationSocket() 
  }, 
  methods: {
    prevent($event) {
      $event.preventDefault()
    }, 
    setupNotificationSocket(){
      this.notificationWebsocket = notificationWebSocket()    
      this.notificationWebsocket.onmessage = (message) =>{ 
        parseNotification(message) 
      } 
      this.notificationWebsocket.onclose = () => {  
        setTimeout(()=>{
          this.setupNotificationSocket()
        }, this.$timeout.websocket_reset_timer);
      }; 
    },
    setupWebSocket(){
      this.ws = getWebsocket()   
      this.ws.onmessage = (message) =>{
        try{
          parseMessage(message)
        }catch(error){ }
      }
      this.ws.onclose = () => {  
        setTimeout(()=>{
          this.setupWebSocket()
        }, this.$timeout.websocket_reset_timer);
      }; 
    } 
  },  
  computed: {
    ...mapGetters([
      'show_sidebar', 
      'showThemeChanger',
      'userHasTables',
      'show_share_theme',
      'getCurrentUser', 
      'show_add_column',
      "show_admin_workspace",
      'showRevokeAccessTable',
      'showRearrangeColumns'
    ])
  }
}
</script>

<style scoped> 
@import '../Assets/css/fonts.css';
@import '../Assets/css/buttons.css';

.outline-gray {
  border-top: 1px solid var(--borderDark);
}

.main-body {
  display: flex;
  max-width: 95%;
}  

@media (max-width: 1200px) {
  .mobile {
    display: none;
  }
} 
</style>