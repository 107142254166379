import store from '../store/index'

let notification = {
    msg: '',
    status: ''
}

const successNotification = (message) =>{
    notification.msg = message
    notification.status = true 
    showAlert()
}

const errorNotification = (message) =>{
    notification.msg = message
    notification.status = false 
    showAlert()
}

const showAlert = () =>{ 
    store.dispatch('notify', notification)
}

export {
    successNotification,
    errorNotification
}