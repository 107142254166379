<template> 
<div class="body-settings">
  
  <div class="notificationPanel">
    <v-icon 
      dark 
      large 
      class="mr-5" 
      @click="showNotifications">
        mdi-bell-outline
    </v-icon> 
  </div>

  <img 
    :src="getCurrentUser.imageUrl" 
    class="profile-image" 
    @click="toggleDashboard" 
    :alt="getCurrentUser.name"> 
  
</div>
</template>

<script>
import {mapGetters} from 'vuex' 
import NotificationCenter from '@/components/Messages/NotificationCenter.vue'
export default { 
  components:{
    NotificationCenter
  }, 
  methods:{ 
    toggleDashboard(){
      this.$store.dispatch('toggleShowUserDashboard')
    }, 
    showNotifications(){
      this.$store.dispatch('setNotificationCenter', true)
    } 
  } ,
  computed: {
    ...mapGetters(['getCurrentUser']), 
  }
}
</script>

<style scoped> 

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.logged_user {
  text-align: center;
  padding-bottom: 3px;
  margin: 0;
}

.current_user_name {
  font-style: italic;
  color: black;
  font-weight: 700;
  text-align: center;
}

.logout-btn {
  width: 100%;
  padding: 5px;
  background-color: #f5f5f5;
}

.user-section {
  display: flex;
  align-items: flex-end;
}

.body-settings{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.notificationPanel{
  cursor: pointer;
  position: absolute;
  margin-right: 30px;
}
.notifications{
  background-color: white;
  position: relative;
  top: 90px;
  right: 90px;
  justify-content: flex-start;
  text-align: left;
}
</style>