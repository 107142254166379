<template>
<div class="body">
  <div class="home-heading-5 mb-5 pa-5 text-theme-secondary">
    What our customer says
  </div>
  <v-carousel v-model="model">
    <v-carousel-item
      v-for="(color, i) in colors"
      :key="color"
    >
      <v-sheet
        :color="color"
        height="100%"
        tile
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="user-profile pa-10">
            <h4 class="customer-testimony text-center mt-10">
              {{ testimonies[i].testimony }}
            </h4>
            <h2 class="mt-5 home-heading-4 text-center">
              {{ testimonies[i].author }}
            </h2>
            <p class="text-center">
              -- {{ testimonies[i].designation }}
            </p>
            <div class="mt-5 text-center">
              <img :src="testimonies[i].image" alt="" class="user-image">
            </div>
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel> 
</div> 
</template>

<script>
export default {
  data(){
    return{
      model: 0,
      colors: [
        '#E1BEE7',
        '#90CAF9',
        '#80CBC4',
        '#FFF59D'
      ],
      testimonies: [
        {
          author: 'John Doe',
          designation: 'Director at XYZ.com',
          image: 'https://randomuser.me/api/portraits/men/94.jpg',
          testimony: 'Hi, guys! I have used the effective services of this company and I am totally satisfied with my current project. It works rapidly and has a stylish design. The support team solves all my problems in a few minutes.'
        },
        {
          author: 'James Smith',
          designation: 'Director at ABC.com',
          image: 'https://randomuser.me/api/portraits/men/14.jpg',
          testimony: 'Your service is often a source of inspiration. My project became the most beneficial and successful among others. Your service simplifies my life and makes the usually complicated process easy.'
        },
        {
          author: 'Peter Rogers',
          designation: 'Director at ABC.com',
          image: 'https://randomuser.me/api/portraits/men/76.jpg',
          testimony: 'Your service is often a source of inspiration. My project became the most beneficial and successful among others. Your service simplifies my life and makes the usually complicated process easy.'
        },
        {
          author: 'Daniel James',
          designation: 'CEO at ABC.com',
          image: 'https://randomuser.me/api/portraits/men/69.jpg',
          testimony: 'Your service is often a source of inspiration. My project became the most beneficial and successful among others. Your service simplifies my life and makes the usually complicated process easy.'
        }
      ]
    }
  }
}
</script>

<style scoped>
.body{
  width: 100%;
  height: 500px;
  margin-bottom: 100px;
}
.user-image{
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.customer-testimony{
  line-height: 1.5rem;
  font-size: 15px;
  font-weight: 400; 
} 
h2{ 
  color: var(--textColor);
  font-weight: 400;
}
</style>