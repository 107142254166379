import { render, staticRenderFns } from "./HowRamsWorks.vue?vue&type=template&id=5a022ff8&scoped=true&"
import script from "./HowRamsWorks.vue?vue&type=script&lang=js&"
export * from "./HowRamsWorks.vue?vue&type=script&lang=js&"
import style0 from "./HowRamsWorks.vue?vue&type=style&index=0&id=5a022ff8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a022ff8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
