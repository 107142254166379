<template>
  <div class="chip-wrapper">
    <span class="chip1"></span>
    <span class="chip2"></span>
    <span class="chip3"></span>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.chip-wrapper{
  display: flex !important;  
  height: 10px!important;
  max-width: 200px!important;
}
.chip1{
  width: 18px;
  border-top: 2px solid var(--primary);
  display: inline-block;
  margin-right: 5px;
}
.chip2{
  width: 5px;
  border-top: 2px solid var(--secondary);
  display: inline-block;
  margin-right: 5px;
}
.chip3{
  width: 2px;
  border-top: 2px solid var(--primary);
}
</style>