<template>
<div class="body">
  <v-btn
    small
    block
    text
    @click="showOptions=!showOptions"
    :style="{ color : currentColor , borderColor : currentColor}"
    class="pill">
      {{ cellData }}
  </v-btn>
  <v-card v-if="showOptions" >
    <div
      class="option"
      v-for="option in list_options"
      :key="option.value"
      v-click-outside="toggleOptions">
      <v-btn
        @click="setData(option)"
        block
        text
        class="ma-0 option-btn"
        :style="{color: option.color}">
          {{ option.value }}
      </v-btn>
      
    </div>
  </v-card>
</div>
</template>

<script>
export default {
  props: ['dataValue', 'options', 'row_uuid', 'col_uuid'],
  data() {
    return {
      showOptions: false,
      list_options: [],
      colorOptions: {},
      cellData: '',
      currentColor: ''
    }
  },
  created() {
    if (!this.cellData) { 
      this.cellData = this.dataValue.value
    }
    if (typeof(this.options) === 'string') {
      this.list_options = JSON.parse(this.options)
    }
    this.list_options.forEach(item => {
      if (item.value === this.dataValue.value) {
        this.colorOptions.backgroundColor = item.color
        this.colorOptions.color = 'WHITE'
      }
    })
  },
  watch: {
    dataValue() { 
      this.cellData = this.dataValue.value
      let op = JSON.parse(this.options)
      try {
        op.forEach(item => {
          if (item.value == this.cellData) {
            this.currentColor = item.color
          }
        })
      } catch (err) { }
    },
    options() {
      if (typeof (this.options) == 'string') {
        this.list_options = JSON.parse(this.options)
      }
      this.list_options.forEach(item => {
        if (item.value == this.dataValue.value) {
          this.colorOptions.backgroundColor = item.color
          this.colorOptions.color = 'WHITE'
        }
      })
    }
  },
  updated() {
    if (!this.cellData) {
      this.cellData = this.dataValue.value
    }
  },
  methods: {
    update() {
      let data = {
        rowUuid: this.row_uuid,
        columnUuid: this.col_uuid,
        value: this.cellData,
        uuid: this.dataValue.uuid
      } 
      this.$store.dispatch('updateCell', data)
    },
    setData(value) { 
      this.cellData = value.value
      this.showOptions = false
      this.update()
    },
    closeModal() {
      this.showOptions = false
    },
    toggleOptions(){
      if(this.showOptions){
        this.showOptions = false
      }
    }
  }
}
</script>

<style scoped> 
.option-btn {
  z-index: 2000;
  background-color: white;
}

.pill {
  border-radius: 80px;
  border: 1px solid #f5f5f5;
  margin-top: -2px;
}
</style>