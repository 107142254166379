<template>
<div class="body">
  <input type="date"
    @change="update"
    v-model="currentData"
    min="1900-01-01"
    max="2100-01-01"> 
</div>
</template>

<script>
export default {
  props: ['dataValue', 'row_uuid', 'col_uuid'],
  data() {
    return {
      currentData: ''
    }
  },
  methods: {
    update() {
      let data = {
        rowUuid: this.row_uuid,
        columnUuid: this.col_uuid,
        value: this.currentData,
        uuid: this.dataValue.uuid
      }
      this.$store.dispatch('updateCell', data)
    }
  },
  watch: {
    dataValue() {
      this.currentData = this.dataValue.value
    }
  },
}
</script>

<style scoped>
* {
  margin: 0;
}

input {
  text-align: center; 
  height: 100%;  
  cursor: pointer;
}
.body{
  display: flex;
  justify-content: center;
}
</style>