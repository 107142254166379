<template>
<div class="template-modal-stretch box-shadow-dark-3">
  <div class="wrapper" v-click-outside="exit"> 
    <p class="text-center">Select an option</p>
    <v-btn 
      block
      v-for="option in options" 
      :key="option.value" 
      class="option"
      :style="{ color: option.color, borderColor: option.color }"
      @click="addOption(option)" >
        {{ option.value }}
    </v-btn>
  </div>
</div>
</template>

<script> 
export default {
  props:['options'],  
  data(){
    return{
      allOptions: [],
      remainingOptions: []
    }
  },
  methods:{
    addOption(option){
      this.$emit('add', option)
    },
    exit(){
      this.$emit('exit')
    }
  }
}
</script>

<style scoped> 
.wrapper{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 250px; 
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
}
.option{
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 10px;
}
</style>