import {successNotification} from '@/composables/notification' 
import { getCurrentTable } from '@/composables/controllers'
import store from '@/store/index'
import {
  formatAddRowMessage,
  formatDeleteRowMessage
} from '@/constants/websocketMessageConstants'

const handleRowBroadcast = (msg) =>{   
  if(msg.message.tableViewUuid === getCurrentTable()){
    if(msg.messageType.includes('ADDED')){   
      store.dispatch('fetchRowData') 
      store.dispatch('fetchCellData')
      successNotification(formatAddRowMessage)
    }
    if(msg.messageType.includes('DELETED')){ 
      store.dispatch('fetchRowData') 
      successNotification(formatDeleteRowMessage)
    }
  } 
}

export{
  handleRowBroadcast
}