<template> </template>

<script>
 
import {mapGetters} from 'vuex'   
import {errorNotification} from '@/composables/notification'
import {getUrlParameters, setLSToken} from '@/composables/auth'  
export default {
  name: "LoginHandler",
  created() { 
    const token = getUrlParameters('token');  
    if (token) {
      setLSToken(process.env.VUE_APP_ACCESS_TOKEN, token);  
      this.$axios.put(`${this.$api.user.lastLogin}`) 
      .then(res=>{ 
        this.$store.dispatch('setCurrentUser', res.data);    
        this.$router.push({ name: 'Home'})
      })
      .catch(err=>{
        errorNotification(err.response.data.message)
        setTimeout(()=>{
          this.$router.push('/login')
        },this.$timeout.login_failure_timer) 
      }) 
    } else {
      this.$router.push('/login');
    }
  },  
  computed: {
    ...mapGetters(['getCurrentUser', 'getAuthenticated'])
  },
  
}
</script>

<style scoped>
</style>
