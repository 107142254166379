<template>
  <div class="template-modal-stretch">
    <div class="image-body" v-click-outside="exit">
       <img :src="image" alt="" class="user-attachment">
       <v-icon x-large class="fix-top-right" color="error" @click="exit">mdi-close-circle</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props:['image'],
  methods:{
    exit(){
      this.$emit('exit')
    }
  }
}
</script>

<style scoped> 
.image-body{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.user-attachment{
  width: 80%;
  height: 80%;
}
.fix-top-right{
  position: absolute;
  top: 10px;
  right: 100px;
  z-index: 20000;
}
</style>