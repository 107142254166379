<template>
  <div class="health-charts elevation-5 pa-5 pb-15">
    <h1 class="heading-3 superAdminThemeText mt-5">Health Monitor</h1>
    <div id="diskspaceChart">
      <div>
        <VueSvgGauge
          :start-angle="-90"
          :end-angle="90"
          :value="series/10"
          :separator-step="1"
          :min="0"
          :max="10"
          baseColor="#eee"
          :gauge-color="graphPrimaryColor"
          :scale-interval="1" 
        />
        <span class="mt-5 text-center d-block">Free: {{ Math.floor(diskDetails.free/GBConstant) }} GB </span>
        <span class="mt-1 text-center d-block">Used:  {{ Math.floor(diskDetails.used/GBConstant) }} GB</span>
        <span class="mt-1 text-center d-block">Total: {{ Math.floor(diskDetails.total/GBConstant) }} GB </span>
        <h1 class="text-center mt-2 heading-5">Disk Space</h1>
      </div> 

      <div>
        <VueSvgGauge
          :start-angle="-90"
          :end-angle="90"
          :value="health"
          :separator-step="1"
          :min="0"
          :max="3"
          baseColor="#eee"
          :gauge-color="graphPrimaryColor"
          :scale-interval="0.1" 
        />
        <span class="mt-5 text-center d-block"> 
          Application Status: {{ applicationsStatus.status }}
        </span>
        <span class="mt-1 text-center d-block"> 
          Database Status: {{ applicationsStatus.db }}
        </span>
        <span class="mt-1 text-center d-block"> 
          Diskspace Status: {{ applicationsStatus.disk }}
        </span>
        <h1 class="text-center mt-2 heading-5">System Health</h1>
      </div>

    </div>
  </div>
</template>

<script> 
import {VueSvgGauge} from 'vue-svg-gauge'
import {superAdminTheme} from '@/constants/colorConstants'
export default {
  components: {
    VueSvgGauge,
  },
  data(){
    return{
      options: {
        labels: ['Free', 'Empty'],
      }, 
      graphPrimaryColor: superAdminTheme.colorPrimary,
      health: 0,
      series: [0],
      diskDetails: {},
      applicationsStatus: {},
      GBConstant: Math.pow(10,9)
    }
  },
  mounted(){
    this.$axios.get(`${this.$api.health}`)
    .then(response=>{   
      this.applicationsStatus.status = response.data.status
      this.applicationsStatus.db = response.data.details.db.status
      this.applicationsStatus.disk = response.data.details.diskSpace.status
      this.checkHealthScore()

      let diskDetails = response.data.details.diskSpace.details
      this.diskDetails = diskDetails
      this.diskDetails.used = (diskDetails.total - diskDetails.free)
      this.series = (diskDetails.total - diskDetails.free) / diskDetails.total * 10 
    }) 
  },
  methods:{
    checkHealthScore(){ 
      this.health = 0   
      Object.values(this.applicationsStatus).forEach(item=>{
        if(item === 'UP'){
          this.health += 1
        }
      })
    }
  }
}
</script>

<style scoped>
#diskspaceChart{
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-between;
  padding-bottom: 80px;
} 
.health-charts{
  background-color: white;
}
</style>