<template>
<div id="workspace-chart"> 
  <apexchart width="100%" :height="chartHeight" type="line" :options="options" :series="series"></apexchart>
</div>
</template>

<script> 
import{ mapGetters } from 'vuex'  
import {chartHeight, formatChartoptions} from '@/constants/chartConstants' 
export default { 
  data(){
     return { 
      options: formatChartoptions('vuechart-workspace',['#64B5F6'], []),
      series: [], 
      chartHeight: chartHeight
    }
  }, 
  mounted(){
    this.refetchData()
  },
  methods:{
    refetchData(){
      this.fetchWorkspacedata()
      setTimeout(()=>{
        this.refetchData()
      },this.$timeout.update_dashboard)
    },
    fetchWorkspacedata(){
      this.$axios.get(`${this.$api.analytics.chartDataWorkspace}${this.getAnalysisRange}`)
      .then(response=>{   
        this.series = [{
          name: 'Workspace',
          data:response.data.workspaces
        }]
        this.options = formatChartoptions('vuechart-workspace',['#64B5F6'], response.data.dateList)
      })
    }
  },  
  computed:{
    ...mapGetters(['getAnalysisRange'])
  }
}
</script>

<style scoped>  
</style>