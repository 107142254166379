import {errorNotification} from './notification'
import {handleRowBroadcast} from './websocketModules/rowBroadcast'
import {handleTableBroadcast} from './websocketModules/tableBroadcast'
import {handleColumnBroadcast} from './websocketModules/columnBroadcast'
import {handleRowValueBroadcast} from './websocketModules/rowValueBroadcast'
import {handleNotificationBroadcast} from './websocketModules/notificationBroadcast'
import {getCurrentTable, getUserUuid, getWorkspaceUuid} from './controllers'

let socket

const getWebsocket = () => {
  socket = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_ENDPOINT}/${getCurrentTable()}`);
  return socket
}

let notificationSocket

const notificationWebSocket = () => {
  notificationSocket = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_NOTIFICATION_ENDPOINT}`);
  return notificationSocket
} 

const parseMessage = (message) => { 
  if(handleServerResponse(message)){
    // Receive messages from server
    let msg = JSON.parse(message.data); 
    if(msg.messageType.includes('TABLEVIEW')){
      handleTableBroadcast(msg);
      return
    }
    if(msg.messageType.includes('ROW_VALUE')){ 
      handleRowValueBroadcast(msg)
      return
    }
    if(msg.messageType.includes('ROW')){ 
      handleRowBroadcast(msg)
      return
    }
    if(msg.messageType.includes('COLUMN')){
      handleColumnBroadcast(msg)
    }
  } 
}

const parseNotification = (message) => { 
  let msg = JSON.parse(message.data); 
  if(msg.messageType.includes('NOTIFICATION')){
    handleNotificationBroadcast(msg)
  }
}
 

const handleServerResponse = (response) =>{
  let error = JSON.parse(response.data).errorMessage
  if(error){ 
    if(JSON.parse(response.data).message.userUuid === getUserUuid()){
      errorNotification(error)
    } 
    return false
  } 
  return true 
}

const configureMessage = (data_messageType, data_message, data_uuid) => {
  let data = {
    messageType: data_messageType,
    message: data_message
  }
  if (data_uuid) {
    data.uuid = data_uuid
  }
  data.message.workspaceUuid = getWorkspaceUuid()
  data.message.userUuid = getUserUuid() 
  socket.send(JSON.stringify(data)) 
}

const sendIssuedNotification = (messageType, message) => {
  let data = {
    messageType: messageType,
    message: message
  }
  data.workspaceUuid = getWorkspaceUuid() 
  notificationSocket.send(JSON.stringify(data))
}

const addRow = () => {  
  configureMessage('ROW_ADDED', { tableViewUuid: getCurrentTable() }, null) 
}

const deleteRow = (row) => { 
  configureMessage('ROW_DELETED',{ tableViewUuid: getCurrentTable() },row)
}

const addColumn = (config) => { 
  configureMessage('COLUMN_ADDED', config, null)
}

const deleteColumn = (col) => { 
  configureMessage('COLUMN_DELETED', { tableViewUuid: getCurrentTable() }, col)
}

const updateColumn = (col) => {
  let messageData  = col
  messageData.tableViewUuid = getCurrentTable() 
  configureMessage('COLUMN_UPDATED', messageData, col.uuid)
}

const deleteTable = (uuid) => { 
  configureMessage('TABLEVIEW_DELETED', {}, uuid)
}

const updateTable = (table) => { 
  configureMessage(
    'TABLEVIEW_UPDATED',
    {
      name: table.name,
      description: table.description 
    },
    table.uuid
  )
}  

const updateRowValue = (val) => {  
  configureMessage(
    'ROW_VALUE_UPDATED',
    {
      rowUuid: val.rowUuid,
      columnUuid: val.columnUuid,
      value: val.value,
      multipartUuid: val.multipartUuid,
      tableViewUuid: getCurrentTable()
    },
    val.uuid
  )
}

const issueNotification = (email, uuid) => {
  let data = {}
  if (email) {
    data.email = email
  }
  if (uuid) {
    data.uuid = uuid
  }
  sendIssuedNotification('GETTING_NOTIFICATION', data)
}

export {
  getWebsocket,
  parseMessage,
  //To Server
  deleteTable,
  updateTable,
  addRow,
  deleteRow,
  addColumn,
  deleteColumn,
  updateColumn, 
  updateRowValue,
  notificationWebSocket,
  parseNotification,
  issueNotification, 
}