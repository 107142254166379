<template>
  <div class="body-wrapper"> 
    <img src="@/Assets/images/features.svg" alt="" class="image-small">
    <v-container>
      <v-text-field
        label="Workspace Domain"
        class="input-box"
        type="text"
        v-model="formData.workspaceDomain"
      ></v-text-field>  
      <v-text-field
        label="Email Address"
        class="input-box"
        type="email"
        v-model="formData.email"
      ></v-text-field> 
      <v-btn block dark @click="handleClick" :disabled="!isEnabled">Submit</v-btn>
    </v-container> 
  </div>
</template>

<script> 
export default {
  data(){
    return{ 
      formData : {},
      isEnabled: true,
    }
  },
  methods:{
    handleClick(){ 
      this.$axios.post(`${this.$api.user.profile.forgotPassword}`, this.formData)
      .then(response=>{
        this.isEnabled = false
        this.$emit('emailSent')
      }) 
    }
  }
}
</script>

<style scoped>
.body-wrapper{
  position: absolute;
  left: 50px;
  width: 300px;
  height: 450px;
  margin-top: -50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 15;
}
.image-small{
  width: 100%;
  height: 200px;
}
.input-box{
  width: 100%;
}
</style>