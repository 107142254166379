<template>
  <div class="body">
    <div class="container">
      <ChangePassword :otp="OTP" />
    </div> 
  </div>
</template>

<script>
import ChangePassword from '@/components/Profile/ChangePassword.vue'
export default {

  components : {ChangePassword},
  data(){
    return{
      OTP: ''
    }
  },
  created(){
    this.OTP = this.$route.params.token
  }
}
</script>

<style scoped>
  .body{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container{
    width: 50%;
    margin: auto;
  }
</style>