import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// Auth
import auth from './Auth/auth'

// Table data Module
import tableData from './Table_data/tableData'
import userTables from './Table_data/userTables'

// UI / UX Module
import UI from './UI/index'
import notification from './UI/notification' 
import theme from './UI/theme'
import navbarUI from './UI/navbarUI'

// User Workspaces
import workspaces from './Workspace/workspaces'
import dashboard from './Dashboard_Data/dashboard'

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth, tableData, userTables, UI, notification, workspaces, theme, dashboard, navbarUI
  }
})
