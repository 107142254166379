<template>
<div class="template-modal-stretch">
  <div class="wrapper text-center p-relative box-shadow-dark-3">  
    <div class="notification-header ">
      <div class="text-center heading-5">
        Delete
      </div>  
    </div>
    <div class="confirmation-notification text-center ">
      <span>Delete {{ deletingComponent }} ?</span>
    </div>
    <div class="confirmation-actions text-center notification-footer">
      <button class="btn cancel-btn" @click="reflect(false)">CANCEL</button>
      <button class="btn submit-btn" @click="reflect(true)">DELETE</button>
    </div>

  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props: ['deletingComponent'],
  methods: {
    reflect(value) {
      this.$emit('confirm', value)
    },
    closeConfirmationBox(){
      this.$emit('exit')
    }
  },
  computed: {
    ...mapGetters(['currentThemeObject'])
  }
}
</script>

<style scoped> 
.wrapper{ 
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  margin: auto; 
  background-color: white; 
  border-radius: 5px;  
}
.btn{
  padding: 5px 15px; 
  margin-right: 10px; 
  border-radius: 5px; 
  transition: all 0.3s ease;
}  
.confirmation-notification{
  background-color: var(--borderLight);
  padding: 30px 20px;
}
.submit-btn{  
  color: white;
  background-color: var(--ramsColorDanger);
} 
.cancel-btn{
  background-color: white;
  border: 1px solid var(--colorGrayDark);
  color:  var(--colorGrayDark); 
} 
.notification-header,
.notification-footer{
  padding: 10px 20px; 
  height: 55px; 
  text-transform: uppercase;
}
.notification-header{
  font-weight: 800; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}
.template-abs-top-right-corner{
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}
</style>