<template>
  <div class="template-modal-stretch">
    <div class="content" v-click-outside="exit">
      <div class="d-flex align-center justify-space-between">
        <h1 class="heading-3">Update Workspace</h1>
        <v-icon color="error" @click="exit">mdi-close-thick</v-icon>
      </div>
      
      <v-text-field
        label="Domain"
        v-model="details.domain"
        class="mb-2"
      ></v-text-field>
      <v-text-field
        label="Name"
        v-model="details.name"
        class="mb-2"
      ></v-text-field>
      <v-file-input
        accept="images/*"
        class="upload-file-tag"
        show-size
        v-model="files"
        label="Update Image"
      ></v-file-input>
      <div class="text-end">
        <v-btn color="success" class="mr-4" outlined @click="openThemeManager">Manage Theme</v-btn>
        <v-btn color="success" dark @click="handleSubmit">SUBMIT</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkspaceUuid } from '../../composables/controllers';
import { errorNotification, successNotification } from '../../composables/notification';
export default {
  props: ['workspace'],
  data(){
    return{
      details: this.workspace,
      files: []
    }
  },
  methods:{
    exit(){
      this.$emit('exit')
    },
    handleSubmit(){
      let formData = new FormData(); 
      formData.append('image', this.files)   
      formData.append('name', this.details.name)
      formData.append('domain', this.workspace.domain)
      this.$axios.put(`/workspace/image/${getWorkspaceUuid()}`, formData)
      .then(response=>{ 
        this.$emit('fetchWorkspace') 
        this.$store.dispatch('setNavbarImage', response.data.image)
        this.exit()
        successNotification('Image Uploaded') 
      })
      .catch(error=>{ 
        this.exit()
        errorNotification(error.response.data)
      })
    },
    openThemeManager(){
      this.exit()
      this.$store.dispatch('toggleThemeChanger', true)
    } 
  },
  watch:{
    workspace(){
      this.details = this.workspace
    }
  }
}
</script>

<style scoped>
.content{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  z-index: 500;
  background-color: white;
  width: 500px;
}
</style>