import axios from "axios" 
import {successNotification, errorNotification} from '../../composables/notification'
const state = {
  workspace_list: [],
  current_user_workspaces: [],  
  selectedWorkspaceId: '',
  user_roles: []
} 
const actions = {  
    setSelectedWorkspaceId({commit}, id){ 
        commit('SET_SELECTED_ID', id)
    },
    getWorkspaceList({commit}){   
        return new Promise((resolve, reject) =>{ 
            axios.get(`${this.$api.workspace.getWorkspaceList}`) 
            .then(response=>{ 
                commit('GET_WORKSPACES', response.data) 
                resolve()
            }).catch(err=>{
                reject(err)
            }) 
        }) 
    },  
    addWorkspace({ dispatch }, data){ 
        axios.post(`${this.$api.workspace.addWorkspace}`, data) 
        .then(createdAWorkspace=>{ 
            successNotification('Workspace added') 
            dispatch('getWorkspaceList')
        }).catch(error=>{   
            errorNotification(error.response.data.message)
        })
    },
    updateWorkspace({ dispatch}, data){  
        axios.put(`/workspace/${data.uuid}`, data) 
        .then(response=>{
            dispatch('getWorkspaceList')
        })
        .catch(error=>{
            errorNotification(error.response.data.message)
        })
    },
    deleteWorkspace({dispatch}, data){
        axios.delete(`/workspace/${data.uuid}`) 
        .then(res=>{
            successNotification('Deleted Workspace')
            dispatch('getWorkspaceList')
        })
        .catch(error=>{
            errorNotification(error.response.data.message)
        }) 
    },

    addUserToWorkspace({commit, dispatch}, data){ 
        axios.post(`${this.$api.user.addUser}`, data)
        .then(res=>{
            dispatch('findWorkspaceByUser')
        })
        .catch(err=>{ errorNotification(err.response.data.message) })
    },

    editUserInWorkspace({commit},data){
        axios.put(`/user/${data.userUuid}`, data)
        .then(res=>{ })
        .catch(error=>{  errorNotification(err.response.data.message)  })
    },

    deleteUserFromWorkspace({commit},user_uuid){  
        axios.delete(`/user/${user_uuid}`)
        .then(res=>{  })
        .catch(err=>{  errorNotification(err.response.data.message)   })
    },

    findWorkspaceByUser({ commit }){  
        axios.get(`/workspace/user`)
        .then(res=>{   
            commit('SET_CURRENT_USER_WORKSPACES', res.data)
        })
    }
}

const mutations = {
    'GET_WORKSPACES'(state, data){
        state.workspace_list = data
    }, 
    'SET_SELECTED_ID'(state, id){
        state.selectedWorkspaceId = id
    }, 
    'SET_CURRENT_USER_WORKSPACES'(state, workspaces){
        state.current_user_workspaces = workspaces
    }
}

const getters = {
    getWorkspaces(state){
        return state.workspace_list
    },
    getCurrentUserWorkspaces(state){
        return state.current_user_workspaces
    }, 
    getSelectedWorkspaceId(state){
        return state.selectedWorkspaceId
    }, 
}

export default {
  state,
  actions,
  mutations,
  getters
}
