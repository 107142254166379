const state = {
  navbarImage: "",
  profileImage: "",
};
const actions = {
  setNavbarImage({ commit }, imageURL) {
    commit("SET_NAVBAR_IMAGE", imageURL);
  },
  setUserProfileImage({ commit }, imageURL) {
    commit("SET_USER_PROFILE_PICTURE", imageURL);
  },
};
const mutations = {
  SET_NAVBAR_IMAGE(state, value) {
    state.navbarImage = value;
  },
  SET_USER_PROFILE_PICTURE(state, value) {
    state.profileImage = value;
  },
};
const getters = {
  getNavbarImageUrl(state) {
    return state.navbarImage;
  },
  getUserProfilePicture(state) {
    return state.profileImage;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
